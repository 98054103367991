import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import { capitalize } from 'lodash';
import styles from './password-changed.module.scss';
import { usePasswordChangedData } from './password-changed.hook';

/**
 * PasswordChanged component
 * @description Password change flow success modal window
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const PasswordChanged: FC = () => {
  const { t, handleCloseModal } = usePasswordChangedData();

  return (
    <Modal
      modalId={ModalIds.passwordChanged}
      title={capitalize(t('settings-page.content.security.modals.passwordChange.success.title'))}
    >
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {capitalize(t('settings-page.content.security.modals.passwordChange.success.message'))}
        </Typography.Text>
        <Button onClick={handleCloseModal} variant={'main'} size={'large'}>
          <Typography.Text variant={'bodyText1'}>
            {capitalize(t('settings-page.content.security.modals.passwordChange.success.continue'))}
          </Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { PasswordChanged };
