import './styles/global.scss';

import { Header, NoDesktop, Preloader, Sidebar } from './components';
import { InformationRouter, ReportsRouter } from './pages';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PaymentFormat, changeUserRole } from './services/api';
import React, { FC, Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { getSupportInfo, getUnreadMessagesCount } from './services/api/general/general';
import { useAppContext, useModalsContext } from './contexts';

import { ReactComponent as ActivityIcon } from '../assets/icons/notes.svg';
import { ReactComponent as ArrowsRightDownIcon } from '../assets/icons/arrows-right-down.svg';
import { ReactComponent as ArrowsUpRightIcon } from '../assets/icons/arrows-up-right.svg';
import { ReactComponent as ClipboardIcon } from '../assets/icons/clipboard-notes.svg';
import { ReactComponent as DollarIcon } from '../assets/icons/dollar-sign.svg';
import { ReactComponent as EnvelopeIcon } from '../assets/icons/envelope-alt.svg';
import { GetSupportInfoResponse } from './services/api/general/general.model';
import { ReactComponent as HeadphonesIcon } from '../assets/icons/headphones-alt.svg';
import { ReactComponent as HistoryIcon } from '../assets/icons/history.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/home-alt.svg';
import { ModalIds } from './contexts/modals/modals.types';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone-alt.svg';
import { ReactComponent as QuestionIcon } from '../assets/icons/question-circle.svg';
import { SidebarProps } from './components/sidebar/sidebar.types';
import { ReactComponent as TelegramIcon } from '../assets/icons/telegram-alt.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user-check.svg';
import { UserRole } from './contexts/app/app.types';
import { ReactComponent as UsersIcon } from '../assets/icons/users.svg';
import { ReactComponent as WhatsAppIcon } from '../assets/icons/whatsapp.svg';
import { changeLanguage } from 'i18next';
import defaultLogo from '../assets/images/default-logo.png';
import { environment } from '../environment';
import styles from './app.module.scss';
import { useMediaQueries } from './hooks';
import { useTranslation } from 'react-i18next';

const checkRoleChangeAccess = (requestRole: UserRole, currentRole: UserRole, userRoles: ReadonlyArray<UserRole>) => {
  switch (requestRole) {
    case UserRole.admin:
      return currentRole === UserRole.admin ? false : userRoles.includes(UserRole.admin);

    case UserRole.manager:
      return currentRole === UserRole.manager
        ? false
        : userRoles.some((role) => role === UserRole.manager || role === UserRole.admin);

    case UserRole.trader:
      return currentRole === UserRole.trader
        ? false
        : userRoles.some((role) => role === UserRole.admin || role === UserRole.trader);

    default:
      return false;
  }
};

const Dashboard = lazy(() => import('./pages/dashboard'));
const Activities = lazy(() => import('./pages/activities'));
const PaymentHistory = lazy(() => import('./pages/payment-history'));
const Faq = lazy(() => import('./pages/faq'));
const Subscriptions = lazy(() => import('./pages/subscriptions'));
const AccountsHistory = lazy(() => import('./pages/accounts-history'));
const Settings = lazy(() => import('./pages/settings'));
const Strategy = lazy(() => import('./pages/strategy'));
const Asset = lazy(() => import('./pages/asset'));
const Requests = lazy(() => import('./pages/requests'));
const UndividedCapital = lazy(() => import('./pages/undivided-capital'));
const FreeAccounts = lazy(() => import('./pages/free-accounts'));
const Participants = lazy(() => import('./pages/participants'));
const ReportsHistory = lazy(() => import('./pages/reports-history'));

const ProtectedRoutes: FC = () => {
  const {
    logo,
    companyName,
    currentRole,
    establishedConnections,
    user,
    paymentFormat,
    unreadMessagesCount,
    isLoading,
    roleMigrations,
    dispatch,
  } = useAppContext();
  const { dispatch: modalsDispatch, activeModals } = useModalsContext();
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { pathname } = useLocation();
  const [supportInfo, setSupportInfo] = useState<GetSupportInfoResponse>();
  const navigate = useNavigate();

  //  GETTING UNREAD MESSAGES COUNT
  const fetchData = useCallback(async () => {
    if (!unreadMessagesCount && user?.email && !isLoading) {
      try {
        const { data } = await getUnreadMessagesCount({ email: user.email });
        data && dispatch({ type: 'SET_UNREAD_MESSAGES_COUNT', payload: data });

        const { data: supportInfoData } = await getSupportInfo();
        supportInfoData &&
          setSupportInfo(
            supportInfoData ?? {
              Mail: 'customer@investudio.com.ua',
              Phone: '+380980001138',
              Telegram: '+380980001138',
              WhatsApp: '+380980001138',
            },
          );
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, isLoading, unreadMessagesCount, user?.email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (
      !activeModals?.some((id) => id === ModalIds.loginRequestGiveAccess) &&
      roleMigrations?.loginCandidate &&
      roleMigrations.loginCandidate.role === currentRole &&
      !pathname.includes('auth') &&
      roleMigrations.loginCandidate.email !== user?.email
    ) {
      modalsDispatch({
        type: 'SHOW_MODAL',
        payload: {
          id: ModalIds.loginRequestGiveAccess,
        },
      });
    } else if (pathname.includes('auth')) {
      modalsDispatch({ type: 'HIDE_MODAL', payload: ModalIds.loginRequestGiveAccess });
    }
  }, [currentRole, user, roleMigrations?.loginCandidate, pathname, dispatch, modalsDispatch, activeModals]);

  const links: Record<UserRole, SidebarProps['sections'][0]> = useMemo(
    () => ({
      [UserRole.admin]: [
        {
          icon: <HomeIcon />,
          label: t('sidebar.home'),
          linkTo: '/management/dashboard',
        },
        {
          icon: <ActivityIcon />,
          label: t('sidebar.activities'),
          linkTo: '/management/activities',
        },
        {
          icon: paymentFormat === PaymentFormat.Subscription ? <DollarIcon /> : <></>,
          label: paymentFormat === PaymentFormat.Subscription ? t('sidebar.subscription') : '',
          linkTo: paymentFormat === PaymentFormat.Subscription ? '/management/subscription' : '',
        },
        {
          icon: <HistoryIcon />,
          label:
            paymentFormat === PaymentFormat.Subscription
              ? t('sidebar.accounts-history')
              : t('sidebar.payments-history'),
          linkTo:
            paymentFormat === PaymentFormat.Subscription
              ? '/management/accounts-history'
              : '/management/payment-history',
        },
        {
          icon: <QuestionIcon />,
          label: t('sidebar.faq'),
          linkTo: '/management/faq',
        },
      ],
      [UserRole.manager]: [
        {
          icon: <HomeIcon />,
          label: t('sidebar.home'),
          linkTo: '/management/dashboard',
        },
        {
          icon: <UsersIcon />,
          label: t('sidebar.members'),
          linkTo: '/management/participants',
        },
        {
          icon: <UserIcon />,
          label: t('sidebar.requests'),
          linkTo: '/management/requests',
        },
        {
          icon: <DollarIcon />,
          label: t('sidebar.capital'),
          linkTo: '/management/capital',
        },
        {
          icon: <ClipboardIcon />,
          label: t('sidebar.accounts'),
          linkTo: '/management/free-accounts',
        },
        {
          icon: <QuestionIcon />,
          label: t('sidebar.faq'),
          linkTo: '/management/faq',
        },
      ],
      [UserRole.trader]: [
        {
          icon: <HomeIcon />,
          label: t('sidebar.home'),
          linkTo: '/management/dashboard',
        },
        {
          icon: <QuestionIcon />,
          label: t('sidebar.faq'),
          linkTo: '/management/faq',
        },
      ],
    }),
    [paymentFormat, t],
  );

  const sideBarSections: SidebarProps['sections'] = useMemo(
    () => [
      currentRole ? links[currentRole] : [],
      [
        {
          icon: currentRole !== UserRole.trader ? <ArrowsUpRightIcon /> : <></>,
          label: currentRole !== UserRole.trader ? t('sidebar.trader') : '',
          disabled: !(currentRole && user && checkRoleChangeAccess(UserRole.trader, currentRole, user?.role)),
          onClick: async () => {
            if (user) {
              const { data } = await changeUserRole({ email: user.email, role: UserRole.trader });
              if (data && data !== 'loginRequest') {
                dispatch({ type: 'SET_CURRENT_ROLE', payload: data });
                navigate('dashboard');
              } else {
                modalsDispatch({
                  type: 'SHOW_MODAL',
                  payload: {
                    id: ModalIds.loginRequestChangeRole,
                    data: {
                      loginRequestChangeRole: {
                        role: UserRole.trader,
                      },
                    },
                  },
                });
              }
            }
          },
          isHidden: currentRole === UserRole.trader,
          messagesCount: unreadMessagesCount?.trader,
        },
        {
          icon:
            currentRole !== UserRole.admin ? (
              currentRole === UserRole.trader ? (
                <ArrowsUpRightIcon />
              ) : (
                <ArrowsRightDownIcon />
              )
            ) : (
              <></>
            ),
          label: currentRole !== UserRole.admin ? t('sidebar.admin') : '',
          disabled: !(currentRole && user && checkRoleChangeAccess(UserRole.admin, currentRole, user?.role)),
          onClick: async () => {
            if (user) {
              const { data } = await changeUserRole({ email: user.email, role: UserRole.admin });
              if (data && data !== 'loginRequest') {
                dispatch({ type: 'SET_CURRENT_ROLE', payload: data });
                navigate('dashboard');
              } else {
                modalsDispatch({
                  type: 'SHOW_MODAL',
                  payload: {
                    id: ModalIds.loginRequestChangeRole,
                    data: {
                      loginRequestChangeRole: {
                        role: UserRole.admin,
                      },
                    },
                  },
                });
              }
            }
          },
          isHidden: currentRole === UserRole.admin,
          messagesCount: unreadMessagesCount?.admin,
        },
        {
          icon: currentRole !== UserRole.manager ? <ArrowsRightDownIcon /> : <></>,
          label: currentRole !== UserRole.manager ? t('sidebar.manager') : '',
          disabled: !(currentRole && user && checkRoleChangeAccess(UserRole.manager, currentRole, user?.role)),
          onClick: async () => {
            if (user) {
              const { data } = await changeUserRole({ email: user.email, role: UserRole.manager });
              if (data && data !== 'loginRequest') {
                dispatch({ type: 'SET_CURRENT_ROLE', payload: data });
                navigate('dashboard');
              } else {
                modalsDispatch({
                  type: 'SHOW_MODAL',
                  payload: {
                    id: ModalIds.loginRequestChangeRole,
                    data: {
                      loginRequestChangeRole: {
                        role: UserRole.manager,
                      },
                    },
                  },
                });
              }
            }
          },
          isHidden: currentRole === UserRole.manager,
          messagesCount: unreadMessagesCount?.manager,
        },
      ],
      [
        {
          icon: <HeadphonesIcon />,
          label: t('sidebar.support'),
          items: [
            {
              icon: <EnvelopeIcon />,
              label: t('sidebar.mail'),
              href: supportInfo?.Mail ? `mailto:${supportInfo.Mail}` : '',
            },
            {
              icon: <TelegramIcon />,
              label: t('sidebar.telegram'),
              href: supportInfo?.Telegram ? `https://t.me/${supportInfo.Telegram}` : '',
              target: '_blank',
            },
            {
              icon: <WhatsAppIcon />,
              label: t('sidebar.whatsapp'),
              href: supportInfo?.WhatsApp ? `https://wa.me/${supportInfo.WhatsApp}` : '',
              target: '_blank',
            },
            {
              icon: <PhoneIcon />,
              label: t('sidebar.phone'),
              onClick: () => {
                if (!supportInfo?.Phone) return;

                window.alert(`${t('sidebar.support-phone')}\n${supportInfo.Phone}`);
              },
            },
          ],
        },
      ],
    ],
    [
      currentRole,
      dispatch,
      links,
      modalsDispatch,
      t,
      unreadMessagesCount?.admin,
      unreadMessagesCount?.manager,
      unreadMessagesCount?.trader,
      user,
      supportInfo,
      navigate,
    ],
  );

  if (!user || !localStorage.getItem('local-auth-mo')) {
    window?.location.replace(`${environment.http.authFrontend}`);
  }

  return (
    <div className={styles.mainLayout}>
      <Sidebar sections={sideBarSections} logoSrc={logo ?? defaultLogo} companyName={companyName} />
      <Header
        currentRole={currentRole}
        isBrokerConnectionEstablished={!!establishedConnections?.tradingModule}
        isInvestudioConnectionEstablished={!!establishedConnections?.tradeVision}
        onLogout={() => dispatch({ type: 'LOGOUT' })}
      />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

const App: FC = () => {
  const { user, logo, currentRole, isLoading, companyName } = useAppContext();
  const { mobile, tablet } = useMediaQueries();
  const browserLanguage = window?.navigator.language?.split('-')[0];
  const userLanguage = useMemo(
    // () => (user?.preferences.language ? (user.preferences.language === 'ua' ? 'uk' : 'en') : undefined),
    () => user?.preferences.language ?? undefined,
    [user],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const link = document?.querySelector('link[rel="icon"]');

      if (link && logo) {
        link.setAttribute('href', logo);
        document.title = companyName ?? '';
      }
    }
  }, [companyName, logo]);

  useEffect(() => {
    changeLanguage(userLanguage ?? browserLanguage);
  }, [browserLanguage, userLanguage]);

  if (mobile || tablet)
    return (
      <>
        <Preloader type={'overlay'} isActive={isLoading} />
        <Routes>
          <Route path={'reports/*'} element={<ReportsRouter />} />
          <Route path={'*'} element={<NoDesktop />} />
        </Routes>
      </>
    );

  return (
    <>
      <Preloader type={'overlay'} isActive={isLoading} />
      <Routes>
        <Route path={'reports/*'} element={<ReportsRouter />} />
        <Route path={'*'} element={<ProtectedRoutes />}>
          <Route
            path={'dashboard/*'}
            element={
              <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path={'activities'}
            element={
              currentRole === UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Activities />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'payment-history'}
            element={
              currentRole === UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <PaymentHistory />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'faq'}
            element={
              <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                <Faq />
              </Suspense>
            }
          />
          <Route
            path={'subscription'}
            element={
              currentRole === UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Subscriptions />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'accounts-history'}
            element={
              currentRole === UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <AccountsHistory />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'settings/*'}
            element={
              <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path={'strategy'}
            element={
              currentRole === UserRole.trader ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Strategy />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'asset'}
            element={
              currentRole !== UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Asset />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'requests'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Requests />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'capital'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <UndividedCapital />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'free-accounts'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <FreeAccounts />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'information/*'}
            element={currentRole === UserRole.manager ? <InformationRouter /> : <Navigate to={'dashboard'} />}
          />
          <Route
            path={'participants'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Participants />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'reports-history'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <ReportsHistory />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />

          <Route path={'*'} element={<Navigate to={'dashboard'} />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
