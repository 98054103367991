import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import { capitalize } from 'lodash';
import styles from './two-fa-changed.module.scss';
import { useTwoFaChangedData } from './two-fa-changed.hook';

/**
 * TwoFaChanged component
 * @description TwoFa change flow success modal window
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const TwoFaChanged: FC = () => {
  const { modalData, t, handleCloseModal } = useTwoFaChangedData();

  return (
    <Modal
      modalId={ModalIds.twoFaChanged}
      title={capitalize(
        t(`settings-page.content.security.modals.twoFaChange.${modalData?.status ?? 'disabled'}.title`),
      )}
    >
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {capitalize(
            t(`settings-page.content.security.modals.twoFaChange.${modalData?.status ?? 'disabled'}.message`),
          )}
        </Typography.Text>
        <Button onClick={handleCloseModal} variant={'main'} size={'large'}>
          <Typography.Text variant={'bodyText1'}>
            {capitalize(
              t(`settings-page.content.security.modals.twoFaChange.${modalData?.status ?? 'disabled'}.continue`),
            )}
          </Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { TwoFaChanged };
