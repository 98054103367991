import { Form as FormComponent, Formik } from 'formik';
import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { Input } from '../../../../../input';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import { capitalize } from 'lodash';
import styles from './form.module.scss';
import { useFormData } from './form.hook';

/**
 * Form component
 * @description Content for the WithdrawPortfolio flow Form stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-160536&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Form: FC = () => {
  const { t, flowData, initialValues, validationSchema, handleSubmitForm } = useFormData();

  return (
    <Modal modalId={ModalIds.withdrawPortfolioForm} title={t('modals.withdrawPortfolio.form.title')}>
      {flowData && (
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <Typography.Text variant={'label2'} className={styles.label}>
              {t('modals.withdrawPortfolio.form.capitalLabel')}
            </Typography.Text>
            <Typography.Number variant={'h3'} currency value={Math.ceil(flowData.portfolio.PortfolioCurrentCapital)} />
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmitForm}>
            {({ setFieldValue, errors, touched, setFieldTouched, isValid, values: { withdrawAmount } }) => (
              <FormComponent className={styles.formWrapper} autoComplete={'off'}>
                <Input
                  id={'withdrawAmount'}
                  label={t('modals.withdrawPortfolio.form.withdrawAmount')}
                  type={'text'}
                  isCurrency={true}
                  value={withdrawAmount}
                  onChange={(value) => {
                    setFieldValue('withdrawAmount', !!+value.toString().replaceAll(' ', '') ? value : null);
                  }}
                  onBlur={() => setFieldTouched('withdrawAmount', true)}
                  error={
                    withdrawAmount &&
                    parseFloat(withdrawAmount.replace(/[^0-9.-]+/g, '')) >
                      Math.ceil(flowData.portfolio.PortfolioCurrentCapital)
                      ? t('modals.withdrawPortfolio.form.insufficient')
                      : errors.withdrawAmount && touched.withdrawAmount
                      ? errors.withdrawAmount
                      : undefined
                  }
                />
                <Button
                  size={'large'}
                  variant={'main'}
                  type={'submit'}
                  disabled={
                    !flowData ||
                    !isValid ||
                    !withdrawAmount ||
                    parseFloat(withdrawAmount.replace(/[^0-9.-]+/g, '')) >
                      Math.ceil(flowData.portfolio.PortfolioCurrentCapital)
                  }
                  className={styles.saveButton}
                >
                  <Typography.Text variant={'bodyText1'}>
                    {capitalize(t('modals.withdrawPortfolio.form.continue'))}
                  </Typography.Text>
                </Button>
              </FormComponent>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export { Form };
