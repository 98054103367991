import React, { FC, useState } from 'react';

import { Button } from '../../../../../../../button';
import { Checkbox } from '../../../../../../../checkbox';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import { WarningProps } from './warning.types';
import styles from './warning.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Warning component
 * @description Content for the AutomatedOrdersConfirmation Set flow Warning stage
 *
 * @author Oleksii Medvediev
 * @param { WarningProps } props - WarningProps defined in the './warning.types.ts'
 * @category Components
 */
const Warning: FC<WarningProps> = ({ onSubmit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const [isSelected, setIsSelected] = useState(false);

  return (
    <Modal
      modalId={ModalIds.automatedOrdersConfirmationSet}
      title={t('modals.automatedOrdersConfirmation.set.warning.title')}
    >
      <div className={styles.wrapper}>
        <div className={styles.text}>
          <Typography.Text variant={'bodyText3'} className={styles.textWarning}>
            {t('modals.automatedOrdersConfirmation.set.warning.text')}
          </Typography.Text>
          <div className={styles.checkboxBlock}>
            <Checkbox onChange={() => setIsSelected(!isSelected)} isChecked={isSelected} />
            <Typography.Text variant={'bodyText3'}>
              {t('modals.automatedOrdersConfirmation.set.warning.checkboxText')}
            </Typography.Text>
          </div>
        </div>
        <Button variant={'main'} size={'large'} onClick={onSubmit} disabled={!isSelected}>
          <Typography.Text variant={'bodyText1'}>
            {t('modals.automatedOrdersConfirmation.set.warning.continue')}
          </Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { Warning };
