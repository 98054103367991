import * as yup from 'yup';

import { EditUserValues, RequestProps } from './request.types';
import { Form, Formik } from 'formik';
import React, { FC, useMemo } from 'react';

import { Button } from '../../../../../../../button';
import { ReactComponent as ChevronDown } from '../../../../../../../../../assets/icons/chevron-down.svg';
import { Dropdown } from '../../../../../../../dropdown';
import { Input } from '../../../../../../../input';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import { UserRole } from '../../../../../../../../contexts/app/app.types';
import styles from './request.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Request component
 * @description Content for the UsersManagement Invite flow Request stage
 *
 * @author Oleksii Medvediev
 * @param { RequestProps } props - RequestProps defined in the './request.types.ts'
 * @category Components
 */
const Request: FC<RequestProps> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  const roleOptions: ReadonlyArray<{ id: UserRole; value: string }> = useMemo(
    () => [
      {
        id: UserRole.manager,
        value: t('roles.manager'),
      },
      {
        id: UserRole.admin,
        value: t('roles.admin'),
      },
      { id: UserRole.trader, value: t('roles.trader') },
    ],
    [t],
  );

  const validationSchema: yup.Schema<EditUserValues> = yup.object({
    name: yup
      .string()
      .min(3, t('modals.usersManagement.edit.request.validation.nameMin'))
      .required(t('modals.usersManagement.edit.request.validation.nameRequired')),
    email: yup
      .string()
      .email(t('modals.usersManagement.edit.request.validation.emailNotValid'))
      .required(t('modals.usersManagement.edit.request.validation.emailRequired')),
    role: yup
      .array()
      .of(yup.string().required().oneOf(Object.values(UserRole)))
      .min(1, t('modals.usersManagement.edit.request.validation.roleRequired'))
      .required(t('modals.usersManagement.edit.request.validation.roleRequired')),
  });

  return (
    <Modal modalId={ModalIds.usersManagementEdit} title={t('modals.usersManagement.edit.request.title')}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ setFieldValue, errors, touched, setFieldTouched, isValid, values }) => (
          <Form className={styles.wrapper}>
            <Input
              id={'name'}
              label={t('modals.usersManagement.edit.request.nameInput')}
              type={'text'}
              value={!!values.name ? values.name : initialValues.name}
              onChange={(name) => setFieldValue('name', name)}
              onBlur={() => setFieldTouched('name', true)}
              error={errors.name && touched.name ? errors.name : undefined}
            />
            <Input
              id={'role'}
              label={t('modals.usersManagement.edit.request.roleInput')}
              type={'text'}
              onChange={() => null}
              value={
                values.role
                  ? values.role.map((role) => t(`roles.${role}`)).join(', ')
                  : initialValues.role.map((role) => t(`roles.${role}`)).join(', ')
              }
              readOnly
              tailingIcon={
                <Dropdown
                  button={<ChevronDown />}
                  options={roleOptions}
                  defaultValues={roleOptions
                    .filter(({ id }) => !!initialValues.role.includes(id))
                    .map((option) => option.id)}
                  onChange={(roles) => setFieldValue('role', roles)}
                  multiple
                />
              }
              onBlur={() => setFieldTouched('role', true)}
              error={errors?.role && touched.role ? errors.role.toString() : undefined}
            />
            <Input
              id={'email'}
              label={t('modals.usersManagement.edit.request.emailInput')}
              type={'email'}
              value={!!values.email ? values.email : initialValues.email}
              onChange={(email) => setFieldValue('email', email, true)}
              onBlur={() => setFieldTouched('email', true)}
              error={errors.email && touched.email ? errors.email : undefined}
            />
            <Button
              variant={'main'}
              size={'large'}
              type={'submit'}
              disabled={!isValid || !Object.values(values).every((field) => !!field)}
            >
              <Typography.Text variant={'bodyText1'}>{t('modals.usersManagement.edit.request.submit')}</Typography.Text>
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export { Request };
