import { FC } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search-40.svg';
import { Typography } from '../typography';
import styles from './nothing-found.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * NothingFound component
 * @description Template content for negative search results
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const NothingFound: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'client' });

  return (
    <div className={styles.wrapper}>
      <SearchIcon />
      <Typography.Text variant={'h5'}>{t('common.nothingFound.title')}</Typography.Text>

      <Typography.Text variant={'bodyText3'}>
        {t('common.nothingFound.text1')}
        <br />
        {t('common.nothingFound.text2')}
      </Typography.Text>
    </div>
  );
};

export { NothingFound };
