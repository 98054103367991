import React, { FC, useCallback } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './branding-error-modal.module.scss';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * BrandingError component
 * @description Content for the BrandingError window
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const BrandingError: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { dispatch } = useModalsContext();

  const closeModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.brandingError });
  }, [dispatch]);

  return (
    <Modal modalId={ModalIds.brandingError} title={t('settings-page.modals.branding.error.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>{t('settings-page.modals.branding.error.text')}</Typography.Text>
        <Button size={'large'} variant={'main'} onClick={closeModal} className={styles.button}>
          <Typography.Text variant={'bodyText1'}>{t('settings-page.modals.branding.error.buttonText')}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { BrandingError };
