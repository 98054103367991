import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { ErrorProps } from './error.types';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import styles from './error.module.scss';

/**
 * Error component
 * @description Content for the ChangeStrategy flow Error stage
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-154738&mode=dev
 *
 * @author Oleksii Medvediev
 * @param { ErrorProps } props - ErrorProps defined in the './error.types.ts'
 * @category Components
 */
const Error: FC<ErrorProps> = ({ onSubmitStage, t }) => (
  <Modal modalId={ModalIds.changeStrategy} title={t('modals.changeStrategy.error.title')}>
    <div className={styles.wrapper}>
      <Typography.Text variant={'bodyText3'}>
        {t('modals.changeStrategy.error.text1')}
        <br />
        <br />
        {t('modals.changeStrategy.error.text2')}
      </Typography.Text>
      <Button type={'button'} variant={'main'} size={'large'} onClick={onSubmitStage}>
        {t('modals.changeStrategy.error.return')}
      </Button>
    </div>
  </Modal>
);

export { Error };
