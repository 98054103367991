import { Customization, Error, Request, Success } from './components';
import { EditProps, EditStageTimePeriod } from './edit.types';
import React, { useCallback, useMemo, useState } from 'react';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { CustomizationProps } from './components/customization/customization.types';
import { ErrorProps } from './components/error/error.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { RequestProps } from './components/request/request.types';
import { SuccessProps } from './components/success/success.types';
import dayjs from 'dayjs';
import { setAutomatedOrdersConfirmationExpDate } from '../../../../../../services/api';
import { useTranslation } from 'react-i18next';

const formatPeriodLabel = (startDate: Date, endDate: Date, t: (value: string) => string): string => {
  const dividedStartDate = dayjs(startDate).format('D MMMM YYYY').split(' ');
  const dividedEndDate = dayjs(endDate).format('D MMMM YYYY').split(' ');
  if (dividedStartDate[2] === dividedEndDate[2]) {
    if (dividedStartDate[1] === dividedEndDate[1]) {
      if (dividedStartDate[0] === dividedEndDate[0]) {
        // D MMMM YYYY
        return dividedStartDate.map((item, index) => (index === 1 ? t(`casedMonths.${item}`) : item)).join(' ');
      } else {
        // D - D MMMM YYYY
        return `${dividedStartDate[0]} - ${dividedEndDate[0]} ${t(`casedMonths.${dividedStartDate[1]}`)} ${
          dividedStartDate[2]
        }`;
      }
    } else {
      // D MMMM - D MMMM YYYY
      return `${dividedStartDate[0]} ${t(`casedMonths.${dividedStartDate[1]}`)} - ${dividedEndDate[0]} ${t(
        `casedMonths.${dividedEndDate[1]}`,
      )} ${dividedStartDate[2]}`;
    }
  } else {
    // D MMMM YYYY - D MMMM YYYY
    return `${dividedStartDate
      .map((item, index) => (index === 1 ? t(`casedMonths.${item}`) : item))
      .join(' ')} - ${dividedEndDate.map((item, index) => (index === 1 ? t(`casedMonths.${item}`) : item)).join(' ')}`;
  }
};

/**
 * useEditData hook
 * @description The hook which processes AutomatedOrdersConformation Edit flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useEditData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { automatedOrdersConfirmationEdit },
    dispatch,
  } = useModalsContext();

  const flowData: EditProps | undefined = useMemo(
    () => automatedOrdersConfirmationEdit,
    [automatedOrdersConfirmationEdit],
  );

  const existingPeriod = useMemo(
    () =>
      flowData?.automatedOrdersConfirmationExpDate &&
      flowData.automatedOrdersConfirmationExpDate?.find(({ strategyId }) => strategyId === flowData?.strategyId)
        ? flowData.automatedOrdersConfirmationExpDate?.find(({ strategyId }) => strategyId === flowData?.strategyId)
        : undefined,
    [flowData?.automatedOrdersConfirmationExpDate, flowData?.strategyId],
  );

  const [period, setPeriod] = useState<EditStageTimePeriod | undefined>(
    existingPeriod
      ? {
          startDate: dayjs(existingPeriod?.period?.startDate, 'YYYY-MM-DD (HH:mm:ss)').toDate(),
          endDate: dayjs(existingPeriod?.period?.endDate, 'YYYY-MM-DD (HH:mm:ss)').toDate(),
        }
      : undefined,
  );

  const setStage = useCallback(
    (stage: EditProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.automatedOrdersConfirmationEdit,
          data: {
            [ModalIds.automatedOrdersConfirmationEdit]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    EditProps['currentStage'],
    RequestProps['onSubmit'] | CustomizationProps['onSubmit'] | SuccessProps['onClose']
  > = useMemo(
    () => ({
      request: (value) => {
        if (value === 'change') {
          setStage('customization');
        } else {
          flowData &&
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.automatedOrdersConfirmationCancel,
                data: {
                  automatedOrdersConfirmationCancel: {
                    onRefetch: flowData?.onRefetch,
                    strategyId: flowData.strategyId,
                    currentStage: 'request',
                  },
                },
              },
            });
          dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationEdit });
        }
      },
      customization: async () => {
        if (user && flowData && period) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { data } = await setAutomatedOrdersConfirmationExpDate({
              strategyId: flowData.strategyId,
              period: {
                endDate: dayjs(period.endDate).format('YYYY-MM-DD HH-mm-ss'),
                startDate: dayjs(period.startDate).format('YYYY-MM-DD HH-mm-ss'),
              },
              updatedBy: user.email,
            });
            if (data && data.automatedOrdersConfirmationExpDate) {
              flowData.onRefetch();
              setStage('success');
            } else {
              setStage('error');
            }
          } catch (error) {
            console.error(error);
            setStage('error');
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationEdit });
      },
      error: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationEdit });
      },
    }),
    [appDispatch, flowData, dispatch, period, setStage, user],
  );

  const stages: Record<EditProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            request: period ? (
              <Request
                onSubmit={submitHandlers.request}
                periodLabel={formatPeriodLabel(period.startDate, period.endDate, t)}
              />
            ) : (
              <></>
            ),
            customization: (
              <Customization
                onSubmit={submitHandlers.customization as CustomizationProps['onSubmit']}
                period={period}
                setPeriod={(value) => setPeriod(value)}
              />
            ),
            success: period ? (
              <Success
                periodLabel={formatPeriodLabel(period.startDate, period.endDate, t)}
                onClose={submitHandlers.success as SuccessProps['onClose']}
              />
            ) : (
              <></>
            ),
            error: <Error onClose={submitHandlers.error as ErrorProps['onClose']} />,
          }
        : undefined,
    [
      flowData,
      period,
      submitHandlers.customization,
      submitHandlers.error,
      submitHandlers.request,
      submitHandlers.success,
      t,
    ],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useEditData, formatPeriodLabel };
