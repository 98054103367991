import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppContext, useModalsContext } from '../../contexts';

import { ReactComponent as BackIcon } from '../../../assets/icons/angle-left.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/times.svg';
import { ModalProps } from './modal.types';
import { Overlay } from './components';
import { Typography } from '../typography';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';
import classNames from 'classnames';

const isBrowser = typeof window !== 'undefined';

const Portal: FC<PropsWithChildren & { readonly domNode: string }> = ({ children, domNode }) => {
  const rootElement = useMemo(() => document.getElementById(domNode), [domNode]);
  return rootElement ? createPortal(children, rootElement) : null;
};

/**
 * Modal component.
 * @description Global modal window component
 *
 * @category Components
 * @param { ModalProps } props - ModalProps defined in the './modal.types.ts'
 */
const Modal: FC<ModalProps> = ({
  children,
  isOpened = false,
  modalId,
  title,
  onBackButtonClick,
  // shouldLeaveModalOpenedOnBackButtonClick,
  className,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { activeModals, dispatch } = useModalsContext();
  const { dispatch: appDispatch } = useAppContext();

  const isAnyModalOpen = useMemo(() => Object.keys(activeModals ?? []).length, [activeModals]);

  const renderModal = useCallback((): boolean => {
    if (isOpened) return true;
    if (activeModals?.includes(modalId)) return true;
    return false;
  }, [activeModals, isOpened, modalId]);

  useEffect(() => {
    if (isBrowser && document) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  const index = useMemo(() => (activeModals?.indexOf(modalId) ?? 0) + 1, [activeModals, modalId]);

  return renderModal() ? (
    <Portal domNode="modal-root">
      <Overlay
        isShown={!!isAnyModalOpen}
        z={isAnyModalOpen}
        onOverlayClick={() =>
          activeModals && activeModals.forEach((id) => dispatch({ type: 'HIDE_MODAL', payload: id }))
        }
      />
      <div className={classNames(styles.modal, className)} ref={ref} style={{ zIndex: index * 10 }}>
        <div className={styles.contentWrapper}>
          <div className={styles.titleRow}>
            <Typography.Text variant={'h2'} className={styles.title}>
              {onBackButtonClick && (
                <BackIcon
                  onClick={() => {
                    // !shouldLeaveModalOpenedOnBackButtonClick && dispatch({ type: 'HIDE_MODAL', payload: modalId });
                    appDispatch({ type: 'SET_ERROR_MESSAGE', payload: undefined });
                    onBackButtonClick();
                  }}
                  className={styles.backButton}
                />
              )}
              {title}
            </Typography.Text>
            <CloseIcon
              className={styles.closeButton}
              onClick={() => {
                dispatch({ type: 'HIDE_MODAL', payload: modalId });
                appDispatch({ type: 'SET_ERROR_MESSAGE', payload: undefined });
                onClose && onClose();
              }}
            />
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Portal>
  ) : null;
};

export { Modal };
