import { Button, MonthlyCalendar, Typography } from '../../../../../../../../components';

import { ReactComponent as CloseIcon } from '../../../../../../../../../assets/icons/times.svg';
import { FC } from 'react';
import { FormMonthlyCalendarProps } from './form-monthly-calendar.types';
import { ReactComponent as LeftIcon } from '../../../../../../../../../assets/icons/angle-left.svg';
import styles from './form-monthly-calendar.module.scss';
import { useAppContext } from '../../../../../../../../context';
import { useTranslation } from 'react-i18next';

/**
 * FormMonthlyCalendar component
 * @description FormMonthlyCalendar
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 * @param { FormMonthlyCalendarProps } props - FormMonthlyCalendarProps defined in the './form-monthly-calendar.types.ts'
 */
const FormMonthlyCalendar: FC<FormMonthlyCalendarProps> = ({ data, setFilterView }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'client' });
  const { dispatch } = useAppContext();

  return (
    <form className={styles.wrapper}>
      <div className={styles.header}>
        <Typography.Text variant={'h5'} className={styles.left}>
          <LeftIcon className={styles.icon} onClick={() => setFilterView && setFilterView()} />
          {t('pages.dashboard.notifications-drawer.filter.form.choosePeriod')}
        </Typography.Text>
        <CloseIcon className={styles.icon} onClick={() => dispatch({ type: 'CLOSE_DRAWER' })} />
      </div>

      <MonthlyCalendar {...data} />

      <div className={styles.controlButtons}>
        {(data.selectedMonths.a || data.selectedMonths.b) && (
          <Button
            size={'medium'}
            variant={'outline'}
            label={t('pages.dashboard.notifications-drawer.filter.form.controlButtons.reset')}
            onClick={() => data.setSelectedMonths({ a: undefined, b: undefined })}
          />
        )}
        <Button
          size={'medium'}
          variant={'outline'}
          label={t('pages.dashboard.notifications-drawer.filter.form.controlButtons.apply')}
          disabled={!data.selectedMonths.a && !data.selectedMonths.b}
          onClick={() => setFilterView && setFilterView()}
        />
      </div>
    </form>
  );
};

export { FormMonthlyCalendar };
