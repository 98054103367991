import { Button, Typography } from '../../../../components';
import React, { FC } from 'react';

import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download-alt-24.svg';
import { Text } from '../../components';
import styles from './header.module.scss';
import { useHeaderHook } from './header.hook';

const Header: FC = () => {
  const { t, state, handlePrint, handleGenerateModalOpen } = useHeaderHook();

  return (
    <header>
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <Text variant={'h4'} bold={700} color={'invert'}>
            {state.entity}
          </Text>
          <Text variant={'body1'} color={'invert'}>
            {state.type === 'account' ? t('accountReport') : t('portfolioReport')}
          </Text>
          <Text variant={'body1'} capitalize color={'invert'}>
            {state.date}
          </Text>
        </div>
        <nav className={styles.headerAction}>
          <Button
            type={'button'}
            variant={'main'}
            size={'medium'}
            className={styles.downloadButton}
            leadingIcon={<DownloadIcon />}
            onClick={handlePrint}
          />
          <Button
            type={'button'}
            variant={'main'}
            size={'medium'}
            className={styles.headerPadding}
            onClick={handleGenerateModalOpen}
          >
            <Typography.Text variant={'bodyText1'}>{t('newReport')}</Typography.Text>
          </Button>
        </nav>
      </div>
    </header>
  );
};

export { Header };
