import {
  GetPortfolioPerformanceOldRequest,
  GetPortfolioPerformanceOldResponse,
  GetPortfoliosResponse,
} from './portfolio.model';

import { request } from '../../http';

// temporary to avoid react script break
export {};

// import { request } from '../../http';
// import {
//   GetPortfolioAllocationRequest,
//   GetPortfolioAllocationResponse,
//   GetPortfolioCashFlowRequest,
//   GetPortfolioCashFlowResponse,
//   GetPortfolioPerformancePositionRequest,
//   GetPortfolioPerformancePositionResponse,
//   GetPortfolioPerformanceRequest,
//   GetPortfolioPerformanceResponse,
//   GetPortfolioRatesRequest,
//   GetPortfolioRatesResponse,
//   GetUserPortfolioAccountsRequest,
//   GetUserPortfolioAccountsResponse,
//   PortfolioOperationRequest,
// } from './portfolio.model';

/**
 * Get portfolio performance
 */
export const getPortfolioPerformanceOld = (data: GetPortfolioPerformanceOldRequest) =>
  request<{ readonly data: GetPortfolioPerformanceOldResponse }>({
    method: 'get',
    url: `portfolio/performance/${data.accountId}/${data.portfolioId}`,
  });

/**
 * Get portfolio rates
 */
// export const getPortfolioRates = (data: GetPortfolioRatesRequest) =>
//   request<{ readonly data: GetPortfolioRatesResponse }>({
//     method: 'get',
//     url: `portfolio/rates/${data.accountId}/${data.portfolioId}`,
//   });

/**
 * Get portfolio assets
 */
// export const getPortfolioAssets = (data: GetPortfolioPerformancePositionRequest) =>
//   request<{ readonly data: GetPortfolioPerformancePositionResponse }>({
//     method: 'get',
//     url: `portfolio/assets/${data.accountId}/${data.portfolioId}/${data.weight}/${data.unallocatedCapital}`,
//   });

/**
 * Get portfolio cash flow
 */
// export const getPortfolioCashFlow = (data: GetPortfolioCashFlowRequest) =>
//   request<{ readonly data: GetPortfolioCashFlowResponse }>({
//     method: 'get',
//     url: `portfolio/cashflow/${data.accountId}/${data.portfolioId}`,
//   });

/**
 * Get portfolio performance chart
 */
// export const getPortfolioAllocation = (data: GetPortfolioAllocationRequest) =>
//   request<{ readonly data: GetPortfolioAllocationResponse }>({
//     method: 'get',
//     url: `portfolio/allocation/${data.accountId}/${data.portfolioId}/${data.weight}/${data.unallocatedCapital}`,
//   });

/**
 * Get portfolio performance chart
 */
// export const getUserPortfolioAccounts = (data: GetUserPortfolioAccountsRequest) =>
//   request<{ readonly data: GetUserPortfolioAccountsResponse }>({
//     method: 'get',
//     url: `portfolio/user-portfolio-accounts/${data.email}`,
//   });

/**
 * Portfolio operations
 */
// export const portfolioOperation = (data: PortfolioOperationRequest) =>
//   request<{ readonly data: unknown }>({
//     method: 'post',
//     url: 'portfolio/operations',
//     data,
//   });

export const getPortfolios = () =>
  request<{ readonly data?: GetPortfoliosResponse }>({
    method: 'get',
    url: 'portfolio',
  });
