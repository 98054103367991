import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './settings-connection-modal.module.scss';
import { useSettingsConnectionModalHook } from './settings-connection-modal.hook';

/**
 * SettingsConnectionModal component
 * @description Content for the SettingsConnection window
 *
 * @author Anna Botsula, Oleksii Medvediev, Sergii Goncharuk
 * @category Components
 */
const SettingsConnectionModal: FC = () => {
  const { t, modalData, handleCloseModal } = useSettingsConnectionModalHook();

  return modalData ? (
    <Modal
      modalId={ModalIds.settingsConnection}
      title={t(`settings-page.modals.${modalData.section}.${modalData.action}.${modalData.requestResult}.title`)}
    >
      <div className={styles.settingsConnection}>
        <Typography.Text variant={'bodyText3'}>
          {t(`settings-page.modals.${modalData.section}.${modalData.action}.${modalData.requestResult}.text`)}
        </Typography.Text>
        <Button className={styles.button} type={'button'} variant={'main'} size={'large'} onClick={handleCloseModal}>
          <Typography.Text variant={'bodyText1'}>
            {t(`settings-page.modals.${modalData.section}.${modalData.action}.${modalData.requestResult}.buttonText`)}
          </Typography.Text>
        </Button>
      </div>
    </Modal>
  ) : null;
};

export { SettingsConnectionModal };
