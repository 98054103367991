import React, { FC } from 'react';

import { ToggleProps } from './toggle.types';
import classNames from 'classnames';
import styles from './toggle.module.scss';

/**
 * Toggle component
 * @description exclusively for the Formik component
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { ToggleProps } props - ToggleProps defined in the './formik-toggle.types.ts'
 */
const Toggle: FC<ToggleProps> = ({ id, onChange, value, className }) => (
  <div className={classNames(styles.toggle, className)}>
    <label htmlFor={id} className={classNames(styles.label, value && styles.checked)}>
      {/*  */}
      <input
        hidden
        className={styles.input}
        type={'checkbox'}
        id={id}
        onChange={() => {
          onChange(!value);
        }}
        checked={value}
      />
    </label>

    <span className={classNames(styles.slider, value && styles.sliderChecked)} />
  </div>
);

export { Toggle };
