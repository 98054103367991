import { Priority, RecommendationPriorityIndicatorProps } from './recommendation-priority-indicator.types';
import React, { FC, ReactElement } from 'react';

import { ReactComponent as High } from '../../../../../assets/icons/HighRec.svg';
import { ReactComponent as Inactive } from '../../../../../assets/icons/DefaultRec.svg';
import { ReactComponent as Low } from '../../../../../assets/icons/LowRec.svg';
import { ReactComponent as Middle } from '../../../../../assets/icons/MiddleRec.svg';

const indicators: Record<Priority, ReactElement> = {
  [Priority.high]: <High />,
  [Priority.inactive]: <Inactive />,
  [Priority.low]: <Low />,
  [Priority.middle]: <Middle />,
};

/**
 * RecommendationPriorityIndicator component
 * @description https://www.figma.com/file/3F1NN6p1bcDJVxnKnBCi7d/Investudio-desktop%7C-UI-Kit-(new)?type=design&node-id=258-1438&mode=dev
 *
 * @author Oleksii Medvediev
 * @param { RecommendationPriorityIndicatorProps } props - RecommendationPriorityIndicatorProps defined in the './recommendation-priority-indicator.types.ts'
 * @category Components
 */
const RecommendationPriorityIndicator: FC<RecommendationPriorityIndicatorProps> = ({ priority }) =>
  indicators[priority];

export { RecommendationPriorityIndicator };
