import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import { capitalize } from 'lodash';
import styles from './success.module.scss';
import { useSuccessData } from './success.hook';

/**
 * Success component
 * @description Exclusively for the ClosePortfolio flow as its Success stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-161637&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Success: FC = () => {
  const { t, flowData, handleSubmitSuccess } = useSuccessData();

  return (
    <Modal modalId={ModalIds.closePortfolioSuccess} title={t('modals.closePortfolio.success.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {t('modals.closePortfolio.success.description', {
            portfolioName: flowData?.portfolioName,
          })}
        </Typography.Text>
        <Button size={'large'} type={'button'} variant={'main'} onClick={handleSubmitSuccess}>
          {capitalize(t('modals.closePortfolio.success.continue'))}
        </Button>
      </div>
    </Modal>
  );
};

export { Success };
