import { useEffect, useState } from 'react';

/**
 * usePaginationHook
 * @description logic for pagination
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Hooks
 */
const usePaginationHook = (totalPages: number, onChangePage: (page: number) => void, currentPage: number) => {
  const [visiblePages, setVisiblePages] = useState<ReadonlyArray<number>>();
  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      onChangePage(page);
    }
  };

  useEffect(() => {
    const pages: number[] = [];
    const maxVisiblePages = 4;

    const start = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    const end = Math.min(start + maxVisiblePages - 1, totalPages);

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    setVisiblePages(pages);
  }, [currentPage, totalPages]);

  const handleArrowClick = (direction: 'left' | 'right') => {
    switch (direction) {
      case 'left':
        if (currentPage > 1) {
          onChangePage(currentPage - 1);
        }
        break;

      case 'right':
        if (currentPage < totalPages) {
          onChangePage(currentPage + 1);
        }
        break;

      default:
        break;
    }
  };

  return { handlePageChange, handleArrowClick, visiblePages };
};

export { usePaginationHook };
