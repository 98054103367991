import './styles/global.scss';

import { FC, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { NoDesktop, Preloader } from './components';

import { Cache } from 'aws-amplify';
import classNames from 'classnames';
import { ReactComponent as BgImg } from '../assets/images/bg-img.svg';
import styles from './app.module.scss';
import { useAppContext } from './context';
import { ProtectedRoutes } from './protected-routes.component';
import { CacheQuery } from './shared';

const App: FC = () => {
  const { isLoading, headerContent } = useAppContext();
  const { pathname } = useLocation();

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!pathname.includes('auth/')) {
      Cache.removeItem(CacheQuery.PASSWORD_RECOVERY_RESEND_COUNT);
      Cache.removeItem(CacheQuery.PASSWORD_RECOVERY_TIMER);
      Cache.removeItem(CacheQuery.TWO_FA_RESEND_COUNT);
      Cache.removeItem(CacheQuery.TWO_FA_TIMER);
    }
  }, [pathname]);

  return (
    <>
      <div className={styles.mobileWrapper}>
        <NoDesktop />
      </div>
      <div className={classNames(styles.wrapper, styles.desktopWrapper)}>
        <div className={styles.background} />
        <BgImg className={styles.bgImg} />
        <Preloader type={'overlay'} isActive={isLoading} />
        {headerContent && (
          <div ref={headerRef} className={styles.appHeader}>
            {headerContent}
          </div>
        )}
        <div className={styles.content}>
          <Routes>
            <Route path={'*'} element={<ProtectedRoutes />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default App;
