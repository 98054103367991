import { Form, Formik } from 'formik';
import React, { ChangeEvent, FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { TwoFaInput } from '../../../two-fa-input';
import { Typography } from '../../../typography';
import { capitalize } from 'lodash';
import styles from './person-confirmation.module.scss';
import { usePersonConfirmationData } from './person-confirmation.hook';

const handleIsNumberCheck = (key: string) => typeof +key === 'number';

/**
 * PersonConfirmation component
 * @description Content for the PersonConfirmation modal window
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const PersonConfirmation: FC = () => {
  const {
    t,
    input1Ref,
    input2Ref,
    input3Ref,
    input4Ref,
    countdown,
    submitButtonRef,
    email,
    errorMessage,
    initialValues,
    validationSchema,
    handleResendCode,
    handleSubmit,
    handleSetError,
  } = usePersonConfirmationData();

  return (
    <Modal
      modalId={ModalIds.personConfirmation}
      title={capitalize(t('settings-page.content.security.modals.personConfirmation.title'))}
    >
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText2'}>
          {capitalize(
            t('settings-page.content.security.modals.personConfirmation.description', { email: email ?? '' }),
          )}
        </Typography.Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
        >
          {({ setFieldValue, values: { code } }) => (
            <Form>
              <div className={styles.inputRow}>
                <div className={styles.inputsWrapper}>
                  <TwoFaInput
                    isErrorMessage={!!errorMessage}
                    className={styles.input}
                    ref={input1Ref}
                    onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('code', [+currentTarget?.value.split('')[0], code[1], code[2], code[3]]);
                      errorMessage && handleSetError(undefined);
                    }}
                    onKeyUp={({ key }: React.KeyboardEvent<HTMLInputElement>) => {
                      if (key === 'Backspace') {
                        input1Ref.current?.blur();
                      } else if (handleIsNumberCheck(key)) {
                        input2Ref.current?.focus();
                      }
                    }}
                  />
                  <TwoFaInput
                    isErrorMessage={!!errorMessage}
                    className={styles.input}
                    ref={input2Ref}
                    onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('code', [code[0], +currentTarget?.value.split('')[0], code[2], code[3]]);
                      errorMessage && handleSetError(undefined);
                    }}
                    onKeyUp={({ key }: React.KeyboardEvent<HTMLInputElement>) => {
                      if (key === 'Backspace') {
                        input1Ref.current?.focus();
                      } else if (handleIsNumberCheck(key)) {
                        input3Ref.current?.focus();
                      }
                    }}
                  />
                  <TwoFaInput
                    isErrorMessage={!!errorMessage}
                    className={styles.input}
                    ref={input3Ref}
                    onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('code', [code[0], code[1], +currentTarget?.value.split('')[0], code[3]]);
                      errorMessage && handleSetError(undefined);
                    }}
                    onKeyUp={({ key }: React.KeyboardEvent<HTMLInputElement>) => {
                      if (key === 'Backspace') {
                        input2Ref.current?.focus();
                      } else if (handleIsNumberCheck(key)) {
                        input4Ref.current?.focus();
                      }
                    }}
                  />
                  <TwoFaInput
                    isErrorMessage={!!errorMessage}
                    className={styles.input}
                    ref={input4Ref}
                    onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('code', [code[0], code[1], code[2], +currentTarget?.value.split('')[0]]);
                      errorMessage && handleSetError(undefined);
                    }}
                    onKeyUp={({ key }: React.KeyboardEvent<HTMLInputElement>) => {
                      if (key === 'Backspace') {
                        input3Ref.current?.focus();
                      } else if (handleIsNumberCheck(key)) {
                        submitButtonRef.current?.focus();
                      }
                    }}
                  />
                </div>
                {errorMessage && (
                  <Typography.Text variant={'label4'} className={styles.errorMessage}>
                    {errorMessage}
                  </Typography.Text>
                )}
              </div>
              <Typography.Text variant={'bodyText3'} className={styles.resendCode}>
                {t('settings-page.content.security.modals.personConfirmation.noCode')}{' '}
                <Button disabled={countdown > 0} variant={'borderless'} onClick={handleResendCode} size={'large'}>
                  <Typography.Text variant={'linkButton1'}>
                    {t('settings-page.content.security.modals.personConfirmation.resend')}
                  </Typography.Text>
                  {!!countdown && <Typography.Text variant={'bodyText3'}> &nbsp;({countdown})</Typography.Text>}
                </Button>
              </Typography.Text>
              <Button
                variant={'main'}
                size={'large'}
                type={'submit'}
                disabled={!code.every((number) => Number.isInteger(number))}
              >
                <Typography.Text variant={'bodyText1'}>
                  {capitalize(t('settings-page.content.security.modals.personConfirmation.continue'))}
                </Typography.Text>
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export { PersonConfirmation };
