import { Customization, Error, Request, Success, Warning } from './components';
import React, { useCallback, useMemo, useState } from 'react';
import { SetProps, SetStageTimePeriod } from './set.types';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { CustomizationProps } from './components/customization/customization.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { RequestProps } from './components/request/request.types';
import { SuccessProps } from './components/success/success.types';
import { WarningProps } from './components/warning/warning.types';
import dayjs from 'dayjs';
import { setAutomatedOrdersConfirmationExpDate } from '../../../../../../services/api';
import { useTranslation } from 'react-i18next';

const formatPeriodLabel = (startDate: Date, endDate: Date, t: (value: string) => string): string => {
  const dividedStartDate = dayjs(startDate).format('D MMMM YYYY').split(' ');
  const dividedEndDate = dayjs(endDate).format('D MMMM YYYY').split(' ');
  if (dividedStartDate[2] === dividedEndDate[2]) {
    if (dividedStartDate[1] === dividedEndDate[1]) {
      if (dividedStartDate[0] === dividedEndDate[0]) {
        // D MMMM YYYY
        return dividedStartDate.map((item, index) => (index === 1 ? t(`casedMonths.${item}`) : item)).join(' ');
      } else {
        // D - D MMMM YYYY
        return `${dividedStartDate[0]} - ${dividedEndDate[0]} ${t(`casedMonths.${dividedStartDate[1]}`)} ${
          dividedStartDate[2]
        }`;
      }
    } else {
      // D MMMM - D MMMM YYYY
      return `${dividedStartDate[0]} ${t(`casedMonths.${dividedStartDate[1]}`)} - ${dividedEndDate[0]} ${t(
        `casedMonths.${dividedEndDate[1]}`,
      )} ${dividedStartDate[2]}`;
    }
  } else {
    // D MMMM YYYY - D MMMM YYYY
    return `${dividedStartDate
      .map((item, index) => (index === 1 ? t(`casedMonths.${item}`) : item))
      .join(' ')} - ${dividedEndDate.map((item, index) => (index === 1 ? t(`casedMonths.${item}`) : item)).join(' ')}`;
  }
};

/**
 * useSetData hook
 * @description The hook which processes AutomatedOrdersConformation Set flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useSetData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { automatedOrdersConfirmationSet },
    dispatch,
  } = useModalsContext();

  const [period, setPeriod] = useState<SetStageTimePeriod>();

  const flowData: SetProps | undefined = useMemo(
    () => automatedOrdersConfirmationSet,
    [automatedOrdersConfirmationSet],
  );

  const setStage = useCallback(
    (stage: SetProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.automatedOrdersConfirmationSet,
          data: {
            [ModalIds.automatedOrdersConfirmationSet]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    SetProps['currentStage'],
    RequestProps['onSubmit'] | WarningProps['onSubmit'] | CustomizationProps['onSubmit'] | SuccessProps['onClose']
  > = useMemo(
    () => ({
      request: () => {
        setStage('warning');
      },
      warning: () => {
        setStage('customization');
      },
      customization: async () => {
        if (user && flowData && period) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { data } = await setAutomatedOrdersConfirmationExpDate({
              strategyId: flowData.strategyId,
              period: {
                endDate: dayjs(period.endDate).format('YYYY-MM-DD HH-mm-ss'),
                startDate: dayjs(period.startDate).format('YYYY-MM-DD HH-mm-ss'),
              },
              updatedBy: user.email,
            });
            if (data && data.automatedOrdersConfirmationExpDate) {
              flowData.onRefetch();
              setStage('success');
            } else {
              setStage('error');
            }
          } catch (error) {
            console.error(error);
            setStage('error');
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationSet });
      },
      error: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationSet });
      },
    }),
    [appDispatch, flowData, dispatch, period, setStage, user],
  );

  const stages: Record<SetProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            request: <Request onSubmit={submitHandlers.request} />,
            warning: <Warning onSubmit={submitHandlers.warning} />,
            customization: (
              <Customization
                onSubmit={submitHandlers.customization}
                period={period}
                setPeriod={(value) => setPeriod(value)}
              />
            ),
            success: period ? (
              <Success
                periodLabel={formatPeriodLabel(period.startDate, period.endDate, t)}
                onClose={submitHandlers.success}
              />
            ) : (
              <></>
            ),
            error: <Error onClose={submitHandlers.error} />,
          }
        : undefined,
    [
      flowData,
      period,
      submitHandlers.customization,
      submitHandlers.error,
      submitHandlers.request,
      submitHandlers.success,
      submitHandlers.warning,
      t,
    ],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useSetData, formatPeriodLabel };
