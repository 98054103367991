import { FC } from 'react';

import classNames from 'classnames';
import { Typography } from '../../../typography';
import styles from './connection-indicator.module.scss';
import { ConnectionIndicatorProps } from './connection-indicator.types';

/**
 * ConnectionIndicator component
 * @description Exclusively for the Header component as its connection indicator.
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { ConnectionIndicatorProps } props - ConnectionIndicatorProps defined in the './connection-indicator.types.ts'
 */
const ConnectionIndicator: FC<ConnectionIndicatorProps> = ({ connectionName, isConnected }) => (
  <div className={styles.wrapper}>
    <div className={classNames(styles.indicator, isConnected && styles.connected)} />

    {connectionName && (
      <Typography.Text variant={'bodyText3'} className={styles.name}>
        {connectionName}
      </Typography.Text>
    )}
  </div>
);

export { ConnectionIndicator };
