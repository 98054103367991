import { GetAccounts, getAccounts } from '../services';
import { ManagerP2Response, getManagerParticipants } from '../../../services/api';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import React, { useCallback } from 'react';

import { Cache } from 'aws-amplify';
import { CacheQuery } from '../../../../../auth/app/shared';
import { Preloader } from '../components';
import { UserRole } from '../../../contexts/app/app.types';
import { useAppContext } from '../../../contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '../shared';
import { AxiosError } from 'axios';

const initialState: { readonly accounts: GetAccounts[]; readonly currentAccount?: ManagerP2Response[number] } = {
  accounts: [],
};

const Context = createContext(initialState);

const { Provider } = Context;

const AccountsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { parsed } = useQueryParams();
  const { user } = useAppContext();
  const params = useParams();
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState(initialState.accounts);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentAccount, setCurrentAccount] = useState<ManagerP2Response[number]>();

  const fetchAccounts = useCallback(async () => {
    try {
      const { data } = await getAccounts({ email: parsed.email });

      setAccounts(data);
    } catch (error) {
      if (((error as AxiosError).response?.data as { message: string })?.message === 'forbidden') {
        return navigate('/auth/login');
      }

      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [parsed.email, navigate]);

  const fetchAccount = useCallback(async () => {
    if (accounts && !currentAccount && (user || Cache.getItem(CacheQuery.USER)?.email)) {
      const { data } = await getManagerParticipants({
        role: UserRole.manager,
        roleLoginEmail: user?.email ?? Cache.getItem(CacheQuery.USER)?.email,
      });
      if (data) {
        const account = data.find(({ AccountID }) => AccountID === params.accountId);
        account && setCurrentAccount(account);
      }
    }
  }, [accounts, currentAccount, params.accountId, user]);

  useEffect(() => {
    !accounts.length && fetchAccounts();
    fetchAccount();
  }, [accounts, fetchAccount, fetchAccounts]);

  return (
    <Provider value={{ accounts, currentAccount }}>
      {error || loading ? <Preloader variant="blank" /> : children}
    </Provider>
  );
};

const useAccounts = () => {
  return useContext(Context);
};

export { AccountsProvider, useAccounts };
