import React, { FC } from 'react';

import { TextProps } from './text.types';
import classNames from 'classnames';
import styles from '../typography.module.scss';

/**
 * Typography.Text component
 * @description component which render text
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { TextProps } props - text defined in the './text.types.ts'
 */
const Text: FC<TextProps> = ({ variant = 'bodyText1', children, className, title }) => (
  <div className={classNames(styles[variant], className)} title={title}>
    {children}
  </div>
);
export { Text };
