import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './order-acceptation.module.scss';
import { useOrderAcceptationData } from './order-acceptation.hook';

/**
 * OrderAcceptation component
 * @description Content for the OrderAcceptation modal.
 * @link https://www.figma.com/file/pCwb00CHfXi1s4Yd5q3q0U/Trader--%7C-File-for-development?type=design&node-id=1787-138346&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const OrderAcceptation: FC = () => {
  const { t, currentType, handleCloseModal } = useOrderAcceptationData();

  return (
    <Modal modalId={ModalIds.orderAcceptation} title={t(`modals.orderAcceptation.title-${currentType}`)}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {t(`modals.orderAcceptation.description-${currentType}`)}
        </Typography.Text>
        <Button type={'button'} variant={'main'} size={'large'} onClick={handleCloseModal}>
          <Typography.Text variant={'bodyText1'}>{t(`modals.orderAcceptation.continue`)}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { OrderAcceptation };
