import React, { FC } from 'react';

import { StepperProps } from './stepper.types';
import { Typography } from '../typography';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import styles from './stepper.module.scss';

const Stepper: FC<StepperProps> = ({ currentStep, steps, className }) => (
  <div className={classNames(styles.stepper, className)}>
    {steps.map(({ id, label, onStepChange }, index) => {
      const isPrevious: boolean = index < steps.indexOf(steps.find((step) => step.id === currentStep) ?? steps[0]);

      return (
        <div
          className={classNames(styles.tab, currentStep === id && styles.active, isPrevious && styles.prev)}
          key={id}
          tabIndex={0}
          role={'button'}
          onKeyDown={() => isPrevious && onStepChange()}
          onClick={() => isPrevious && onStepChange()}
        >
          <Typography.Text variant={'bodyText2'} className={styles.label}>
            {`${index + 1}. ${capitalize(label.toLowerCase())}`}
          </Typography.Text>
          <div className={styles.bottomLine} />
        </div>
      );
    })}
  </div>
);

export { Stepper };
