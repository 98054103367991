import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from '../../../../../assets/icons/angle-right.svg';
import { Typography } from '../../../typography';
import styles from './security-item.module.scss';
import { SecurityItemProps } from './security-item.types';

const Wrapper: FC<PropsWithChildren & Omit<SecurityItemProps, 'icon' | 'title'>> = ({
  children,
  link,
  bottomBorder,
  onClick,
}) =>
  link ? (
    <NavLink to={link} className={classNames(styles.wrapper, bottomBorder && styles.bottomBorder)}>
      {children}
    </NavLink>
  ) : (
    <button
      tabIndex={0}
      type="button"
      aria-label="button"
      className={classNames(styles.wrapper, bottomBorder && styles.bottomBorder, styles.button)}
      onClick={onClick && onClick}
    >
      {children}
    </button>
  );

const SecurityItem: FC<SecurityItemProps> = ({ title, icon, link, bottomBorder = true, onClick }) => {
  return (
    <Wrapper link={link} bottomBorder={bottomBorder} onClick={onClick}>
      <div className={styles.leftBlock}>
        <div className={styles.icon}>{icon}</div>
        <Typography.Text variant="label1" className={styles.title}>
          {title}
        </Typography.Text>
      </div>
      <RightArrowIcon className={styles.rightArrowIcon} />
    </Wrapper>
  );
};

export { SecurityItem };
