import { useAppContext, useModalsContext } from '../../../../contexts';
import { useCallback, useMemo } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { getAdminInvoices } from '../../../../services/api';
import { useTranslation } from 'react-i18next';

/**
 * useInvoiceCreatedModal
 * @description Gets useInvoiceCreatedModal component data from store
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Hooks
 * @returns AccountCreatedData
 */
const useInvoiceCreatedModal = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { invoiceCreated },
    dispatch,
  } = useModalsContext();
  const { dispatch: appDispatch } = useAppContext();

  const modalData = useMemo(() => invoiceCreated, [invoiceCreated]);

  const handleContinueClick = useCallback(async () => {
    if (modalData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const data = await getAdminInvoices({
          id: modalData.subscriptionId,
          currentSubscription: modalData.currentSubscription,
        });
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        }
      } catch (error) {
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.invoiceCreated });
  }, [appDispatch, dispatch, modalData]);

  return {
    t,
    handleContinueClick,
  };
};

export { useInvoiceCreatedModal };
