import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './success.types';
import { Typography } from '../../../../../../../typography';
import styles from './success.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Success component
 * @description Content for the Success stage modal of PortfoliosSettings Add flow
 *
 * @author Oleksii Medvediev
 * @param { SuccessProps } props - SuccessProps defined in the './success.component.tsx'
 * @category Components
 */
const Success: FC<SuccessProps> = ({ onClose, accountId, clientNameOwner }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  return (
    <Modal modalId={ModalIds.portfoliosSettingsAdd} title={t('modals.portfoliosSettings.add.success.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {t('modals.portfoliosSettings.add.success.text', { clientNameOwner, accountId: accountId.toUpperCase() })}
        </Typography.Text>
        <Button type={'button'} variant={'main'} size={'large'} onClick={onClose}>
          {t('modals.portfoliosSettings.add.success.continue')}
        </Button>
      </div>
    </Modal>
  );
};

export { Success };
