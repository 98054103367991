import { FC } from 'react';
import { Overlay } from '../overlay';
import { Portal } from '../portal';
import classNames from 'classnames';
import styles from './drawer.module.scss';
import { useAppContext } from '../../context';

const Drawer: FC = () => {
  const { dispatch, drawer } = useAppContext();

  if (drawer?.isOpened && drawer.data) {
    return (
      <Portal domNode="drawer-root">
        <Overlay
          isShown={!!drawer.isOpened}
          blur={5}
          onOverlayClick={() => {
            dispatch({ type: 'CLOSE_DRAWER' });
          }}
        />

        <div className={classNames(styles.drawer, drawer.data.maxHeight && styles.maxHeight)}>
          {drawer.data.children}
        </div>
      </Portal>
    );
  }

  return null;
};

export { Drawer };
