enum CacheQuery {
  USER = 'ISMOUser',
  CURRENT_ROLE = 'ISMOCurrentRole',
  PASSWORD = 'ISPassword',
  REMEMBER_USER = 'ISRememberUser',
  COMPANY_NAME = 'ISCompanyName',
  LOGO = 'ISLogo',
  NEW_PARTICIPANT_SETUP_VALUES = 'newParticipantSetupValues',
}

export { CacheQuery };
