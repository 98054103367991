import {
  GetPortfolioFramesResponse,
  GetTickerQuotesPayload,
  GetTickerQuotesResponse,
  ManagerGetAccountFeesPayload,
  ManagerGetAccountFeesResponse,
  ManagerGetAccountPortfolioSnapshotPayload,
  ManagerGetAccountPortfolioSnapshotResponse,
  ManagerGetAccountPortfoliosPayload,
  ManagerGetAccountPortfoliosResponse,
  ManagerGetAccountPortfoliosSnapshotPayload,
  ManagerGetAccountPortfoliosSnapshotResponse,
  ManagerGetAccountStrategyPayload,
  ManagerGetAccountStrategyResponse,
  ManagerGetAccountsByEmailPayload,
  ManagerGetAccountsByEmailResponse,
  ManagerGetPortfolioChartPayload,
  ManagerGetPortfolioChartResponse,
  ManagerGetPortfolioPayload,
  ManagerGetStrategiesPayload,
  ManagerGetStrategiesResponse,
  ManagerInitResponse,
  ManagerP1Payload,
  ManagerP1Response,
  ManagerP2Payload,
  ManagerP2Response,
  ManagerP2_1_1Payload,
  ManagerP2_1_1Response,
  ManagerP2_2_1_3_f1Payload,
  ManagerP2_2_1_3_f1Response,
  ManagerP2_2_1_3_f2Payload,
  ManagerP2_2_1_3_f2Response,
  ManagerP2_2_1_4_fPayload,
  ManagerP2_2_1_4_fResponse,
  ManagerP2_2_1_4_pPayload,
  ManagerP2_2_1_4_pResponse,
  ManagerP2_2_1_5Payload,
  ManagerP2_2_1_5Response,
  ManagerP2_2_1_6Payload,
  ManagerP2_2_1_6Response,
  ManagerP2_3_1Payload,
  ManagerP2_3_4_setPayload,
  ManagerP2_4_1Payload,
  ManagerP2_4_2Payload,
  ManagerP2_4_3Payload,
  ManagerP2_4_4_closePayload,
  ManagerP2_4_4_getPayload,
  ManagerP2_4_5Payload,
  ManagerP3Payload,
  ManagerP3Response,
  ManagerP4Payload,
  ManagerP4Response,
  ManagerP4_1Payload,
  ManagerP4_1Response,
  ManagerP4_1_1Payload,
  ManagerP4_2Payload,
  ManagerP4_2Response,
  ManagerP4_3Payload,
  ManagerP4_3Response,
  ManagerP5Payload,
  ManagerP5Response,
  ManagerSendBulkReportPayload,
  ManagerSendReportPayload,
  ManagerSetAccountEntitiesPayload,
  ManagerSetAccountEntitiesResponse,
  ManagerSetAccountPortfoliosPayload,
  ManagerSetAccountStrategyPayload,
} from './manager.model';

import { request } from '../../http';

export const getManagerHeaderData = ({ roleLoginEmail, role }: ManagerP1Payload) =>
  request<{ readonly data: ManagerP1Response }>({
    method: 'get',
    url: `office/manager/p1?roleLoginEmail=${roleLoginEmail}&role=${role}`,
  });

export const getManagerParticipants = ({ roleLoginEmail, role }: ManagerP2Payload) =>
  request<{ readonly data: ManagerP2Response }>({
    method: 'get',
    url: `office/manager/p2?roleLoginEmail=${roleLoginEmail}&role=${role}`,
  });

export const getManagerMessages = ({ roleLoginEmail, lang, role }: ManagerP3Payload) =>
  request<{ readonly data: ManagerP3Response }>({
    method: 'get',
    url: `office/manager/p3?roleLoginEmail=${roleLoginEmail}&role=${role}&lang=${lang}`,
  });

export const getManagerReactingPanelData = ({ roleLoginEmail, lang, role }: ManagerP4Payload) =>
  request<{ readonly data: ManagerP4Response }>({
    method: 'get',
    url: `office/manager/p4?roleLoginEmail=${roleLoginEmail}&role=${role}&lang=${lang}`,
  });

export const getManagerFaq = ({ role, language, topicLimit }: ManagerP5Payload) =>
  request<{ readonly data: ManagerP5Response }>({
    method: 'get',
    url: `office/manager/p5?role=${role}&lang=${language}&topicLimit=${topicLimit}`,
  });

export const getManagerClientRequests = ({ roleLoginEmail, lang, role }: ManagerP4_1Payload) =>
  request<{ readonly data: ManagerP4_1Response }>({
    method: 'get',
    url: `office/manager/p4_1?roleLoginEmail=${roleLoginEmail}&role=${role}&lang=${lang}`,
  });

export const setManagerClientRequests = ({ roleLoginEmail, role, requestId, newStatus }: ManagerP4_1_1Payload) =>
  request<{ readonly data: boolean }>({
    method: 'post',
    url: `office/manager/p4_1_1?roleLoginEmail=${roleLoginEmail}&role=${role}&requestId=${requestId}&newStatus=${newStatus}`,
  });

export const getManagerUndividedCapital = ({ roleLoginEmail, role }: ManagerP4_3Payload) =>
  request<{ readonly data: ManagerP4_3Response }>({
    method: 'get',
    url: `office/manager/p4_3?roleLoginEmail=${roleLoginEmail}&role=${role}`,
  });

export const getManagerReportsHistory = ({ roleLoginEmail, role }: ManagerP2_1_1Payload) =>
  request<{ readonly data: ManagerP2_1_1Response }>({
    method: 'get',
    url: `office/manager/p2_1_1?roleLoginEmail=${roleLoginEmail}&role=${role}`,
  });

export const getManagerFreeAccounts = ({ roleLoginEmail, role }: ManagerP4_2Payload) =>
  request<{ readonly data: ManagerP4_2Response }>({
    method: 'get',
    url: `office/manager/p4_2?roleLoginEmail=${roleLoginEmail}&role=${role}`,
  });

export const getManagerParticipantsIncomeF1 = ({ acctId, portfolioId, limitCount }: ManagerP2_2_1_3_f1Payload) =>
  request<{ readonly data: ManagerP2_2_1_3_f1Response }>({
    method: 'get',
    url: `office/manager/p2_2_1_3_f1?acctId=${acctId}&portfolioId=${portfolioId}&limitCount=${limitCount}`,
  });

export const getManagerParticipantsIncomeF2 = ({ acctId, portfolioId }: ManagerP2_2_1_3_f2Payload) =>
  request<{ readonly data: ManagerP2_2_1_3_f2Response }>({
    method: 'get',
    url: `office/manager/p2_2_1_3_f2?acctId=${acctId}&portfolioId=${portfolioId}`,
  });

export const getManagerParticipantsAssets = ({
  acctId,
  portfolioId,
  weightPortfolioIdNumeric,
  unlocatedCap,
}: ManagerP2_2_1_4_fPayload) =>
  request<{ readonly data: ManagerP2_2_1_4_fResponse }>({
    method: 'get',
    url: `office/manager/p2_2_1_4_f?acctId=${acctId}&portfolioId=${portfolioId}&weightPortfolioIdNumeric=${weightPortfolioIdNumeric}&unlocatedCap=${unlocatedCap}`,
  });

export const setManagerParticipantsAssets = ({
  acctId,
  portfolioId,
  weightPortfolioIdNumeric,
  unlocatedCap,
}: ManagerP2_2_1_4_pPayload) =>
  request<{ readonly data: ManagerP2_2_1_4_pResponse }>({
    method: 'get',
    url: `office/manager/p2_2_1_4_p?acctId=${acctId}&portfolioId=${portfolioId}&weightPortfolioIdNumeric=${weightPortfolioIdNumeric}&unlocatedCap=${unlocatedCap}`,
  });

export const getManagerParticipantsOperations = ({ acctId, portfolioId, fromDate, toDate }: ManagerP2_2_1_5Payload) =>
  request<{ readonly data: ManagerP2_2_1_5Response }>({
    method: 'get',
    url: `office/manager/p2_2_1_5?acctId=${acctId}&portfolioId=${portfolioId}&fromDate=${fromDate}&toDate=${toDate}}`,
  });

export const getManagerParticipantsFundsTransfer = ({ acctId, portfolioId }: ManagerP2_2_1_6Payload) =>
  request<{ readonly data: ManagerP2_2_1_6Response }>({
    method: 'get',
    url: `office/manager/p2_2_1_6?acctId=${acctId}&portfolioId=${portfolioId}`,
  });

export const setManagerParticipantsPortfolioDeposit = ({
  roleLoginEmail,
  role,
  acctId,
  portfolioId,
  sumDeposit,
}: ManagerP2_4_1Payload) =>
  request({
    method: 'get',
    url: `office/manager/p2_4_1?roleLoginEmail=${roleLoginEmail}&role=${role}&acctId=${acctId}&portfolioId=${portfolioId}&sumDeposit=${sumDeposit}`,
  });

export const setManagerParticipantsPortfolioWithdraw = ({
  roleLoginEmail,
  role,
  acctId,
  portfolioId,
  sumWithdraw,
}: ManagerP2_4_2Payload) =>
  request({
    method: 'get',
    url: `office/manager/p2_4_2?roleLoginEmail=${roleLoginEmail}&role=${role}&acctId=${acctId}&portfolioId=${portfolioId}&sumWithdraw=${sumWithdraw}`,
  });

export const setManagerParticipantsPortfolioTransfer = ({
  roleLoginEmail,
  role,
  acctId,
  fromPortfolioId,
  toPortfolioId,
  sumTransfer,
}: ManagerP2_4_3Payload) =>
  request({
    method: 'get',
    url: `office/manager/p2_4_3?roleLoginEmail=${roleLoginEmail}&role=${role}&acctId=${acctId}&fromPortfolioId=${fromPortfolioId}&toPortfolioId=${toPortfolioId}&sumTransfer=${sumTransfer}`,
  });

export const closeManagerParticipantsPortfolio = ({
  roleLoginEmail,
  role,
  acctId,
  portfolioId,
}: ManagerP2_4_4_closePayload) =>
  request<{ readonly data: unknown }>({
    method: 'get',
    url: `office/manager/p2_4_4_close?roleLoginEmail=${roleLoginEmail}&role=${role}&acctId=${acctId}&portfolioId=${portfolioId}`,
  });

export const getManagerParticipantsPortfolioSnapshot = ({ acctId, portfolioId }: ManagerP2_4_4_getPayload) =>
  request<{ readonly data: unknown }>({
    method: 'get',
    url: `office/manager/p2_4_4_get?acctId=${acctId}&portfolioId=${portfolioId}`,
  });

export const setManagerParticipantsPortfolioName = ({
  roleLoginEmail,
  role,
  acctId,
  portfolioId,
  newNamePortfolio,
}: ManagerP2_4_5Payload) =>
  request<{ readonly data: unknown }>({
    method: 'get',
    url: `office/manager/p2_4_5?roleLoginEmail=${roleLoginEmail}&role=${role}&acctId=${acctId}&portfolioId=${portfolioId}&newNamePortfolio=${newNamePortfolio}`,
  });

export const setAccountWithdraw = ({ acctId, role, roleLoginEmail, sumWithdraw, targetDate }: ManagerP2_3_1Payload) =>
  request({
    method: 'post',
    url: `office/manager/p2_3_1?roleLoginEmail=${roleLoginEmail}&role=${role}&acctId=${acctId}&sumWithdraw=${sumWithdraw}&targetDate=${targetDate}`,
  });

export const getAccountPortfoliosSnapshot = ({
  acctId,
  role,
  roleLoginEmail,
}: ManagerGetAccountPortfoliosSnapshotPayload) =>
  request<{ readonly data: ManagerGetAccountPortfoliosSnapshotResponse }>({
    method: 'get',
    url: `office/manager/account-portfolios-snapshot?acctId=${acctId}&roleLoginEmail=${roleLoginEmail}&role=${role}`,
  });

export const setAccountEntities = (data: ManagerSetAccountEntitiesPayload) =>
  request<{ readonly data: ManagerSetAccountEntitiesResponse }>({
    method: 'post',
    data,
    url: 'office/manager/set-account-entities',
  });

export const getAccountStrategy = ({ acctId, role, roleLoginEmail }: ManagerGetAccountStrategyPayload) =>
  request<{ readonly data: ManagerGetAccountStrategyResponse }>({
    method: 'get',
    url: `office/manager/get-account-strategy?role=${role}&roleLoginEmail=${roleLoginEmail}&acctId=${acctId}`,
  });

export const getStrategies = ({ lang, role, roleLoginEmail }: ManagerGetStrategiesPayload) =>
  request<{ readonly data: ManagerGetStrategiesResponse }>({
    method: 'get',
    url: `office/manager/strategies?lang=${lang}&role=${role}&roleLoginEmail=${roleLoginEmail}`,
  });

export const getAccountPortfolios = ({ acctId, role, roleLoginEmail }: ManagerGetAccountPortfoliosPayload) =>
  request<{ readonly data: ManagerGetAccountPortfoliosResponse }>({
    method: 'get',
    url: `office/manager/account-portfolios?acctId=${acctId}&role=${role}&roleLoginEmail=${roleLoginEmail}`,
  });

export const getAccountPortfolioSnapshot = ({ acctId }: ManagerGetAccountPortfolioSnapshotPayload) =>
  request<{ readonly data: ReadonlyArray<ManagerGetAccountPortfolioSnapshotResponse> }>({
    method: 'get',
    url: `office/manager/portfolio-snapshot?acctId=${acctId}`,
  });

export const setAccountStrategy = (data: Omit<ManagerSetAccountStrategyPayload, 'portfoliosDoc'>) =>
  request({ method: 'post', data, url: 'office/manager/set-account-strategy' });

export const setAccountPortfolios = (data: ManagerSetAccountPortfoliosPayload) =>
  request({ method: 'post', data, url: 'office/manager/set-account-portfolios' });

export const setAccountFees = (data: ManagerP2_3_4_setPayload) =>
  request({ method: 'post', data, url: 'office/manager/set-account-fees' });

export const getAccountFees = ({ acctId, role, roleLoginEmail }: ManagerGetAccountFeesPayload) =>
  request<{ readonly data: ManagerGetAccountFeesResponse }>({
    method: 'get',
    url: `office/manager/account-fees?acctId=${acctId}&role=${role}&roleLoginEmail=${roleLoginEmail}`,
  });

export const getTickerQuotes = (data: GetTickerQuotesPayload) =>
  request<{ readonly data: GetTickerQuotesResponse }>({
    url: 'common/ticker/quotes',
    method: 'get',
    params: { ticker: data.ticker, isin: data.isin, period: data.period },
  });

export const getPortfolioChart = ({ accountId, portfolioId, period }: ManagerGetPortfolioChartPayload) =>
  request<{ readonly data: ManagerGetPortfolioChartResponse }>({
    method: 'get',
    url: `office/manager/get-portfolio-chart?accountId=${accountId}&portfolioId=${portfolioId}&period=${period}`,
  });

export const getFrames = () =>
  request<{ readonly data: GetPortfolioFramesResponse }>({
    method: 'get',
    url: 'office/manager/get-frames',
  });

export const getPortfolio = ({ email }: ManagerGetPortfolioPayload) =>
  request<{ readonly data: unknown }>({
    method: 'get',
    url: `office/manager/get-portfolio-list?email=${email}`,
  });

export const sendReport = (payload: ManagerSendReportPayload) =>
  request({ method: 'post', data: payload, url: 'office/manager/report/send' });

export const sendBulkReport = (payload: ManagerSendBulkReportPayload) =>
  request({ method: 'post', data: payload, url: 'office/manager/report/send/bulk' });

export const getInitForManager = (params: ManagerP4Payload & ManagerP4_1Payload & ManagerP3Payload) =>
  request<{ readonly data?: ManagerInitResponse }>({ method: 'get', url: 'office/manager/init', params });

export const getAccountsByEmail = (payload: ManagerGetAccountsByEmailPayload) =>
  request<{ readonly data: ManagerGetAccountsByEmailResponse }>({
    method: 'post',
    data: payload,
    url: 'account/get-accounts-by-email',
  });
