import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './request-service-action-modal.module.scss';
import { useRequestServiceActionModalHook } from './request-service-action-modal.hook';
import { SettingsSection } from '../../../../services/api';
import { Preloader } from '../../../preloader';

/**
 * RequestServiceActionModal component
 * @description Content for the RequestServiceAction window
 *
 * @author Sergii Goncharuk
 * @category Components
 */
const RequestServiceActionModal: FC = () => {
  const { t, modalData } = useRequestServiceActionModalHook();

  return modalData ? (
    <Modal modalId={ModalIds.requestServiceAction} title={t(`settings-page.modals.${modalData.section}.request.title`)}>
      <div className={styles.requestServiceAction}>
        <div className={styles.content}>
          <Preloader type={'small'} color="yellow" isActive={true} />

          {modalData.section === SettingsSection.ibGateway && (
            <div className={styles.message}>
              <Typography.Text variant={'bodyText3'}>
                {t(`settings-page.modals.${modalData.section}.request.text1`)}
              </Typography.Text>
              <Typography.Text variant={'bodyText3'}>
                {t(`settings-page.modals.${modalData.section}.request.text2`)}
              </Typography.Text>
              <Typography.Text variant={'bodyText3'}>
                {t(`settings-page.modals.${modalData.section}.request.text3`)}
              </Typography.Text>
            </div>
          )}

          {modalData.section === SettingsSection.tradingModule && (
            <Typography.Text variant={'bodyText3'}>
              {t(`settings-page.modals.${modalData.section}.request.text`)}
            </Typography.Text>
          )}
        </div>

        <Button
          className={styles.button}
          type={'button'}
          variant={'main'}
          size={'large'}
          onClick={modalData.cancelHandler}
        >
          <Typography.Text variant={'bodyText1'}>
            {t(`settings-page.modals.${modalData.section}.request.buttonText`)}
          </Typography.Text>
        </Button>
      </div>
    </Modal>
  ) : null;
};

export { RequestServiceActionModal };
