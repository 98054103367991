import {
  ChangeColorThemePayload,
  ChangeColorThemeResponse,
  ChangeLanguagePayload,
  ChangeLanguageResponse,
  GetAutomatedOrdersConfirmationExpDateResponse,
  GetIBGatewayConnectionStatusResponse,
  GetTradingModuleConnectionStatusResponse,
  GetTradeVisionConnectionStatusResponse,
  GetSupportInfoResponse,
  GetUnreadMessagesCountPayload,
  GetUnreadMessagesCountResponse,
  SetAutomatedOrdersConfirmationExpDatePayload,
  SetAutomatedOrdersConfirmationExpDateResponse,
  SetMessageReadStatusPayload,
  SetMessagesStatusReadPayload,
  ToggleTwoFaPayload,
  ToggleTwoFaResponse,
} from './general.model';

import { request } from '../../http';

/**
 * GeneralService
 * @description Service for general requests.
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 * @category Services
 */
export const changeLanguage = (data: ChangeLanguagePayload) =>
  request<{ readonly data: ChangeLanguageResponse }>({ method: 'post', data, url: 'office/change-language' });

export const changeColorTheme = (data: ChangeColorThemePayload) =>
  request<{ readonly data: ChangeColorThemeResponse }>({ method: 'post', data, url: 'office/change-color-theme' });

export const toggle2Fa = (data: ToggleTwoFaPayload) =>
  request<{ readonly data: ToggleTwoFaResponse }>({ method: 'post', data, url: 'office/toggle-two-fa' });

export const setAutomatedOrdersConfirmationExpDate = (data: SetAutomatedOrdersConfirmationExpDatePayload) =>
  request<{ readonly data: SetAutomatedOrdersConfirmationExpDateResponse }>({
    method: 'post',
    data,
    url: 'office/admin/set-automated-orders-confirmation-exp-date',
  });

export const getAutomatedOrdersConfirmationExpDate = () =>
  request<{ readonly data: GetAutomatedOrdersConfirmationExpDateResponse }>({
    method: 'get',
    url: `office/admin/automated-orders-confirmation-exp-date`,
  });

export const getIBGatewayConnectionStatus = (email?: string) =>
  request<{ readonly data: GetIBGatewayConnectionStatusResponse }>({
    method: 'get',
    url: 'office/connection-status',
    params: { email },
  });

export const getTradingModuleConnectionStatus = () =>
  request<{ readonly data: GetTradingModuleConnectionStatusResponse }>({
    method: 'get',
    url: 'ibkr-tws/connection-status',
  });

export const getTradeVisionConnectionStatus = (email?: string) =>
  request<{ readonly data: GetTradeVisionConnectionStatusResponse }>({
    method: 'get',
    url: 'office/connection-status',
    params: { email },
  });

export const setMessageReadStatus = ({ roleLoginEmail, role, messageId, readMark }: SetMessageReadStatusPayload) =>
  request({
    method: 'post',
    url: `office/manager/p3_1?roleLoginEmail=${roleLoginEmail}&role=${role}&messageId=${messageId}&readMark=${readMark}`,
  });

export const getUnreadMessagesCount = (params: GetUnreadMessagesCountPayload) =>
  request<{ readonly data: GetUnreadMessagesCountResponse }>({
    method: 'get',
    url: 'office/unread-messages-count',
    params,
  });

export const setAllMessagesReadStatus = (params: SetMessagesStatusReadPayload) =>
  request({
    method: 'post',
    url: 'office/set-messages-status-read',
    params,
  });

export const getEmailTransporterStatus = () =>
  request<{ readonly data?: { readonly connection: boolean } }>({
    method: 'get',
    url: 'office/email-transporter-status',
  });

export const getSupportInfo = () =>
  request<{ readonly data: GetSupportInfoResponse }>({ method: 'get', url: 'is-connector/support' });
