import React, { FC } from 'react';
import { dateIsBetween, getMonthsFromTo, monthByIndex } from '../../../../utils';

import { Button } from '../../../button';
import { Modal } from '../../../modal';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Tab } from '../../../tab';
import { Typography } from '../../../typography';
import classNames from 'classnames';
import styles from './monthly-calendar-modal.module.scss';
import { useMonthlyCalendarModalData } from './monthly-calendar-modal.hook';
import { v4 } from 'uuid';

/**
 * MonthlyCalendarModal component
 * @description Content for the MonthlyCalendar window
 *
 * @author Rostyslav Nahornyi, Ihor Dubas, Oleksii Medvediev, Sergii Goncharuk
 * @category Components
 */
const MonthlyCalendarModal: FC = () => {
  const { t, monthlyCalendarModalData, period, removePeriod, selectDate, submitPeriod } = useMonthlyCalendarModalData();

  return (
    <Modal
      modalId={ModalIds.monthlyCalendar}
      title={
        monthlyCalendarModalData?.isAnnual ? t('modals.monthlyCalendar.titleYear') : t('modals.monthlyCalendar.title')
      }
      onClose={monthlyCalendarModalData?.onClose}
      onBackButtonClick={monthlyCalendarModalData?.onBackButtonClick}
    >
      <div className={styles.monthlyCalendar}>
        <div className={classNames(styles.content, monthlyCalendarModalData?.isAnnual ? styles.yearsCalendar : '')}>
          {monthlyCalendarModalData &&
            getMonthsFromTo(monthlyCalendarModalData?.periods.startDate, monthlyCalendarModalData.periods.endDate).map(
              ({ year, monthData }, fragmentIndex) => (
                <React.Fragment key={v4() + fragmentIndex}>
                  {!monthlyCalendarModalData?.isAnnual && (
                    <div className={styles.yearWrapper}>
                      <Typography.Text variant={'h3'}>{year}</Typography.Text>

                      <div className={styles.months}>
                        {monthData.map(({ monthNumber, isMonthInRange: isActive }, index) => (
                          <Tab.DatePicker
                            key={v4().concat(index.toString())}
                            disabled={!isActive}
                            onClick={() => selectDate(year, monthNumber)}
                            isSelected={dateIsBetween(`${year}-${monthNumber + 1}-1`, period.startDate, period.endDate)}
                            label={t(`modals.monthlyCalendar.months.${monthByIndex[monthNumber]}`) as string}
                            className={styles.button}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {monthlyCalendarModalData?.isAnnual && (
                    <div className={styles.year}>
                      <Tab.DatePicker
                        onClick={() => selectDate(year, 0)}
                        isSelected={dateIsBetween(`${year}-${0 + 1}-1`, period.startDate, period.endDate)}
                        label={year + ''}
                        className={styles.button}
                      />
                    </div>
                  )}
                </React.Fragment>
              ),
            )}
        </div>

        <div className={styles.controlButtons}>
          {(period.endDate || period.startDate) && (
            <Button size={'large'} variant={'outline'} type={'button'} className={styles.button} onClick={removePeriod}>
              <Typography.Text variant={'bodyText1'}>
                {t('modals.monthlyCalendar.controlButtons.cancel')}
              </Typography.Text>
            </Button>
          )}

          <Button
            size={'large'}
            variant={'main'}
            type={'button'}
            className={styles.button}
            disabled={!period.startDate}
            onClick={submitPeriod}
          >
            <Typography.Text variant={'bodyText1'}>{t('modals.monthlyCalendar.controlButtons.submit')}</Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { MonthlyCalendarModal };
