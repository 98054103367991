import {
  ManagerP2_3_4_setPayload,
  ManagerSetAccountPortfoliosPayload,
  ManagerSetAccountStrategyPayload,
  setAccountFees,
  setAccountPortfolios,
  setAccountStrategy,
} from '../../../../services/api';
import { NewParticipantSetupFlowProps, NewParticipantSetupStages } from './new-participant-setup-flow.types';
import { useAppContext, useModalsContext } from '../../../../contexts';
import { useCallback, useEffect, useMemo } from 'react';

import { Cache } from 'aws-amplify';
import { CacheQuery } from '../../../../typescript';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { PaymentSettingsValues } from '../payment-settings-flow/components/form/form.types';
import { SummaryFormValues } from '../change-strategy-flow/components/summary/summary.types';
import { UserRole } from '../../../../contexts/app/app.types';
import { useTranslation } from 'react-i18next';

/**
 * useNewParticipantSetupFlowData hook
 * @description The hook which processes ChangeStrategy flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useNewParticipantSetupFlowData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { newParticipantSetup },
    dispatch,
  } = useModalsContext();

  const flowData = useMemo(() => newParticipantSetup, [newParticipantSetup]);
  const newParticipantSetupValues = Cache.getItem(CacheQuery.NEW_PARTICIPANT_SETUP_VALUES);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.newParticipantSetup }),
    [dispatch],
  );

  const submitHandlers = useMemo(
    () => ({
      payment: (values: PaymentSettingsValues, stage: NewParticipantSetupFlowProps['currentStage']) => {
        if (flowData) {
          Cache.setItem(CacheQuery.NEW_PARTICIPANT_SETUP_VALUES, {
            ...(newParticipantSetupValues ?? {}),
            payment: values,
          });
          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              id: ModalIds.newParticipantSetup,
              data: {
                newParticipantSetup: { ...flowData, currentStage: stage },
              },
            },
          });
        }
      },
      portfolios: async (
        values: ReadonlyArray<{
          readonly portfolioMember: string;
          readonly portfolioOwner: string;
          readonly portfolioCurrentCapital: number;
          readonly portfolioCurrentWeight: number;
          readonly portfolioId: string;
        }>,
        stage: NewParticipantSetupFlowProps['currentStage'],
      ) => {
        if (flowData && user) {
          Cache.setItem(CacheQuery.NEW_PARTICIPANT_SETUP_VALUES, {
            ...(newParticipantSetupValues ?? {}),
            portfolios: values,
          });

          appDispatch({ type: 'TOGGLE_IS_LOADING' });

          try {
            const strategyDoc: ManagerSetAccountStrategyPayload['strategyDoc'] = {
              AccountID: flowData.account.AccountID,
              CAGR: newParticipantSetupValues.strategy.expectedReturn,
              MaxDrawDown: newParticipantSetupValues.strategy.maxRisk,
              StrategyName: newParticipantSetupValues.strategy.strategy,
              StrategyID:
                flowData.strategies.find(
                  ({ StrategyName }) => newParticipantSetupValues.strategy.strategy === StrategyName,
                )?.StrategyID ?? '',
              ClientEmailOwner: flowData.accountStrategy.ClientEmailOwner,
            };

            const portfoliosDoc: ManagerSetAccountPortfoliosPayload['portfoliosDoc'] = {
              AccountID: flowData.account.AccountID,
              ClientEmailOwner: flowData.account.ClientEmailOwner,
              ReOpenPortfolios: [],
              OpenPortfolios:
                // (
                //   (newParticipantSetupValues.portfolios ?? []) as ReadonlyArray<{
                //     readonly portfolioMember: string;
                //     readonly portfolioOwner: string;
                //     readonly portfolioCurrentCapital: number;
                //     readonly portfolioCurrentWeight: number;
                //     readonly portfolioId: string;
                //   }>
                // )
                values
                  .filter(
                    ({ portfolioId }) => !flowData.account.Portfolios.some((item) => item.PortfolioID === portfolioId),
                  )
                  .map(({ portfolioMember, portfolioOwner, portfolioCurrentCapital }) => ({
                    PortfolioMember: portfolioMember,
                    PortfolioOwner: portfolioOwner,
                    PortfolioStartCapital: portfolioCurrentCapital,
                  })),
              ClosePortfolios: flowData.account.Portfolios.filter(
                ({ PortfolioID }) =>
                  // !(
                  //   (newParticipantSetupValues.portfolios ?? []) as ReadonlyArray<{
                  //     readonly portfolioMember: string;
                  //     readonly portfolioOwner: string;
                  //     readonly portfolioCurrentCapital: number;
                  //     readonly portfolioCurrentWeight: number;
                  //     readonly portfolioId: string;
                  //   }>
                  // )
                  !values.some(({ portfolioId }) => portfolioId === PortfolioID),
              ).map(({ PortfolioID, PortfolioOwner, PortfolioMember }) => ({
                PortfolioID,
                PortfolioOwner,
                PortfolioMember,
              })),
              ChangePortfolios:
                // (
                //   (newParticipantSetupValues.portfolios ?? []) as ReadonlyArray<{
                //     readonly portfolioMember: string;
                //     readonly portfolioOwner: string;
                //     readonly portfolioCurrentCapital: number;
                //     readonly portfolioCurrentWeight: number;
                //     readonly portfolioId: string;
                //   }>
                // )
                values
                  .filter(({ portfolioId, portfolioOwner, portfolioMember }) =>
                    flowData.account.Portfolios.some(
                      ({ PortfolioID, PortfolioOwner, PortfolioMember }) =>
                        portfolioId === PortfolioID &&
                        (PortfolioOwner !== portfolioOwner || PortfolioMember !== portfolioMember),
                    ),
                  )
                  .map(({ portfolioId, portfolioMember, portfolioOwner }) => ({
                    PortfolioID: portfolioId,
                    PortfolioMember: portfolioMember,
                    PortfolioOwner: portfolioOwner,
                  })),
            };

            const feesDoc: ManagerP2_3_4_setPayload['feesDoc'] = {
              AccountID: flowData.account.AccountID,
              ClientEmailOwner: flowData.accountStrategy.ClientEmailOwner,
              ClientNameOwner: flowData.accountStrategy.ClientNameOwner,
              ManagementFee: +newParticipantSetupValues.payment.ManagementFee,
              ManagementFrequency: newParticipantSetupValues.payment.ManagementFrequency,
              PerformanceFee: +newParticipantSetupValues.payment.PerformanceFee,
              PerformanceFrequency: newParticipantSetupValues.payment.PerformanceFrequency,
              HighWaterMark: newParticipantSetupValues.payment.HighWaterMark,
            };

            const { message: message1 } = await setAccountStrategy({
              role: UserRole.manager,
              roleLoginEmail: user.email,
              strategyDoc,
            });

            const { message: message2 } = await setAccountFees({
              role: UserRole.manager,
              roleLoginEmail: user.email,
              feesDoc,
            });

            const { message: message3 } = await setAccountPortfolios({
              roleLoginEmail: user.email,
              role: user.isLoggedInRole,
              portfoliosDoc,
            });

            if (message1 === 'ok' && message2 === 'ok' && message3 === 'ok') {
              dispatch({
                type: 'SHOW_MODAL',
                payload: {
                  id: ModalIds.newParticipantSetup,
                  data: {
                    newParticipantSetup: { ...flowData, currentStage: stage },
                  },
                },
              });
              flowData.onRefetchParticipants();
              Cache.removeItem(CacheQuery.NEW_PARTICIPANT_SETUP_VALUES);
            }
          } catch (error) {
            console.error(error);
          }

          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      strategy: (values: SummaryFormValues, stage: NewParticipantSetupFlowProps['currentStage']) => {
        if (flowData) {
          Cache.setItem(CacheQuery.NEW_PARTICIPANT_SETUP_VALUES, {
            ...(newParticipantSetupValues ?? {}),
            strategy: values,
          });
          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              id: ModalIds.newParticipantSetup,
              data: {
                newParticipantSetup: { ...flowData, currentStage: stage },
              },
            },
          });
        }
      },
      success: () => {
        handleCloseModal();
      },
    }),
    [appDispatch, dispatch, flowData, handleCloseModal, newParticipantSetupValues, user],
  );

  const setStage = useCallback(
    (stage: NewParticipantSetupFlowProps['currentStage']) => {
      if (flowData) {
        switch (stage) {
          case 'payment': {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.paymentSettingsForm,
                data: {
                  paymentSettingsForm: {
                    accountId: flowData.account.AccountID,
                    clientEmailOwner: flowData.account.ClientEmailOwner,
                    clientNameOwner: flowData.account.ClientNameOwner,
                    shouldNotFetchDefaultValues: true,
                    onRefreshParticipants: flowData.onRefetchParticipants,
                    onSubmitNewParticipantSetupFlowStage: submitHandlers.payment,
                    stepperProps: {
                      currentStep: stage,
                      steps: Object.values(NewParticipantSetupStages)
                        .splice(0, 3)
                        .map((item) => ({
                          id: item,
                          label: t(`modals.newParticipantSetup.stepper.${item}`),
                          onStepChange: () => {
                            setStage(item);
                            dispatch({ type: 'HIDE_MODAL', payload: ModalIds.paymentSettingsForm });
                          },
                        })),
                    },
                  },
                },
              },
            });
            handleCloseModal();
            break;
          }

          case 'portfolios': {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.portfoliosSettingsSummary,
                data: {
                  portfoliosSettingsSummary: {
                    accountId: flowData.account.AccountID,
                    accountUnlocatedCapitalPortfolio: !!flowData.account.Portfolios.length
                      ? flowData.account.AccountUnlocatedCapitalPortfolio
                      : flowData.account.AccountCurrentCapital,
                    clientEmailOwner: flowData.account.ClientEmailOwner,
                    clientNameOwner: flowData.account.ClientNameOwner,
                    accountCurrentCapital: flowData.account.AccountCurrentCapital,
                    onRefetchParticipants: flowData.onRefetchParticipants,
                    portfolios: flowData.account.Portfolios.map(
                      ({
                        PortfolioCurrentCapital,
                        PortfolioCurrentWeight,
                        PortfolioID,
                        PortfolioMember,
                        PortfolioOwner,
                      }) => ({
                        portfolioCurrentCapital: PortfolioCurrentCapital,
                        portfolioCurrentWeight: PortfolioCurrentWeight,
                        portfolioId: PortfolioID,
                        portfolioMember: PortfolioMember,
                        portfolioOwner: PortfolioOwner,
                      }),
                    ),
                    onSubmitNewParticipantSetupFlowStage: submitHandlers.portfolios,
                    stepperProps: {
                      currentStep: stage,
                      steps: Object.values(NewParticipantSetupStages)
                        .splice(0, 3)
                        .map((item) => ({
                          id: item,
                          label: t(`modals.newParticipantSetup.stepper.${item}`),
                          onStepChange: () => {
                            setStage(item);
                            dispatch({ type: 'HIDE_MODAL', payload: ModalIds.portfoliosSettingsSummary });
                          },
                        })),
                    },
                  },
                },
              },
            });
            handleCloseModal();
            break;
          }

          case 'strategy': {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.changeStrategy,
                data: {
                  changeStrategy: {
                    account: flowData.account,
                    accountStrategy: flowData.accountStrategy,
                    currentStage: 'summary',
                    onRefetchParticipants: flowData.onRefetchParticipants,
                    strategies: flowData.strategies,
                    onSubmitNewParticipantSetupFlowStage: submitHandlers.strategy,
                    stepperProps: {
                      currentStep: stage,
                      steps: Object.values(NewParticipantSetupStages)
                        .splice(0, 3)
                        .map((item) => ({
                          id: item,
                          label: t(`modals.newParticipantSetup.stepper.${item}`),
                          onStepChange: () => {
                            setStage(item);
                            dispatch({ type: 'HIDE_MODAL', payload: ModalIds.changeStrategy });
                          },
                        })),
                    },
                  },
                },
              },
            });
            handleCloseModal();
            break;
          }

          case 'success': {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.newParticipantSetup,
                data: {
                  newParticipantSetup: { ...flowData, currentStage: NewParticipantSetupStages.success },
                },
              },
            });
            // handleCloseModal();
            break;
          }

          default:
            break;
        }
      }
    },
    [
      dispatch,
      flowData,
      handleCloseModal,
      submitHandlers.payment,
      submitHandlers.portfolios,
      submitHandlers.strategy,
      t,
    ],
  );

  useEffect(() => {
    flowData && flowData.currentStage !== 'success' && setStage(flowData.currentStage);
  }, [flowData?.currentStage, flowData, setStage]);

  return {
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
    t,
    accountOwner: useMemo(() => flowData?.account.ClientNameOwner, [flowData?.account.ClientNameOwner]),
    handleCloseModal,
  };
};

export { useNewParticipantSetupFlowData };
