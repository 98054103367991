import { AvailableLocales } from '../../../../localization';
import { Dispatch } from 'react';
import { MoUser } from '../../../../auth/app/services/api/management-office';
import { PaymentFormat } from '../../services/api';

/**
 * AppContextProps
 * @description Props for the AppContext
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 */
interface AppContextProps extends AppState {
  readonly dispatch: Dispatch<Action>;
}

type AppState = {
  readonly user?: MoUser;
  readonly rootUserPreRegisterToken?: string;
  readonly errorMessage?: string;
  readonly isRootUserRegistered?: boolean;
  readonly currentRole?: UserRole;
  readonly establishedConnections?: {
    readonly ibGateway?: boolean;
    readonly tradingModule?: boolean;
    readonly tradeVision?: boolean;
    readonly emailTransporterStatus?: boolean;
  };
  readonly ibGateway?: {
    readonly name?: string;
    readonly ipAddress?: string;
  };
  readonly tradingModule?: {
    readonly name?: string;
    readonly ipAddress?: string;
  };
  readonly roleMigrations?: {
    readonly loginCandidate?: LoginCandidate;
    readonly reservedRoles?: ReadonlyArray<UserRole>;
  };
  readonly logo?: string;
  readonly companyName?: string;
  readonly isLoading: boolean;
  readonly unreadMessagesCount?: Partial<Record<UserRole, number>>;
  readonly paymentFormat?: PaymentFormat;
};

type LoginCandidate = {
  readonly name: string;
  readonly role: UserRole;
  readonly email: string;
};

type Action =
  | {
      readonly type: 'SET_USER';
      readonly payload: MoUser;
    }
  | {
      readonly type: 'SET_ROOT_USER_PRE_REGISTER_TOKEN';
      readonly payload: {
        readonly apiKey: string;
        readonly companyName?: string;
      };
    }
  | {
      readonly type: 'SET_ERROR_MESSAGE';
      readonly payload?: string;
    }
  | {
      readonly type: 'SET_IS_ROOT_USER_REGISTERED';
      readonly payload: boolean;
    }
  | {
      readonly type: 'SET_CURRENT_ROLE';
      readonly payload: UserRole;
    }
  | {
      readonly type: 'SET_IBGATEWAY_CONNECTION_STATUS';
      readonly payload: boolean;
    }
  | {
      readonly type: 'SET_TRADING_MODULE_CONNECTION_STATUS';
      readonly payload: boolean;
    }
  | {
      readonly type: 'SET_TRADEVISION_CONNECTION_STATUS';
      readonly payload: boolean;
    }
  | {
      readonly type: 'SET_IBGATEWAY_NAME';
      readonly payload: string;
    }
  | {
      readonly type: 'SET_IBGATEWAY_IP_ADDRESS';
      readonly payload: string;
    }
  | {
      readonly type: 'SET_TRADING_MODULE_NAME';
      readonly payload: string;
    }
  | {
      readonly type: 'SET_TRADING_MODULE_IP_ADDRESS';
      readonly payload: string;
    }
  | {
      readonly type: 'SET_EMAIL_TRANSPORTER_STATUS';
      readonly payload: boolean;
    }
  | {
      readonly type: 'SET_LOGIN_CANDIDATE';
      readonly payload?: LoginCandidate;
    }
  | {
      readonly type: 'ADD_RESERVED_ROLE';
      readonly payload: UserRole;
    }
  | {
      readonly type: 'REMOVE_RESERVED_ROLE';
      readonly payload: UserRole;
    }
  | {
      readonly type: 'SET_RESERVED_ROLES';
      readonly payload?: ReadonlyArray<UserRole>;
    }
  | {
      readonly type: 'SET_LOGO';
      readonly payload: string;
    }
  | {
      readonly type: 'LOGOUT';
    }
  | {
      readonly type: 'SET_COMPANY_NAME';
      readonly payload: string;
    }
  | {
      readonly type: 'TOGGLE_IS_LOADING';
    }
  | {
      readonly type: 'SET_UNREAD_MESSAGES_COUNT';
      readonly payload: Partial<AppState['unreadMessagesCount']>;
    }
  | {
      readonly type: 'SET_PAYMENT_FORMAT';
      readonly payload: PaymentFormat;
    }
  | {
      readonly type: 'SET_THEME';
      readonly payload: 'light' | 'dark';
    }
  | {
      readonly type: 'SET_LANGUAGE';
      // readonly payload: 'ua' | 'uk';
      readonly payload: AvailableLocales;
    };

enum UserRole {
  admin = 'admin',
  manager = 'manager',
  trader = 'trader',
}

enum UserStatus {
  active = 'active',
  inviteSent = 'inviteSent',
  inviteAccepted = 'inviteAccepted',
  deleted = 'deleted',
}

export type { AppContextProps, AppState, Action, LoginCandidate };

export { UserRole, UserStatus };
