import { useCallback, useEffect, useMemo, useState } from 'react';

import { Filter } from '../../pages/dashboard/components/dashboard/components/filter';
import { FormFilterValues } from '../../pages/dashboard/components/dashboard/components/filter/components/form-filter/form-filter.types';
import { Notification } from '../../services/api/client-office';
import { NotificationsDrawerProps } from './notifications-drawer.types';
import { Priority } from '../indicator/components/status-indicator/status-indicator.types';
import dayjs from 'dayjs';
import { useAppContext } from '../../context';
import { useTranslation } from 'react-i18next';

/**
 * 1 month.
 * By documentation the default start date of notifications.
 */
const DEFAULT_START_DATE = dayjs().subtract(1, 'month');

const indicatorColorToType = (color: string): Priority => {
  switch (color) {
    case 'RGB(202, 23, 10)':
      return Priority.alarm;

    case 'RGB(241,244,94)':
      return Priority.info;

    case 'RGB(44,188,113)':
      return Priority.success;

    default:
      return Priority.alarm;
  }
};

/**
 * useNotificationsDrawerData hook
 * @description The hook which processes NotificationsDrawer page data
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev, Sergii Goncharuk
 * @category Hooks
 */
const useNotificationsDrawerData = ({ notifications }: NotificationsDrawerProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'client.pages.dashboard.notifications-drawer' });
  const { dispatch: appDispatch, user } = useAppContext();

  const [filters, setFilters] = useState<FormFilterValues>({});
  const [filteredNotifications, setFilteredNotifications] = useState<Array<Notification>>();
  const [filteredStartDate, setFilteredStartDate] = useState<string>(dayjs().subtract(1, 'month').toString());

  const fullSortedNotifications = [...notifications].sort((a, b) => {
    const aDate = new Date(a.Date);
    const bDate = new Date(b.Date);

    return aDate.getTime() - bDate.getTime();
  });

  const startDate = useMemo(
    () => (!!fullSortedNotifications?.length ? new Date(fullSortedNotifications[0]?.Date) : undefined),
    [fullSortedNotifications],
  );
  const endDate = useMemo(
    () =>
      !!fullSortedNotifications?.length
        ? new Date(fullSortedNotifications[fullSortedNotifications.length - 1].Date)
        : undefined,
    [fullSortedNotifications],
  );

  const filterNotifications = useCallback(() => {
    const fullSortedNotifications = [...notifications].sort((a, b) => {
      const aDate = new Date(a.Date);
      const bDate = new Date(b.Date);

      return aDate.getTime() - bDate.getTime();
    });

    const fullFilteredNotifications = fullSortedNotifications.filter(({ Date: date, ReadMark, Color }) => {
      const { period, isRead: isReadFilter, statuses } = filters;

      const customPeriod = period
        ? {
            a: dayjs(period?.a).startOf('month').toDate(),
            b: dayjs(period?.b ? period.b : period?.a)
              .endOf('month')
              .toDate(),
          }
        : {
            a: dayjs(filteredStartDate).toDate(),
            b: new Date(),
          };

      const isBetween = customPeriod
        ? dayjs(date).isBetween(
            customPeriod?.a ?? DEFAULT_START_DATE,
            customPeriod?.b ?? new Date(),
            'millisecond',
            '[]',
          )
        : true;

      const readStatus = typeof isReadFilter !== 'undefined' ? !!ReadMark === isReadFilter : true;
      const hasStatuses = typeof statuses !== 'undefined' ? statuses.includes(indicatorColorToType(Color)) : true;
      const tooOld = new Date(date) < new Date(user?.createdAt ?? '');

      return isBetween && readStatus && hasStatuses && !tooOld;
    });

    setFilteredNotifications(fullFilteredNotifications);
  }, [filters, notifications, filteredStartDate, user]);

  useEffect(() => {
    notifications && filterNotifications();
  }, [filterNotifications, notifications]);

  const openFiltersDrawer = useCallback(
    () =>
      appDispatch({
        type: 'OPEN_DRAWER',
        payload: {
          data: {
            children: (
              <Filter
                selectedFilters={filters}
                onSubmit={(filter) => {
                  if (!filter.statuses?.length || typeof filter.isRead !== 'undefined') return setFilters(filter);

                  setFilters({ ...filter, isRead: false });
                }}
                period={{ startDate: startDate ?? new Date(), endDate }}
                onChange={() => setFilteredStartDate(fullSortedNotifications && fullSortedNotifications[0]?.Date)}
              />
            ),
            maxHeight: true,
          },
        },
      }),
    [appDispatch, endDate, filters, startDate, fullSortedNotifications],
  );

  return {
    t,
    unreadNotificationsCount: useMemo(
      () => filteredNotifications?.filter(({ ReadMark }) => !ReadMark)?.length,
      [filteredNotifications],
    ),
    notifications: filteredNotifications,
    openFiltersDrawer,
  };
};

export { useNotificationsDrawerData };
