import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportType, ReportsPeriod } from '../../reports-flow.types';
import { Success, Summary } from './components';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { CollectiveAccountsPortfoliosProps } from './collective-accounts-portfolios.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { SummaryProps } from './components/summary/summary.types';
import dayjs from 'dayjs';
import { getAccountsByEmail } from '../../../../../../services/api/manager';
import { sendBulkReport } from '../../../../../../services/api';
import { useTranslation } from 'react-i18next';

const formatPeriodLabel = (startDate: Date, reportType: ReportType, t: (value: string) => string): string => {
  const dividedStartDate = dayjs(startDate).format('MMMM YYYY').split(' ');
  if (reportType === ReportType.monthly) {
    return `${t(dividedStartDate[0])} ${dividedStartDate[1]}`;
  } else {
    return `${dividedStartDate[1]} ${t('modals.reports.summary.year')}`;
  }
};

/**
 * useCollectiveAccountsPortfoliosData hook
 * @description The hook which processes Reports CollectiveAccountsPortfolios flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useCollectiveAccountsPortfoliosData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { reportsCollectiveAccountsPortfolios },
    dispatch,
  } = useModalsContext();

  const [minDate, setMinDate] = useState<string>();
  const [period, setPeriod] = useState<ReportsPeriod>();
  const [reportType, setReportType] = useState<ReportType>(ReportType.monthly);

  const flowData: CollectiveAccountsPortfoliosProps | undefined = useMemo(
    () => reportsCollectiveAccountsPortfolios,
    [reportsCollectiveAccountsPortfolios],
  );

  const fetchAccountsByEmail = useCallback(async () => {
    if (flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getAccountsByEmail({
          emails: [
            ...flowData.accounts.map(({ clientEmailOwner }) => clientEmailOwner),
            ...flowData.portfolios.map(({ portfolioOwner }) => portfolioOwner),
          ],
        });
        const dates = [...data]
          .filter(
            ({ accountId, portfolios }) =>
              flowData.accounts.some((account) => account.accountId === accountId) ||
              flowData.portfolios.some(({ portfolioId }) => portfolios.some(({ id }) => id === portfolioId)),
          )
          .flatMap(({ dateAccountFunded, portfolios }) => [
            dateAccountFunded,
            ...portfolios.map(({ opened }) => opened),
          ]);
        setMinDate([...dates].sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]);
      } catch (error) {
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, flowData]);

  useEffect(() => {
    if (!minDate) {
      fetchAccountsByEmail();
    }
  }, [fetchAccountsByEmail, minDate]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.reportsCollectiveAccountsPortfolios });
  }, [dispatch]);

  const setStage = useCallback(
    (stage: CollectiveAccountsPortfoliosProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.reportsCollectiveAccountsPortfolios,
          data: {
            [ModalIds.reportsCollectiveAccountsPortfolios]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    CollectiveAccountsPortfoliosProps['currentStage'],
    SummaryProps['onSubmitStage'] | SuccessProps['onSubmitStage']
  > = useMemo(
    () => ({
      summary: async () => {
        if (user && flowData && period) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { message } = await sendBulkReport({
              clients: flowData.accounts.map(({ accountId, clientEmailOwner, clientNameOwner }) => ({
                accountId,
                email: clientEmailOwner,
                name: clientNameOwner,
              })),
              start: dayjs(period.startDate).format('YYYY-MM-DD'),
              end: dayjs(period.endDate).format('YYYY-MM-DD'),
              reportType: reportType,
            });
            if (message === 'ok' || message.includes('Duplicate entry')) {
              setStage('success');
            }
          } catch (error) {
            console.error(error);
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: handleCloseModal,
    }),
    [appDispatch, flowData, handleCloseModal, period, reportType, setStage, user],
  );

  const stages: Record<CollectiveAccountsPortfoliosProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData && minDate
        ? {
            summary: (
              <Summary
                accounts={flowData.accounts}
                portfolios={flowData.portfolios}
                minDate={minDate}
                period={period}
                selectedReportType={reportType}
                onReportTypeSelect={(value) => setReportType(value)}
                onClose={handleCloseModal}
                onSetPeriod={(value) => setPeriod(value)}
                onSubmitStage={submitHandlers.summary}
              />
            ),
            success: (
              <Success
                accounts={flowData.accounts}
                portfolios={flowData.portfolios}
                t={t}
                onSubmitStage={submitHandlers.success}
              />
            ),
          }
        : undefined,
    [flowData, handleCloseModal, minDate, period, reportType, submitHandlers.success, submitHandlers.summary, t],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { formatPeriodLabel, useCollectiveAccountsPortfoliosData };
