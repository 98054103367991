import { EditUserValues, RequestProps } from './components/request/request.types';
import { Error, Request, Success } from './components';
import React, { useCallback, useMemo } from 'react';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { EditProps } from './edit.types';
import { ErrorProps } from './components/error/error.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { editUser } from '../../../../../../services/api';

/**
 * useEditData hook
 * @description The hook which processes UsersManagement Edit flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useEditData = () => {
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { usersManagementEdit },
    dispatch,
  } = useModalsContext();

  const flowData: EditProps | undefined = useMemo(() => usersManagementEdit, [usersManagementEdit]);

  const setStage = useCallback(
    (stage: EditProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.usersManagementEdit,
          data: {
            [ModalIds.usersManagementEdit]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    EditProps['currentStage'],
    RequestProps['onSubmit'] | SuccessProps['onClose'] | ErrorProps['onClose']
  > = useMemo(
    () => ({
      request: async (values: EditUserValues) => {
        if (user && flowData) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { data } = await editUser({
              id: flowData.targetUser.id,
              email: values.email,
              name: values.name,
              role: values.role,
            });

            if (data) {
              await flowData.onRefetchUsers();
              setStage('success');
            } else {
              setStage('error');
            }
          } catch (error) {
            console.error(error);
            setStage('error');
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.usersManagementEdit });
      },
      error: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.usersManagementEdit });
      },
    }),
    [user, flowData, appDispatch, setStage, dispatch],
  );

  const stages: Record<EditProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            request: <Request initialValues={flowData.targetUser} onSubmit={submitHandlers.request} />,
            success: <Success onClose={submitHandlers.success as SuccessProps['onClose']} />,
            error: <Error onClose={submitHandlers.error as ErrorProps['onClose']} />,
          }
        : undefined,
    [flowData, submitHandlers.error, submitHandlers.request, submitHandlers.success],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useEditData };
