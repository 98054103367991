import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './invoice-downloaded-modal.module.scss';
import { useInvoiceDownloadedModal } from './invoice-downloaded-modal.hook';

/**
 * InvoiceDownloadedModal component
 * @description Content for the AccountDownloaded window
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const InvoiceDownloadedModal: FC = () => {
  const { t, isInvoiceDownloaded, handleContinueClick } = useInvoiceDownloadedModal();

  return isInvoiceDownloaded ? (
    <Modal modalId={ModalIds.invoiceDownloaded} title={t('modals.accountDownloaded.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>{t('modals.accountDownloaded.text')}</Typography.Text>
        <Button className={styles.button} variant={'main'} size={'large'} onClick={handleContinueClick}>
          <Typography.Text variant={'bodyText1'}>{t('modals.accountDownloaded.button')}</Typography.Text>
        </Button>
      </div>
    </Modal>
  ) : null;
};

export { InvoiceDownloadedModal };
