import { AccountApiModel } from '../models';
import { request } from '../../../../services';

const getAccounts = (json?: { email: string }) =>
  request<{ readonly data: AccountApiModel['getAccounts'] }>({
    url: 'account',
    method: 'get',
    ...(json?.email && { params: { email: json.email } }),
  });

export { getAccounts };
