import { ReactComponent as ChatIcon } from '../../../../../assets/icons/chat.svg';
import { FC } from 'react';
import { HeaderSmallProps } from './investor-header-small.types';
import { IconedButton } from '../../../iconed-button';
import { ReactComponent as NotificationIcon } from '../../../../../assets/icons/bell-2.svg';
import { ReactComponent as SettingsIcon } from '../../../../../assets/icons/settings-2.svg';
import classNames from 'classnames';
import styles from './investor-header-small.module.scss';

/**
 * HeaderSmall component
 * @description Content for the App Header
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @param {HeaderSmallProps} props - HeaderSmallProps defined in the './investor-header-small.types.ts';
 * @category Components
 */
const HeaderSmall: FC<HeaderSmallProps> = ({
  className,
  leftControl,
  rightControl: { settingsClickHandler, notificationClickHandler, chatClickHandler },
}) => (
  <div className={classNames(styles.wrapper, className)}>
    {leftControl && (
      <div
        role={'button'}
        tabIndex={0}
        onKeyDown={() => leftControl.onClick && leftControl.onClick()}
        onClick={() => leftControl.onClick && leftControl.onClick()}
        className={styles.leftControl}
      >
        {leftControl.label && leftControl.label}
        {leftControl.trailingIcon && leftControl.trailingIcon}
      </div>
    )}

    <div className={styles.rightControlWrapper}>
      {settingsClickHandler && (
        <IconedButton
          icon={<SettingsIcon className={styles.icon} />}
          size={'small'}
          variant={'borderless'}
          onClick={settingsClickHandler}
        />
      )}
      {notificationClickHandler && (
        <IconedButton
          size={'small'}
          variant={'borderless'}
          icon={<NotificationIcon className={styles.icon} />}
          onClick={notificationClickHandler}
        />
      )}
      {chatClickHandler && (
        <IconedButton
          icon={<ChatIcon className={styles.icon} />}
          size={'small'}
          variant={'borderless'}
          onClick={chatClickHandler}
        />
      )}
    </div>
  </div>
);
export { HeaderSmall };
