/**
 * environment variable.
 * @description Global app environment variables stored in the object.
 */
const environment = {
  api: {
    base: process.env['base-api'],
  },
  port: process.env.port,
  mode: process.env.REACT_APP_ENV,
  config: process.env.config,
  imgPath: process.env['img-path'],
  portals: {
    client: process.env['client-base'],
    management: process.env['management-base'],
    authFrontend: process.env['auth-base'],
  },
};

export { environment };
