import { Action, AppState } from './app.types';

import { Cache } from 'aws-amplify';
import { CacheQuery } from '../../typescript';
// import { changeLanguage } from 'i18next';
import { logout } from '../../services/api';

/**
 * @description InitialState for the AppContext
 *
 * @author Oleksii Medvediev
 */
const initialState: AppState = {
  user:
    Cache.getItem(CacheQuery.USER) && Cache.getItem(CacheQuery.USER).isLoggedInRole
      ? Cache.getItem(CacheQuery.USER)
      : undefined,
  currentRole: Cache.getItem(CacheQuery.CURRENT_ROLE) ?? Cache.getItem(CacheQuery.USER)?.role[0] ?? undefined,
  isLoading: false,
};

/**
 * @description Reducer function for the useReducer hook in the AppContextProvider component.
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 * @param {AppState} state - State defined in the './app.types.ts'
 * @param {Action} action - Action defined in the './app.types.ts'
 * @returns updated AppContextProvider state.
 */
const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'SET_USER':
      Cache.setItem(CacheQuery.USER, action.payload);
      // changeLanguage(action.payload.preferences.language !== 'ua' ? AvailableLocales.EN : AvailableLocales.UK);
      // changeLanguage(action.payload.preferences.language);
      return {
        ...state,
        user: action.payload,
      };

    case 'SET_CURRENT_ROLE':
      Cache.setItem(CacheQuery.CURRENT_ROLE, action.payload);

      return {
        ...state,
        currentRole: action.payload,
      };

    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.payload,
      };

    case 'SET_IS_ROOT_USER_REGISTERED':
      return {
        ...state,
        isRootUserRegistered: action.payload,
      };

    case 'SET_ROOT_USER_PRE_REGISTER_TOKEN':
      Cache.setItem(CacheQuery.COMPANY_NAME, action.payload.companyName);

      return {
        ...state,
        rootUserPreRegisterToken: action.payload.apiKey,
        companyName: action.payload.companyName,
      };

    case 'SET_IBGATEWAY_CONNECTION_STATUS':
      return {
        ...state,
        establishedConnections: {
          ...(state.establishedConnections ?? {}),
          ibGateway: action.payload,
        },
      };

    case 'SET_IBGATEWAY_NAME':
      return {
        ...state,
        ibGateway: {
          ...(state.ibGateway ?? {}),
          name: action.payload,
        },
      };

    case 'SET_IBGATEWAY_IP_ADDRESS':
      return {
        ...state,
        ibGateway: {
          ...(state.ibGateway ?? {}),
          ipAddress: action.payload,
        },
      };

    case 'SET_TRADING_MODULE_NAME':
      return {
        ...state,
        tradingModule: {
          ...(state.tradingModule ?? {}),
          name: action.payload,
        },
      };

    case 'SET_TRADING_MODULE_IP_ADDRESS':
      return {
        ...state,
        tradingModule: {
          ...(state.tradingModule ?? {}),
          ipAddress: action.payload,
        },
      };

    case 'SET_TRADING_MODULE_CONNECTION_STATUS':
      return {
        ...state,
        establishedConnections: {
          ...(state.establishedConnections ?? {}),
          tradingModule: action.payload,
        },
      };

    case 'SET_TRADEVISION_CONNECTION_STATUS':
      return {
        ...state,
        establishedConnections: {
          ...(state.establishedConnections ?? {}),
          tradeVision: action.payload,
        },
      };

    case 'SET_EMAIL_TRANSPORTER_STATUS': {
      return {
        ...state,
        establishedConnections: {
          ...(state.establishedConnections ?? {}),
          emailTransporterStatus: action.payload,
        },
      };
    }

    case 'SET_LOGIN_CANDIDATE':
      return {
        ...state,
        roleMigrations: {
          ...(state.roleMigrations ?? {}),
          loginCandidate: action.payload,
        },
      };

    case 'ADD_RESERVED_ROLE':
      return {
        ...state,
        roleMigrations: {
          ...(state.roleMigrations ?? {}),
          reservedRoles: [...(state.roleMigrations?.reservedRoles ?? []), action.payload],
        },
      };

    case 'REMOVE_RESERVED_ROLE':
      return {
        ...state,
        roleMigrations: {
          ...(state.roleMigrations ?? {}),
          reservedRoles: (state.roleMigrations?.reservedRoles ?? []).filter((role) => role !== action.payload),
        },
      };

    case 'SET_RESERVED_ROLES':
      return {
        ...state,
        roleMigrations: {
          ...(state.roleMigrations ?? {}),
          reservedRoles: action.payload,
        },
      };

    case 'SET_LOGO':
      Cache.setItem(CacheQuery.LOGO, action.payload);
      return {
        ...state,
        logo: action.payload,
      };

    case 'LOGOUT':
      state.user && logout({ email: state.user.email });
      Cache.removeItem(CacheQuery.USER);
      Cache.removeItem(CacheQuery.CURRENT_ROLE);
      localStorage.removeItem('local-auth-co');
      localStorage.removeItem('local-auth-mo');
      return {
        ...state,
        user: undefined,
        currentRole: undefined,
      };

    case 'SET_COMPANY_NAME':
      Cache.setItem(CacheQuery.COMPANY_NAME, action.payload);
      return {
        ...state,
        companyName: action.payload,
      };

    case 'TOGGLE_IS_LOADING':
      return {
        ...state,
        isLoading: !state.isLoading,
      };

    case 'SET_UNREAD_MESSAGES_COUNT': {
      return {
        ...state,
        unreadMessagesCount: {
          ...(state.unreadMessagesCount ?? {}),
          ...action.payload,
        },
      };
    }

    case 'SET_PAYMENT_FORMAT': {
      return {
        ...state,
        paymentFormat: action.payload,
      };
    }

    case 'SET_THEME': {
      return state.user
        ? {
            ...state,
            user: {
              ...state.user,
              preferences: {
                ...state.user.preferences,
                darkTheme: action.payload === 'dark' ? true : false,
              },
            },
          }
        : state;
    }

    case 'SET_LANGUAGE': {
      state.user &&
        Cache.setItem(CacheQuery.USER, {
          ...state.user,
          preferences: {
            ...state.user.preferences,
            language: action.payload,
          },
        });
      // changeLanguage(action.payload !== 'ua' ? AvailableLocales.EN : AvailableLocales.UK);
      // changeLanguage(action.payload);

      return state.user
        ? {
            ...state,
            user: {
              ...state.user,
              preferences: {
                ...state.user.preferences,
                language: action.payload,
              },
            },
          }
        : state;
    }

    default:
      return state;
  }
};

export { initialState, appReducer };
