import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { ConfirmationProps } from './confirmation.types';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import styles from './confirmation.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * ConfirmationComponent
 * @description Content for the Confirmation stage of the Order Modify flow
 *
 * @author Oleksii Medvediev
 * @param { ConfirmationProps } props - ConfirmationProps defined in the './confirmation.types.ts'
 * @category Components
 */
const Confirmation: FC<ConfirmationProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  return (
    <Modal modalId={ModalIds.orderModify} title={t('modals.order.modify.confirmation.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>{t('modals.order.modify.confirmation.text')}</Typography.Text>
        <div className={styles.controls}>
          <Button type={'button'} variant={'outline'} size={'large'} onClick={onClose}>
            {t('modals.order.modify.confirmation.cancel')}
          </Button>
          <Button type={'button'} variant={'main'} size={'large'} onClick={onSubmit}>
            {t('modals.order.modify.confirmation.send')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { Confirmation };
