import * as yup from 'yup';

import { Form, Formik } from 'formik';
import { InviteNewUserValues, RequestProps } from './request.types';
import React, { FC, useMemo, useState } from 'react';

import { Button } from '../../../../../../../button';
import { ReactComponent as ChevronDown } from '../../../../../../../../../assets/icons/chevron-down.svg';
import { Dropdown } from '../../../../../../../dropdown';
import { Input } from '../../../../../../../input';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import { UserRole } from '../../../../../../../../contexts/app/app.types';
import styles from './request.module.scss';
import { useAppContext } from '../../../../../../../../contexts';
import { useTranslation } from 'react-i18next';

const initialValues: InviteNewUserValues = {
  email: '',
  name: '',
  role: [],
};

/**
 * Request component
 * @description Content for the UsersManagement Invite flow Request stage
 *
 * @author Oleksii Medvediev
 * @param { RequestProps } props - RequestProps defined in the './request.types.ts'
 * @category Components
 */
const Request: FC<RequestProps> = ({ onSubmit, errorMessage }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const [currentRole, setCurrentRole] = useState<string>();
  const { dispatch } = useAppContext();

  const roleOptions: ReadonlyArray<{ id: UserRole; value: string }> = useMemo(
    () => [
      {
        id: UserRole.manager,
        value: t('roles.manager'),
      },
      {
        id: UserRole.admin,
        value: t('roles.admin'),
      },
      { id: UserRole.trader, value: t('roles.trader') },
    ],
    [t],
  );

  const validationSchema: yup.Schema<InviteNewUserValues> = yup.object({
    name: yup
      .string()
      .min(3, t('modals.usersManagement.invite.request.validation.nameMin'))
      .required(t('modals.usersManagement.invite.request.validation.nameRequired')),
    email: yup
      .string()
      .email(t('modals.usersManagement.invite.request.validation.emailNotValid'))
      .required(t('modals.usersManagement.invite.request.validation.emailRequired')),
    role: yup
      .array()
      .of(yup.string().required().oneOf(Object.values(UserRole)))
      .min(1, t('modals.usersManagement.invite.request.validation.roleRequired'))
      .required(t('modals.usersManagement.invite.request.validation.roleRequired')),
  });

  return (
    <Modal modalId={ModalIds.usersManagementInvite} title={t('modals.usersManagement.invite.request.title')}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur>
        {({ setFieldValue, errors, touched, setFieldTouched, isValid, values }) => (
          <Form className={styles.wrapper}>
            <Input
              id={'name'}
              label={t('modals.usersManagement.invite.request.nameInput')}
              type={'text'}
              onChange={(name) => {
                setFieldValue('name', name);
                dispatch({ type: 'SET_ERROR_MESSAGE' });
              }}
              onBlur={() => setFieldTouched('name', true)}
              error={
                (errors.name || errorMessage === 'User with this name is already exists') && touched.name
                  ? errors.name
                    ? errors.name
                    : t('modals.usersManagement.invite.request.nameExists')
                  : undefined
              }
            />
            <Input
              id={'role'}
              label={t('modals.usersManagement.invite.request.roleInput')}
              type={'text'}
              onChange={() => null}
              value={currentRole}
              readOnly
              tailingIcon={
                <Dropdown
                  button={<ChevronDown />}
                  options={roleOptions}
                  multiple
                  onChange={(value) => {
                    setFieldValue('role', value);
                    setCurrentRole(
                      Array.isArray(value) ? value.map((item) => t(`roles.${item}`)).join(', ') : t(`roles.${value}`),
                    );
                  }}
                />
              }
              onBlur={() => setTimeout(() => setFieldTouched('role', true))}
              error={errors?.role && touched.role ? errors.role.toString() : undefined}
            />
            <Input
              id={'email'}
              label={t('modals.usersManagement.invite.request.emailInput')}
              type={'email'}
              onChange={(email) => {
                setFieldValue('email', email, true);
                dispatch({ type: 'SET_ERROR_MESSAGE' });
              }}
              onBlur={() => setFieldTouched('email', true)}
              error={
                (errors.email || errorMessage === 'User with this email is already exists') && touched.email
                  ? errors.email
                    ? errors.email
                    : t('modals.usersManagement.invite.request.emailExists')
                  : undefined
              }
            />
            <Button
              variant={'main'}
              size={'large'}
              type={'submit'}
              disabled={!isValid || (!values.name && !values.role && !values.email)}
            >
              <Typography.Text variant={'bodyText1'}>
                {t('modals.usersManagement.invite.request.submit')}
              </Typography.Text>
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export { Request };
