import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { ReactComponent as CalendarIcon } from '../../../../../../../assets/icons/calendar-16.svg';
import { Input } from '../../../../../input';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import styles from './form.module.scss';
import { useFormData } from './form.hook';

const formatPeriodLabel = (date: Date, t: (value: string) => string): string => {
  const dividedDate = dayjs(date).format('D MMMM YYYY').split(' ');
  return dividedDate.map((item, index) => (index === 1 ? t(`casedMonths.${item}`) : item)).join(' ');
};

/**
 * Form component
 * @description Content for the WithdrawAccount flow Form stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-160536&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Form: FC = () => {
  const {
    t,
    flowData,
    date,
    currentStage,
    errors,
    touched,
    withdrawAmount,
    isValid,
    handleOpenCalendar,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = useFormData();

  return currentStage === 'default' ? (
    <Modal modalId={ModalIds.withdrawAccountForm} title={t('modals.withdrawAccount.form.title')}>
      {flowData && (
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <Typography.Text variant={'label2'} className={styles.label}>
              {t('modals.withdrawAccount.form.capitalLabel')}
            </Typography.Text>
            <Typography.Number variant={'h3'} currency value={Math.round(flowData.accountCurrentCapital)} />
          </div>
          <form className={styles.formWrapper} autoComplete={'off'} onSubmit={handleSubmit}>
            <div className={styles.fields}>
              <Input
                id={'withdrawAmount'}
                label={t('modals.withdrawAccount.form.withdrawAmount')}
                type={'text'}
                isCurrency={true}
                value={withdrawAmount}
                onChange={(value) => {
                  setFieldValue('withdrawAmount', !!+value.toString().replaceAll(' ', '') ? value : null);
                }}
                onBlur={() => setFieldTouched('withdrawAmount', true)}
                error={
                  withdrawAmount &&
                  parseFloat(withdrawAmount.replace(/[^0-9.-]+/g, '')) > flowData.accountCurrentCapital
                    ? t('modals.withdrawAccount.form.insufficient')
                    : errors.withdrawAmount && touched.withdrawAmount
                    ? errors.withdrawAmount
                    : undefined
                }
              />
              <Button
                type={'button'}
                variant={'main'}
                size={'small'}
                leadingIcon={<CalendarIcon />}
                onClick={handleOpenCalendar}
              >
                <Typography.Text variant={'bodyText2'}>
                  {date ? formatPeriodLabel(date, t) : t('modals.withdrawAccount.form.withdrawDate')}
                </Typography.Text>
              </Button>
            </div>

            <Button
              size={'large'}
              variant={'main'}
              type={'submit'}
              disabled={
                !flowData ||
                !isValid ||
                !withdrawAmount ||
                parseFloat(withdrawAmount.replace(/[^0-9.-]+/g, '')) > flowData.accountCurrentCapital
              }
              className={styles.saveButton}
            >
              <Typography.Text variant={'bodyText1'}>
                {capitalize(t('modals.withdrawAccount.form.continue'))}
              </Typography.Text>
            </Button>
          </form>
        </div>
      )}
    </Modal>
  ) : null;
};

export { Form };
