import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { CSVLink } from 'react-csv';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { RequestProps } from './request.types';
import { Typography } from '../../../../../typography';
import styles from './request.module.scss';

/**
 * Request component
 * @description Content for the ChangeStrategy flow Request stage
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-154585&mode=dev
 *
 * @author Oleksii Medvediev
 * @param { RequestProps } props - RequestProps defined in the './request.types.ts'
 * @category Components
 */
const Request: FC<RequestProps> = ({ onSubmitStage, accountId, snapshotData, t }) => (
  <Modal modalId={ModalIds.changeStrategy} title={t('modals.changeStrategy.request.title')}>
    <div className={styles.wrapper}>
      <Typography.Text variant={'bodyText3'}>
        {t('modals.changeStrategy.request.text1')}
        <br />
        <br />
        {t('modals.changeStrategy.request.text2')}
        <br />
        <br />
        {t('modals.changeStrategy.request.text3')}
      </Typography.Text>
      <div className={styles.controlButtons}>
        {snapshotData?.data && snapshotData?.headers && (
          <CSVLink headers={snapshotData.headers} data={snapshotData.data} filename={`${accountId}.csv`}>
            <Button variant={'outline'} size={'large'}>
              <Typography.Text variant={'bodyText1'}>{t('modals.changeStrategy.request.report')}</Typography.Text>
            </Button>
          </CSVLink>
        )}

        <Button variant={'main'} size={'large'} className={styles.btn} onClick={onSubmitStage}>
          <Typography.Text variant={'bodyText1'}>{t('modals.changeStrategy.request.confirm')}</Typography.Text>
        </Button>
      </div>
    </div>
  </Modal>
);

export { Request };
