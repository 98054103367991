import { Footer, Header } from './layout';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React, { FC, Suspense, lazy, useCallback, useEffect, useState } from 'react';

import { BrandingType } from '../../services/api/admin/admin.model';
import { Cache } from 'aws-amplify';
import { CacheQuery } from '../../../../auth/app/shared';
import { Contexts } from './contexts';
import { Preloader } from '../../components';
import { UserRole } from '../../contexts/app/app.types';
import { getBranding } from '../../../../auth/app/services/api/management-office';
import { useAppContext } from '../../contexts';

const PortfolioReportPage = lazy(() => import('./portfolio-report-page'));
const AccountReportPage = lazy(() => import('./account-report-page'));

// ATTENTION: THE PAGES BELOW ARE COPIED FROM THE PREVIOUS PROJECT!!!
// They should be totally rewrote because of lack of typification and global syntax issues!!!
// In the current version they are just cleaned from critical runtime errors, but stay inappropriate to the project!!!

const ReportsRouter: FC = () => {
  const [branding, setBranding] = useState<BrandingType>();
  const { user, currentRole } = useAppContext();
  const { pathname } = useLocation();

  const redirectUrl = `/auth/login?url=${
    '/' +
    pathname
      .split('/')
      .splice(pathname.split('/').indexOf('reports'), pathname.split('/').length - 1)
      .join('/')
  }`;

  const fetchBranding = useCallback(async () => {
    if (!branding) {
      try {
        const { data } = await getBranding();
        data && setBranding(data);
      } catch (error) {
        console.error(error);
      }
    }
  }, [branding]);

  useEffect(() => {
    fetchBranding();
  }, [fetchBranding]);

  return (
    <div id={'reportsPage'}>
      {(user && user.role.some((role) => role === UserRole.manager)) ||
      currentRole === UserRole.manager ||
      Cache.getItem(CacheQuery.USER) ? (
        <Routes>
          <Route
            path={':accountId'}
            element={
              <Suspense fallback={<Preloader isActive type={'overlay'} />}>
                <Contexts>
                  <Header />
                  <AccountReportPage />
                  {branding && <Footer {...branding} />}
                </Contexts>
              </Suspense>
            }
          />
          <Route
            path={':accountId/:portfolioId'}
            element={
              <Contexts>
                <Header />
                <PortfolioReportPage />
                {branding && <Footer {...branding} />}
              </Contexts>
            }
          />
          <Route path={'/'} element={<Navigate to={'/'} replace />} />
        </Routes>
      ) : (
        <Navigate
          to={`${redirectUrl}${
            window?.location.href.includes('?start=')
              ? `?start=${window?.location.href.split('?start=')[1]}`
              : `?${window?.location.href.split('?')[1]}`
          }`}
          replace
        />
      )}
    </div>
  );
};

export { ReportsRouter };
