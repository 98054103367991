import { LoginCandidate, UserRole } from '../../../pages/auth/context/auth.types';

import { AvailableLocales } from '../../../../../localization';

export type GetAdminSettingsResponse = {
  readonly section: SettingsSection;
  readonly data: PostServer | IbkrConnection | InvestudioConnection | Branding | FlexAccountsData;
};

export enum SettingsSection {
  postServer = 'postServer',
  ibkrConnection = 'ibkrConnection',
  investudioConnection = 'investudioConnection',
  branding = 'branding',
  flexAccountsData = 'flexAccountsData',
}

export type PostServer = {
  readonly serverName: string;
  readonly port: string;
  readonly connectionSecurity: string;
  readonly authenticationMethod: string;
  readonly userName: string;
  readonly password: string;
};

export type IbkrConnection = {
  readonly ipAddress: string;
  readonly port: string;
};

export type InvestudioConnection = {
  readonly investudioApi: string;
};

export type Branding = {
  readonly companyName: string;
  readonly logo: string;
  readonly address1: string;
  readonly address2: string;
  readonly phone: string;
  readonly site: string;
  readonly email: string;
};

export type FlexAccountsData = {
  readonly GlobalFlexId: string;
  readonly DateExpFlexId: string;
  readonly AccountInfo: string;
  readonly CashFlow: string;
  readonly Trades: string;
  readonly OpenPositions: string;
  readonly Cash: string;
  readonly MarkToMark: string;
  readonly Transfers: string;
  readonly ChangeDividend: string;
};

export type VerifyEmailResendPayload = {
  readonly email: string;
  readonly password: string;
};

export type RegisterRootUserPayload = {
  readonly name: string;
  readonly email: string;
  readonly password: string;
  readonly rootRegisterToken: string;
};

export type RegisterUserPayload = {
  readonly email: string;
  readonly password: string;
};

export type getTradeVisionConnectionStatusResponse = {
  readonly connection: boolean;
  readonly loginCandidate?: LoginCandidate;
  readonly reservedRoles?: ReadonlyArray<UserRole>;
};

export type RequestLoginPayload = {
  readonly email: string;
  readonly role?: UserRole;
};

export type CancelLoginRequestPayload = {
  readonly email: string;
};

export type MoLoginPayload = {
  readonly email: string;
  readonly password: string;
  readonly rememberUser: boolean;
  readonly isClientOfficeLogin?: boolean;
  readonly alreadyVerifiedTwoFa?: boolean;
};

export type MoUser = {
  readonly email: string;
  readonly uuid: string;
  readonly name: string;
  readonly role: ReadonlyArray<UserRole>;
  readonly preferences: UserPreferences;
  readonly status: UserStatus;
  readonly isRoot: boolean;
  readonly isLoggedInRole: UserRole;
  readonly createdAt: string;
};

enum UserStatus {
  active = 'active',
  inviteSent = 'inviteSent',
  inviteAccepted = 'inviteAccepted',
  deleted = 'deleted',
}

type UserPreferences = {
  /**
   * User preferred language
   */
  // readonly language: 'ua' | 'uk';
  readonly language: AvailableLocales;

  /**
   * Is dark theme enabled
   */
  readonly darkTheme: boolean;

  /**
   * Dial in for support
   */
  readonly dial: string;

  /**
   * Default messenger
   */
  readonly messenger: 'telegram' | 'viber';

  /**
   * Telegram link
   */
  readonly telegram: string;

  /**
   * Is two factor enabled
   */
  readonly twoFactor: boolean;

  /**
   * Viber link
   */
  readonly viber: string;

  /**
   * Is email verified
   */
  readonly verified: boolean;

  /**
   * Specifies last password change date
   */
  readonly lastPasswordChangeDate?: string | null;

  readonly portals: ReadonlyArray<Office>;
};

export enum Office {
  management = 'management',
  client = 'client',
}

/**
 * @description Portals
 * @category REQUEST/RESPONSE
 */
export type GetPortalsResponse = {
  readonly affiliation: ReadonlyArray<Office>;
};

export type VerifyTwoFaPayload = {
  readonly code: string;
  readonly userEmail: string;
  readonly isClientOfficeLogin?: boolean;
  readonly rememberUser?: boolean;
};

export type PasswordRecoveryPayload = {
  readonly email: string;
};

export type NewPasswordSetupPayload = { readonly password: string; readonly email: string; readonly token: string };
