import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './success.types';
import { Typography } from '../../../../../../../typography';
import styles from './success.module.scss';

/**
 * Success component
 * @description Content for the Reports CollectivePortfolios flow Success stage
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2118-411997&mode=dev
 *
 * @author Oleksii Medvediev
 * @param { SuccessProps } props - SuccessProps defined in the './success.types.ts'
 */
const Success: FC<SuccessProps> = ({ portfolios, t, onSubmitStage }) => (
  <Modal modalId={ModalIds.reportsCollectivePortfolios} title={t('modals.reports.success.title')}>
    <div className={styles.wrapper}>
      <Typography.Text variant={'bodyText3'}>
        {t('modals.reports.success.text', {
          name: portfolios.map(({ portfolioMember }) => portfolioMember).join(', '),
        })}
      </Typography.Text>
      <Button variant={'main'} size={'large'} onClick={onSubmitStage}>
        <Typography.Text variant={'bodyText1'}>{t('modals.reports.success.continue')}</Typography.Text>
      </Button>
    </div>
  </Modal>
);

export { Success };
