import { AccordionBody, AccordionHeader } from './components';
import React, { FC, useEffect, useState } from 'react';

import { AccordionProps } from './accordion.types';
import classNames from 'classnames';
import styles from './accordion.module.scss';

/**
 * Message component
 * @description Accordion which have two parts: header and body, after click on header body will be shown
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const Accordion: FC<AccordionProps> = ({
  title,
  body,
  isMessage,
  className,
  disabled = false,
  isExpanded = false,
  hideBottomLine = false,
  onToggleExpandState,
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(!!isExpanded);

  const toggleExpand = () => {
    if (!disabled) {
      setIsAccordionExpanded(!isAccordionExpanded);
      onToggleExpandState && onToggleExpandState();
    }
  };

  useEffect(() => {
    setIsAccordionExpanded(!!isExpanded);
  }, [isExpanded]);

  return (
    <div
      className={classNames(className, styles.accordion, disabled && styles.disabled)}
      style={{ backgroundColor: isMessage ? '#202025' : '' }}
    >
      <AccordionHeader
        title={title}
        isExpanded={isAccordionExpanded}
        onClick={toggleExpand}
        hideBottomLine={hideBottomLine}
        isMessage={isMessage}
      />
      {isAccordionExpanded && body ? <AccordionBody items={body.items} isMessage={isMessage} /> : null}
    </div>
  );
};

export { Accordion };
