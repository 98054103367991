import { Form as FormComponent, Formik } from 'formik';
import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { Input } from '../../../../../input';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import { capitalize } from 'lodash';
import styles from './form.module.scss';
import { useFormData } from './form.hook';

/**
 * Form component
 * @description Content for the RenamePortfolio flow Form stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-160624&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Form: FC = () => {
  const { t, flowData, initialValues, validationSchema, handleSubmitForm, handleCloseModal } = useFormData();

  return (
    <Modal modalId={ModalIds.renamePortfolioForm} title={t('modals.renamePortfolio.form.title')}>
      {flowData && (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmitForm}>
          {({ setFieldValue, errors, touched, setFieldTouched, isValid, values: { newNamePortfolio } }) => (
            <FormComponent className={styles.wrapper} autoComplete={'off'}>
              <Input
                id={'renameAmount'}
                label={t('modals.renamePortfolio.form.name')}
                type={'text'}
                value={newNamePortfolio}
                onChange={(value) => {
                  setFieldValue('newNamePortfolio', value);
                }}
                onBlur={() => setFieldTouched('newNamePortfolio', true)}
                error={errors.newNamePortfolio && touched.newNamePortfolio ? errors.newNamePortfolio : undefined}
              />
              <div className={styles.controls}>
                <Button size={'large'} variant={'outline'} onClick={handleCloseModal}>
                  <Typography.Text variant={'bodyText1'}>
                    {capitalize(t('modals.renamePortfolio.form.cancel'))}
                  </Typography.Text>
                </Button>
                <Button
                  size={'large'}
                  variant={'main'}
                  type={'submit'}
                  disabled={!flowData || !isValid || !newNamePortfolio}
                  className={styles.saveButton}
                >
                  <Typography.Text variant={'bodyText1'}>
                    {capitalize(t('modals.renamePortfolio.form.save'))}
                  </Typography.Text>
                </Button>
              </div>
            </FormComponent>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export { Form };
