import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { CSVLink } from 'react-csv';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import styles from './total-withdraw.module.scss';
import { useTotalWithdrawData } from './total-withdraw.hook';

/**
 * TotalWithdraw component
 * @description Content for the WithdrawPortfolio flow TotalWithdraw stage.
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const TotalWithdraw: FC = () => {
  const { t, flowData, snapShotData, handleSubmitTotalWithdraw } = useTotalWithdrawData();

  return (
    <Modal modalId={ModalIds.withdrawPortfolioTotalWithdraw} title={t('modals.withdrawPortfolio.totalWithdraw.title')}>
      {flowData && (
        <div className={styles.wrapper}>
          <Typography.Text variant={'bodyText3'} className={styles.label}>
            {t('modals.withdrawPortfolio.totalWithdraw.description')}
          </Typography.Text>
          <div className={styles.buttons}>
            {flowData && snapShotData && (
              <CSVLink
                headers={snapShotData.headers}
                data={snapShotData.data}
                filename={`${flowData.portfolio.PortfolioOnAccountID}.csv`}
              >
                <Button size={'large'} variant={'outline'}>
                  <Typography.Text variant={'bodyText1'}>
                    {t('modals.withdrawPortfolio.totalWithdraw.controlButtons.report')}
                  </Typography.Text>
                </Button>
              </CSVLink>
            )}
            <Button size={'large'} variant={'main'} onClick={handleSubmitTotalWithdraw}>
              <Typography.Text variant={'bodyText1'}>
                {t('modals.withdrawPortfolio.totalWithdraw.controlButtons.continue')}
              </Typography.Text>
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export { TotalWithdraw };
