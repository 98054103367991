import React, { FC } from 'react';

import { ProgressBarProps } from './progress-bar.types';
import { Typography } from '../typography';
import classNames from 'classnames';
import styles from './progress-bar.module.scss';

/**
 * ProgressBar component.
 * @description Progress bar of portfolio
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { ProgressBarProps } props - ProgressBarProps defined in the './progress-bar.types.ts'
 */
const ProgressBar: FC<ProgressBarProps> = ({ value, maxValue }) => {
  const growthMax = (value / maxValue) * 100;
  const rubicon = 50; // magic number, after which we should switch display of percentage

  return (
    <div className={styles.progressBar}>
      <div className={styles.grow} style={{ width: growthMax.toFixed(0).toString().concat('%') }} />
      <div className={classNames(styles.percentage, growthMax >= rubicon && styles.percentageDark)}>
        <Typography.Number variant={'bodyText3'} percent value={Number(growthMax.toFixed(0))} />
      </div>
    </div>
  );
};

export { ProgressBar };
