import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { dashboardReducer, initialState } from './dashboard.state';

import { DashboardContextProps } from './dashboard.types';
import { getAccountsPortfolios } from '../../../services/api';
import { getNotifications } from '../../../services/api/client-office';
import { getSupportInfo } from '../../../../../management-office/app/services/api';
import { useAppContext } from '../../../context';

/**
 * DashboardContext
 * @description Context for DashboardRouter pages
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const DashboardContext = createContext<DashboardContextProps>({
  dispatch: () => null,
});

/**
 * DashboardContextProvider component
 * @description Provider for the DashboardContext
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 * @category Context Providers
 */
const DashboardContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(dashboardReducer, initialState);
  const { isLoading, selectedAccountId, user, dispatch: appDispatch } = useAppContext();
  const [hasDataFetchingStarted, setHasDataFetchingStarted] = useState(false);

  const fetchDashboardData = useCallback(async () => {
    if (!isLoading && !state.accountsPortfolios && user && !hasDataFetchingStarted) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        setHasDataFetchingStarted(true);

        const { data } = await getAccountsPortfolios({ clientEmail: user.email });

        const params = new URLSearchParams(window?.location.search);
        const accountId = params?.get('accountId');

        if (!!data) {
          dispatch({ type: 'SET_ACCOUNTS_PORTFOLIOS', payload: data });
          selectedAccountId
            ? dispatch({
                type: 'SET_CURRENT_ACCOUNT',
                payload: [...data].find(({ AccountID }) => AccountID === selectedAccountId),
              })
            : dispatch({
                type: 'SET_CURRENT_ACCOUNT',
                payload: accountId
                  ? [...data].find(({ AccountID }) => AccountID === accountId)
                  : [...data].sort((a, b) => b.AccountConsolidatedCapital - a.AccountConsolidatedCapital)[0],
              });
        }
      } catch (error) {
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, hasDataFetchingStarted, isLoading, selectedAccountId, state.accountsPortfolios, user]);

  const fetchNotifications = useCallback(async () => {
    try {
      if (!state.notifications && !isLoading && (!!state.accountsPortfolios || !!state.currAcc) && user) {
        const { data } = await getNotifications({ clientEmail: user.email, lang: user.preferences.language });

        data && dispatch({ type: 'SET_NOTIFICATIONS', payload: data });
      }
    } catch (error) {
      console.error(error);
    }
  }, [isLoading, state.accountsPortfolios, state.currAcc, state.notifications, user]);

  const fetchSupportInfo = useCallback(async () => {
    try {
      if (!state.supportInfo && !isLoading && state.notifications) {
        const { data } = await getSupportInfo();

        data && dispatch({ type: 'SET_SUPPORT_INFO', payload: data });
      }
    } catch (error) {
      console.error(error);
    }
  }, [isLoading, state.notifications, state.supportInfo]);

  useEffect(() => {
    fetchDashboardData();
    fetchNotifications();
    fetchSupportInfo();
  }, [fetchDashboardData, fetchNotifications, fetchSupportInfo]);

  return <DashboardContext.Provider value={{ ...state, dispatch }}>{children}</DashboardContext.Provider>;
};

/**
 * useDashboardContext hook.
 * @description The hook for getting DashboardContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useDashboardContext = () => useContext(DashboardContext);

export { DashboardContextProvider, useDashboardContext };
