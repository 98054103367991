import { en as authEn, uk as authUk } from '../auth/localization';
import { en as clientEn, uk as clientUk } from '../client-office/localization';
import { en as managementEn, uk as managementUk } from '../management-office/localization';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  resources: {
    uk: {
      translation: {
        auth: authUk,
        client: clientUk,
        management: managementUk,
      },
    },
    en: {
      translation: {
        auth: authEn,
        client: clientEn,
        management: managementEn,
      },
    },
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
