import { Error, Request, Success } from './components';
import React, { useCallback, useMemo } from 'react';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { DeleteProps } from './delete.types';
import { ErrorProps } from './components/error/error.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { RequestProps } from './components/request/request.types';
import { SuccessProps } from './components/success/success.types';
import { deleteUser } from '../../../../../../services/api';

/**
 * useDeleteData hook
 * @description The hook which processes AutomatedOrdersConformation Delete flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useDeleteData = () => {
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { usersManagementDelete },
    dispatch,
  } = useModalsContext();

  const flowData: DeleteProps | undefined = useMemo(() => usersManagementDelete, [usersManagementDelete]);

  const setStage = useCallback(
    (stage: DeleteProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.usersManagementDelete,
          data: {
            [ModalIds.usersManagementDelete]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    DeleteProps['currentStage'],
    RequestProps['onSubmit'] | SuccessProps['onClose'] | ErrorProps['onClose']
  > = useMemo(
    () => ({
      request: async () => {
        if (user && flowData) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { message } = await deleteUser({
              id: flowData.targetUserId,
            });

            if (message === 'ok') {
              await flowData.onRefetchUsers();
              setStage('success');
            } else {
              setStage('error');
            }
          } catch (error) {
            console.error(error);
            setStage('error');
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.usersManagementDelete });
      },
      error: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.usersManagementDelete });
      },
    }),
    [user, flowData, appDispatch, setStage, dispatch],
  );

  const stages: Record<DeleteProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            request: (
              <Request
                onSubmit={submitHandlers.request}
                onClose={() => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.usersManagementDelete })}
              />
            ),
            success: (
              <Success email={flowData.targetUserEmail} onClose={submitHandlers.success as SuccessProps['onClose']} />
            ),
            error: <Error onClose={submitHandlers.error as ErrorProps['onClose']} />,
          }
        : undefined,
    [dispatch, flowData, submitHandlers.error, submitHandlers.request, submitHandlers.success],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useDeleteData };
