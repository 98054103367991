import { Button } from './button';
import { Input } from './input';
import { Toggle } from './toggle';
import { TwoFaInput } from './two-fa-input';
import { Typography } from './typography';

export const Desktop = {
  Button,
  Toggle,
  Typography,
  Input,
  TwoFaInput,
};
