// import { ReactComponent as Star } from '../../../../assets/icons/star.svg';
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
export enum Values {
  money = 'money',
  percentage = 'percentage',
  chartPercentage = 'chartPercentage',
  ratio = 'ratio',
  days = 'days',
  default = '',
  twoAfterDot = 'twoAfterDot',
}

const currency = (num: number, s?: string) => {
  return `$${parseFloat(num.toString())
    .toFixed(0)
    .replace(new RegExp('\\d(?=(\\d{3})+$)', 'g'), `$&${s || ' '}`)}`;
};

const numberType = (variant: Values, value: number) => {
  switch (variant) {
    case Values.money:
      return `${currency(value)}`;
    case Values.percentage:
      return `${value.toString().length > 4 ? value.toFixed(2) : value}%`;
    // return `${value.toString().split('.')[1].length > 4 ? value.toFixed(4) : value}%`;

    case Values.chartPercentage:
      return `${value >= 0 ? '+' : ''}${value.toString().length > 4 ? value.toFixed(2) : value}%`;
    case Values.twoAfterDot:
      return `${
        value.toString().includes('.')
          ? value.toFixed(2).toString().includes('00')
            ? value.toFixed(0)
            : value.toFixed(2)
          : value
      }`;
    // case Values.ratio:
    //   return [...Array(value).keys()].map((n) => {
    //     return <Star key={n} />;
    //   });
    default:
      return value;
  }
};

export { currency, numberType };
