import React, { FC } from 'react';

import { NumberProps } from './number.types';
import classNames from 'classnames';
import kitStyles from '../typography.module.scss';
import { useNumberHook } from './number.hook';

/**
 * Typography.Number component
 * @description component which render number with different props
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { NumberProps } props - NumberProps defined in the './number.types.ts'
 */
const Number: FC<NumberProps> = (props) => {
  const { finalValue, colorizeClass } = useNumberHook(props);
  return <div className={classNames(kitStyles[props.variant], colorizeClass)}>{finalValue}</div>;
};

export { Number };
