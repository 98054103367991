import { UserRole } from '../../../../../../contexts/app/app.types';

enum Stage {
  start = 'start',
  confirmation = 'confirmation',
  reject = 'reject',
}

/**
 * ChangeRoleProps
 * @description Props for the ChangeRole component
 *
 * @author Rostyslav Nahornyi
 */
type ChangeRoleProps = {
  readonly role: UserRole;
};

export type { ChangeRoleProps };
export { Stage };
