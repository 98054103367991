import { Action, DashboardState } from './dashboard.types';

/**
 * @description InitialState for the DashboardContext
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 */
const initialState: DashboardState = {};

/**
 * @description Reducer function for the useReducer hook in the DashboardContextProvider component.
 *
 * @author Oleksii Medvediev
 * @param {DashboardState} state - State defined in the './dashboard.types.ts'
 * @param {Action} action - Action defined in the './dashboard.types.ts'
 * @returns updated DashboardContextProvider state.
 */
const dashboardReducer = (state: DashboardState, action: Action): DashboardState => {
  switch (action.type) {
    case 'OPEN_NOTIFICATION_DRAWER': {
      return { ...state, isNotificationDrawerOpened: true };
    }

    case 'CLOSE_NOTIFICATION_DRAWER': {
      return { ...state, isNotificationDrawerOpened: false, notifications: undefined };
    }

    case 'SET_NOTIFICATIONS': {
      return { ...state, notifications: action.payload };
    }

    case 'SET_ACCOUNTS_PORTFOLIOS': {
      return {
        ...state,
        accountsPortfolios: action.payload,
      };
    }

    case 'SET_CURRENT_ACCOUNT': {
      return {
        ...state,
        currAcc: action.payload,
      };
    }

    case 'SET_SUPPORT_INFO': {
      return {
        ...state,
        supportInfo: action.payload,
      };
    }

    default:
      return state;
  }
};

export { initialState, dashboardReducer };
