import * as yup from 'yup';

import { resendTwoFa, verifyTwoFa } from '../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../contexts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useTranslation } from 'react-i18next';

type TwoFaFormValues = {
  readonly code: Partial<[number, number, number, number]>;
};

const initialValues: TwoFaFormValues = { code: [undefined, undefined, undefined, undefined] };

/**
 * usePersonConfirmationData hook
 * @description The hook which processes PersonConfirmation modal window data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const usePersonConfirmationData = () => {
  const [countdown, setCountdown] = useState(0);
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, errorMessage, dispatch: appDispatch } = useAppContext();
  const {
    data: { personConfirmation },
    dispatch,
  } = useModalsContext();

  const flowData = useMemo(() => personConfirmation, [personConfirmation]);

  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleResendCode = useCallback(async () => {
    if (user && user) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        await resendTwoFa({ userEmail: user.email });
      } catch (error) {
        console.error();
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
    setCountdown(60);
  }, [appDispatch, user]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.personConfirmation }),
    [dispatch],
  );

  const handleSetError = useCallback(
    (payload?: string) => appDispatch({ type: 'SET_ERROR_MESSAGE', payload }),
    [appDispatch],
  );

  const handleSubmit = async (values: TwoFaFormValues) => {
    if (user && user.email) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await verifyTwoFa({ code: values.code.join(''), userEmail: user.email });
        if (data && typeof data !== 'string') {
          flowData?.onSuccess();
          handleCloseModal();
        } else {
          handleSetError(t('settings-page.content.security.modals.twoFaChange.error.wrongCode'));
        }
      } catch (error) {
        handleSetError(t('settings-page.content.security.modals.twoFaChange.error.wrongCode'));
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevState) => prevState - 1);
      }, 1000);

      if (countdown <= 0) {
        clearInterval(countdownInterval);
      }

      return () => clearInterval(countdownInterval);
    }
  }, [countdown]);

  const validationSchema: yup.Schema<{ readonly code: ReadonlyArray<number> }> = yup.object({
    code: yup.array().length(4).of(yup.number().required().min(0).max(9)).required(),
  });

  return {
    t,
    input1Ref,
    input2Ref,
    input3Ref,
    input4Ref,
    countdown,
    submitButtonRef,
    email: user?.email,
    errorMessage,
    validationSchema,
    initialValues,
    handleResendCode,
    handleSubmit,
    handleSetError,
  };
};

export { usePersonConfirmationData };
