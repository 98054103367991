import React, { FC } from 'react';

import { TendencyIndicatorProps } from './tendency-indicator.types';
import styles from './tendency-indicator.module.scss';
import { Typography } from '../../../typography';
import { ReactComponent as TendencyIcon } from '../../../../../assets/icons/tendency.svg';
import classNames from 'classnames';

/**
 * TendencyIndicator component
 * @description Tendency count indicator
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { TendencyIndicatorProps } props - TendencyIndicatorProps defined in the './messages-indicator.types.ts'
 */
const TendencyIndicator: FC<TendencyIndicatorProps> = ({ direction, text }) => (
  <div className={styles.wrapper}>
    {<TendencyIcon className={classNames(styles.icon, styles[direction])} />}
    <Typography.Text variant="label4" className={styles[direction]}>
      {text}
    </Typography.Text>
  </div>
);

export { TendencyIndicator };
