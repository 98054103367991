import React, { FC } from 'react';

import { ReactComponent as AngleLeft } from '../../../assets/icons/angle-left-b.svg';
import { ReactComponent as AngleRight } from '../../../assets/icons/angle-right.svg';
import { Button } from '../button';
import { PaginationProps } from './pagination.types';
import classNames from 'classnames';
import styles from './pagination.module.scss';
import { usePaginationHook } from './pagination.hook';

/**
 * Pagination component
 * @description Pagination
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { PaginationProps } props - PaginationProps defined in the './pagination.types.ts'
 */
const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, onChangePage }) => {
  const { handleArrowClick, handlePageChange, visiblePages } = usePaginationHook(totalPages, onChangePage, currentPage);

  return (
    <div className={styles.pagination}>
      <span
        role={'button'}
        tabIndex={0}
        onKeyDown={() => handleArrowClick('left')}
        className={styles.arrowLeft}
        onClick={() => handleArrowClick('left')}
      >
        <AngleLeft />
      </span>
      {!!visiblePages?.length &&
        visiblePages.map((page) => (
          <Button
            className={classNames(styles.button, page === currentPage ? styles.selectedButton : '')}
            key={page}
            selected={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Button>
        ))}

      <span
        role={'button'}
        tabIndex={0}
        onKeyDown={() => handleArrowClick('right')}
        className={styles.arrowRight}
        onClick={() => handleArrowClick('right')}
      >
        <AngleRight />
      </span>
    </div>
  );
};

export { Pagination };
