import { FC, useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector, SectorProps } from 'recharts';

import styles from './donut-chart.module.scss';
import { DonutChartProps } from './donut-chart.types';

const renderActiveShape = (props: SectorProps, label?: string) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      {label && (
        <text x={cx} y={cy} dy={5} textAnchor={'middle'} className={styles.label}>
          {label}
        </text>
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={(outerRadius ?? 0) + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

/**
 * DonutChart component
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { DonutChartProps } props - DonutChartProps defined in the './donut-chart.types.ts'
 */
const DonutChart: FC<DonutChartProps> = ({ label, data, activeSector }) => {
  const activeIndex = useMemo(() => {
    switch (typeof activeSector) {
      case 'number':
        return activeSector;

      case 'string':
        return data.findIndex(({ name }) => activeSector === name);

      default:
        return -1;
    }
  }, [activeSector, data]);

  const processedData = useMemo(
    () =>
      data.map((sectorData, index) => {
        if (index === activeIndex) {
          return { ...sectorData, color: sectorData.activeColor };
        }

        if (sectorData.inactiveColor) {
          return { ...sectorData, color: sectorData.inactiveColor };
        } else {
          const inactiveColor = index % 2 === 1 ? '#E5E5E480' : '#8E8F8C80';
          return { ...sectorData, color: inactiveColor };
        }
      }),
    [data, activeIndex],
  );
  return (
    <ResponsiveContainer width={'100%'} height={'100%'} className={styles.chart}>
      <PieChart>
        <Pie
          dataKey={'value'}
          activeIndex={activeIndex}
          activeShape={(shapeProps) => renderActiveShape(shapeProps, label)}
          data={processedData}
          cx={'50%'}
          cy={'50%'}
          innerRadius={37.5}
          outerRadius={47}
          stroke={'none'}
          strokeWidth={0}
        >
          {processedData.map(({ color }, idx) => (
            <Cell key={`cell-${idx}`} fill={color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export { DonutChart };
