import {
  AcceptRecommendationsPayload,
  AcceptRecommendationsResponse,
  GetRecommendationsResponse,
  RejectRecommendationsPayload,
  RejectRecommendationsResponse,
  TraderGetTickerShortInfoPayload,
  TraderGetTickerShortInfoResponse,
  TraderInitResponse,
  TraderP1Response,
  TraderP2Payload,
  TraderP2Response,
  TraderP3Payload,
  TraderP3Response,
  TraderP5Payload,
  TraderP5Response,
  TraderP6Payload,
  TraderP6Response,
  TraderP8Payload,
  TraderP8Response,
} from './trader.model';

import { request } from '../../http';

export const checkIfIsHolyDay = () =>
  request<{ readonly data: TraderP1Response }>({ method: 'get', url: `office/trader/p1` });

export const getTraderTasks = ({ lang, role, roleLoginEmail }: TraderP2Payload) =>
  request<{ readonly data: TraderP2Response }>({
    method: 'get',
    url: `office/trader/p2?roleLoginEmail=${roleLoginEmail}&lang=${lang}&role=${role}`,
  });

export const getTraderMessages = ({ roleLoginEmail, lang, role }: TraderP3Payload) =>
  request<{ readonly data: TraderP3Response }>({
    method: 'get',
    url: `office/trader/p3?roleLoginEmail=${roleLoginEmail}&lang=${lang}&role=${role}`,
  });

export const getTraderFaq = ({ language, role, topicLimit }: TraderP5Payload) =>
  request<{ readonly data: TraderP5Response }>({
    method: 'get',
    url: `office/trader/p5?role=${role}&lang=${language}&topicLimit=${topicLimit}`,
  });

export const getTraderStrategy = ({ email, language, role, strategyId, strategyType }: TraderP6Payload) =>
  request<{ readonly data: TraderP6Response }>({
    method: 'get',
    url: `office/trader/p6?email=${email}&language=${language}&role=${role}&strategyId=${strategyId}&strategyType=${strategyType}`,
  });

export const getRecommendations = ({ accountsIds }: { readonly accountsIds: ReadonlyArray<string> }) =>
  request<{ readonly data: GetRecommendationsResponse }>({
    method: 'get',
    url: 'ibkr-tws/recommendations',
    params: { ids: accountsIds },
  });

export const acceptRecommendations = (data: AcceptRecommendationsPayload) =>
  request<{ readonly data: AcceptRecommendationsResponse }>({
    method: 'post',
    data,
    url: 'ibkr-tws/accept-recommendation',
  });

export const rejectRecommendations = (data: RejectRecommendationsPayload) =>
  request<{ readonly data: RejectRecommendationsResponse }>({
    method: 'post',
    data,
    url: 'ibkr-tws/reject-recommendation',
  });

export const getTickerShortInfo = ({ limit, ticker, isin }: TraderGetTickerShortInfoPayload) =>
  request<{ readonly data: TraderGetTickerShortInfoResponse }>({
    method: 'get',
    url: `office/trader/ticker-short-info?ticker=${ticker}&isin=${isin}&limit=${limit}`,
  });

export const getTraderActive = ({ ticker, isin }: TraderP8Payload) =>
  request<{ readonly data: TraderP8Response }>({
    method: 'get',
    url: `office/trader/p8?isin=${isin}&ticker=${ticker}`,
  });

export const getInitForTrader = (params: TraderP2Payload & TraderP3Payload) =>
  request<{ readonly data: TraderInitResponse }>({ method: 'get', url: 'office/trader/init', params });
