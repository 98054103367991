import React, { FC, useState } from 'react';

import { Accordion } from '../accordion';
import { AccordionListProps } from './accordion-list.types';
import styles from './accordion-list.module.scss';

/**
 * Message component
 * @description Accordion which have two parts: header and body, after click on header body will be shown, but another accordion will be not closed, only if click on his header.
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const AccordionList: FC<AccordionListProps> = ({ accordionListItems }) => {
  const [expandedItem, setExpandedItem] = useState<string | number>();
  return (
    <div className={styles.accordionList}>
      {accordionListItems.map(({ id, title, body }, idx) => (
        <Accordion
          key={id + String(idx)}
          id={id}
          isExpanded={expandedItem === id}
          onToggleExpandState={() => setExpandedItem(expandedItem === id ? undefined : id)}
          title={title}
          body={body}
        />
      ))}
    </div>
  );
};

export { AccordionList };
