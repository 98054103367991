import React, { FC } from 'react';

import { Button } from '../../../button';
import { DateRange } from '../../../date-picker';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import { addDays } from 'date-fns';
import styles from './date-range-picker-modal.module.scss';
import { useDateRangePickerModalData } from './date-range-picker-modal.hook';

/**
 * DateRangePickerModal component
 * @description Modal window with date range picker
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const DateRangePickerModal: FC = () => {
  const { t, ranges, modalData, defaultRange, setRanges, handleSubmit } = useDateRangePickerModalData();

  return modalData ? (
    <Modal
      modalId={ModalIds.dateRangePicker}
      title={modalData.title}
      onBackButtonClick={modalData.onBackButtonClick}
      onClose={modalData.onClose}
    >
      <div className={styles.wrapper}>
        {modalData.description && <Typography.Text variant={'bodyText3'}>{modalData.description}</Typography.Text>}
        <DateRange
          onChange={(item: { selection: { startDate: Date; endDate: Date; key: string } }) => {
            setRanges([
              {
                ...(modalData.startNow ? { ...item.selection, startDate: new Date() } : item.selection),
                isChanged: true,
              },
            ]);
          }}
          ranges={ranges}
          minDate={
            modalData.disablePastDatesSelect || modalData.availableRange?.min
              ? modalData.availableRange?.min ?? new Date()
              : addDays(new Date(), -800)
          }
          maxDate={modalData.availableRange?.max ?? addDays(new Date(), 1000)}
        />
        <div className={styles.buttons}>
          {ranges[0].endDate && ranges[0].isChanged && (
            <Button
              variant={'outline'}
              size={'large'}
              type={'button'}
              onClick={() => {
                setRanges(defaultRange);
                modalData.onSubmit(undefined);
              }}
            >
              <Typography.Text variant={'bodyText1'}>{t('modals.dateRangePicker.cancel')}</Typography.Text>
            </Button>
          )}
          <Button variant={'main'} size={'large'} disabled={!ranges[0].isChanged && !ranges[0].startNow} onClick={handleSubmit}>
            <Typography.Text variant={'bodyText1'}>{t('modals.dateRangePicker.select')}</Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export { DateRangePickerModal };
