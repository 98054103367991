import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import React, { FC } from 'react';

import { SmallLineChartProps } from './small-line-chart.types';
import { ReactComponent as SparklineGreen } from '../../../assets/icons/sparkline-green.svg';
import { ReactComponent as SparklineRed } from '../../../assets/icons/sparkline-red.svg';
import { Typography } from '..';
import styles from './small-line-chart.module.scss';

/**
 * SmallLineChart component
 * @description Component which showing the tariff
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { SmallLineChartProps } props - SmallLineChartProps defined in the './small-line-chart.types.ts'
 */
// TODO: Find another solution of Storybook reference error instead of 'var'
// eslint-disable-next-line no-var
var SmallLineChart: FC<SmallLineChartProps> = ({ chartData, descending, labelNumber, labelTitle }) => (
  <div className={styles.smallLineChart}>
    {labelTitle && (
      <Typography.Text variant={'bodyText3'} className={styles.contrast}>
        {labelTitle}
      </Typography.Text>
    )}
    {!chartData ? (
      <>{descending ? <SparklineRed /> : <SparklineGreen />}</>
    ) : (
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <AreaChart data={[...chartData]} margin={{ top: 0, right: 1, left: 1, bottom: 0 }}>
          <defs>
            <linearGradient id={'LineGradient'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
              <stop offset={'5%'} stopColor={'#30BB70'} stopOpacity={0.8} />
              <stop offset={'95%'} stopColor={'#30BB70'} stopOpacity={0} />
            </linearGradient>
            <linearGradient id={'descLineGradient'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
              <stop offset={'5%'} stopColor={'#C91D12'} stopOpacity={0.8} />
              <stop offset={'95%'} stopColor={'#C91D12'} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type={'linear'}
            dataKey={'y'}
            stroke={!descending ? '#40f493' : '#C91D12'}
            strokeWidth={2}
            fillOpacity={1}
            fill={!descending ? 'url(#LineGradient)' : 'url(#descLineGradient)'}
          />
        </AreaChart>
      </ResponsiveContainer>
    )}

    {labelNumber && <Typography.Number variant={'bodyText3'} colorize sign percent round={2} value={labelNumber} />}
  </div>
);

export { SmallLineChart };
