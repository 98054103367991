import './localization/localization.config';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AuthApp } from './auth';
import { ClientOfficeApp } from './client-office';
import { Localization } from './localization';
import { ManagementOfficeApp } from './management-office';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Localization>
    <BrowserRouter>
      <Routes>
        <Route path={'/auth/*'} element={<AuthApp />} />
        <Route path={'/management/*'} element={<ManagementOfficeApp />} />
        <Route path={'/client/*'} element={<ClientOfficeApp />} />
        <Route path={'*'} element={<Navigate to={'auth'} />} />
      </Routes>
    </BrowserRouter>
  </Localization>,
);

reportWebVitals();
