import { Button } from '../../../../../../../button';
import { ConfirmationStageProps } from './confirmation-stage.types';
import { FC } from 'react';
import { Preloader } from '../../../../../../../preloader';
import React from 'react-redux';
import { Typography } from '../../../../../../../typography';
import styles from './confirmation-stage.module.scss';
import { useConfirmationStageData } from './confirmation-stage.hook';
import { useTranslation } from 'react-i18next';

const ConfirmationStage: FC<ConfirmationStageProps> = ({ setStage, role }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  useConfirmationStageData(setStage, role);

  return (
    <div className={styles.confirmationStage}>
      <div className={styles.content}>
        <Preloader isActive={true} type={'small'} color={'yellow'} />
        <Typography.Text>{t('modals.loginRequest.changeRole.confirmationStage.p')}</Typography.Text>
      </div>

      <div className={styles.controlButtons}>
        <Button variant={'outline'} size={'large'} className={styles.btn} disabled>
          {t('modals.loginRequest.changeRole.startStage.controlButtons.cancel')}
        </Button>
        <Button variant={'main'} size={'large'} className={styles.btn} disabled>
          {t('modals.loginRequest.changeRole.startStage.controlButtons.request')}
        </Button>
      </div>
    </div>
  );
};

export { ConfirmationStage };
