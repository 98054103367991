import React, { FC } from 'react';

import { Button } from '../../../button';
import { Calendar } from '../../../date-picker';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import { addDays } from 'date-fns';
import styles from './date-picker-modal.module.scss';
import { useDatePickerModalData } from './date-picker-modal.hook';

/**
 * DatePickerModal component
 * @description Modal window with date picker
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const DatePickerModal: FC = () => {
  const { t, ranges, modalData, defaultRange, setRanges, handleSubmit } = useDatePickerModalData();

  return modalData ? (
    <Modal
      modalId={ModalIds.datePicker}
      title={modalData.title}
      onBackButtonClick={modalData.onBackButtonClick}
      onClose={modalData.onClose}
    >
      <div className={styles.wrapper}>
        {modalData.description && <Typography.Text variant={'bodyText3'}>{modalData.description}</Typography.Text>}
        <Calendar
          onChange={(value: Date) => {
            setRanges([{ ...defaultRange[0], startDate: value, endDate: value, isChanged: true }]);
          }}
          date={ranges[0].startDate}
          ranges={ranges}
          minDate={
            modalData.disablePastDatesSelect || modalData.availableRange?.min
              ? modalData.availableRange?.min ?? new Date()
              : addDays(new Date(), -800)
          }
          maxDate={modalData.availableRange?.max ?? addDays(new Date(), 1000)}
        />
        <div className={styles.buttons}>
          {ranges[0].endDate && ranges[0].isChanged && (
            <Button
              variant={'outline'}
              size={'large'}
              onClick={() => {
                setRanges(defaultRange);
                modalData.onSubmit(undefined);
              }}
            >
              <Typography.Text variant={'bodyText1'}>{t('modals.datePicker.cancel')}</Typography.Text>
            </Button>
          )}
          <Button variant={'main'} size={'large'} disabled={!ranges[0].isChanged} onClick={handleSubmit}>
            <Typography.Text variant={'bodyText1'}>{t('modals.datePicker.select')}</Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export { DatePickerModal };
