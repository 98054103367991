import { Frame, PortfolioState } from '../../../../context/portfolio.types';
import {
  GetPortfolioIncomeF1Response,
  getFrames,
  getPortfolioChart,
} from '../../../../../../services/api/management-office';
import { useCallback, useEffect, useMemo } from 'react';

import { AccordionProps } from '../../../../../../components/accordion/accordion.types';
import { TabMenuProps } from '../../../../../../components/tab-menu/tab-menu.types';
import { Typography } from '../../../../../../components';
import classNames from 'classnames';
import styles from './yield.module.scss';
import { useAppContext } from '../../../../../../context';
import { usePortfolioContext } from '../../../../context';
import { useTranslation } from 'react-i18next';

export const compileYieldOption = (
  newOption: Frame,
  incomeF1: GetPortfolioIncomeF1Response,
  prevSelectedOption: PortfolioState['selectedYieldOption'],
): PortfolioState['selectedYieldOption'] => {
  switch (newOption) {
    case Frame.week:
      return {
        navs: incomeF1[0].NAVS_WEEK,
        profit: incomeF1[0].PROFIT_WEEK,
        name: newOption,
      };

    case Frame.month:
      return {
        navs: incomeF1[0].NAVS_MONTH,
        profit: incomeF1[0].PROFIT_MONTH,
        name: newOption,
      };

    case Frame.quarter:
      return {
        navs: incomeF1[0].NAVS_QUARTER,
        profit: incomeF1[0].PROFIT_QUARTER,
        name: newOption,
      };

    case Frame.year:
      return {
        navs: incomeF1[0].NAVS_YEAR,
        profit: incomeF1[0].PROFIT_YEAR,
        name: newOption,
      };

    case Frame.all:
      return {
        navs: incomeF1[0].NAVS_ALL,
        profit: incomeF1[0].PROFIT_ALL,
        name: newOption,
      };

    default:
      return prevSelectedOption;
  }
};

/**
 * useYieldHook

 * @description Provides data for the Yield component.
 *
 * @author Ihor Dubas, Oleksii Medvediev
 * @category Hooks
 */
const useYieldData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'client' });
  const { incomeF1, portfolio, portfolioChart, frames, yieldPeriod, selectedYieldOption, dispatch } =
    usePortfolioContext();
  const { dispatch: appDispatch } = useAppContext();

  const handleSelectYieldPeriod = useCallback(
    async (newOption: Frame) => {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        if (portfolio) {
          const { data: chartData } = await getPortfolioChart({
            accountId: portfolio.accountId,
            portfolioId: portfolio.id,
            period: newOption,
          });
          chartData && dispatch({ type: 'SET_PORTFOLIO_CHART', payload: chartData });
        }
        const { data } = await getFrames();
        data && dispatch({ type: 'SET_FRAMES', payload: data });
      } catch (error) {
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      !!incomeF1?.length &&
        dispatch({
          type: 'SET_SELECTED_YIELD_OPTION',
          payload: compileYieldOption(newOption, incomeF1, selectedYieldOption),
        });

      dispatch({ type: 'SET_YIELD_PERIOD', payload: newOption });
    },
    [appDispatch, dispatch, incomeF1, portfolio, selectedYieldOption],
  );

  useEffect(() => {
    if (!!incomeF1?.length && !selectedYieldOption) {
      dispatch({
        type: 'SET_SELECTED_YIELD_OPTION',
        payload: compileYieldOption(yieldPeriod, incomeF1, selectedYieldOption),
      });
    }
  }, [dispatch, incomeF1, selectedYieldOption, yieldPeriod]);

  const tabMenuProps: TabMenuProps = useMemo(
    () => ({
      menuType: 'pill',
      tabs: [...Object.values(Frame)].map((item) => ({
        id: item,
        isSelected: yieldPeriod === item,
        label: t(`pages.portfolio.root.chart.${item}`),
        onClick: () => portfolio && item !== yieldPeriod && handleSelectYieldPeriod(item),
      })),
      size: 'small',
    }),
    [handleSelectYieldPeriod, portfolio, t, yieldPeriod],
  );

  const accordionProps: AccordionProps | undefined = useMemo(
    () =>
      !!portfolio
        ? {
            description: [''],
            id: 'description',
            title: '',
            label: t('pages.portfolio.root.indicators'),
            className: styles.accordion,
            body: {
              items: [
                {
                  text: (
                    <div className={styles.indicators}>
                      <Typography.Text variant={'label2'}>{t('pages.portfolio.root.income')}</Typography.Text>
                      <div>
                        <div>
                          <Typography.Text variant={'label3'}>
                            {t(`pages.portfolio.root.chart.${selectedYieldOption?.name}`)}
                          </Typography.Text>
                          <Typography.Number variant={'bodyText2'} currency value={selectedYieldOption?.navs} />
                        </div>
                        <div>
                          <Typography.Text variant={'label3'}>{t('pages.portfolio.root.all')}</Typography.Text>
                          <Typography.Number
                            variant={'bodyText2'}
                            currency
                            value={incomeF1 ? incomeF1[0].NAVS_ALL : 0}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  text: (
                    <div className={styles.indicators}>
                      <Typography.Text variant={'label2'}>{t('pages.portfolio.root.yield')}</Typography.Text>
                      <div>
                        <div>
                          <Typography.Text variant={'label3'}>
                            {t(`pages.portfolio.root.chart.${selectedYieldOption?.name}`)}
                          </Typography.Text>
                          <Typography.Number
                            variant={'bodyText2'}
                            percent
                            sign
                            value={+(Number(selectedYieldOption?.profit) * 100).toFixed(2)}
                          />
                        </div>
                        <div>
                          <Typography.Text variant={'label3'}>{t('pages.portfolio.root.all')}</Typography.Text>
                          <Typography.Number
                            variant={'bodyText2'}
                            percent
                            sign
                            value={+(Number(incomeF1 ? incomeF1[0].PROFIT_ALL : 0) * 100).toFixed(2)}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  text: (
                    <div className={classNames(styles.indicators, styles['indicators--last'])}>
                      <Typography.Text variant={'label2'}>{t('pages.portfolio.root.draft')}</Typography.Text>
                      <div>
                        <div>
                          <Typography.Text variant={'label3'}>{t('pages.portfolio.root.depth')}</Typography.Text>
                          <Typography.Number
                            variant={'bodyText2'}
                            percent
                            value={+(Number(incomeF1 ? incomeF1[0].Drawdown : 0) * 100).toFixed(2)}
                          />
                        </div>
                        <div>
                          <Typography.Text variant={'label3'}>{t('pages.portfolio.root.duration')}</Typography.Text>
                          <Typography.Number
                            variant={'bodyText2'}
                            value={Number(incomeF1 ? incomeF1[0].DaysDrawDown : 0)}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                },
              ],
            },
          }
        : undefined,
    [incomeF1, portfolio, selectedYieldOption?.name, selectedYieldOption?.navs, selectedYieldOption?.profit, t],
  );

  return {
    selectedOption: useMemo(() => selectedYieldOption, [selectedYieldOption]),
    t,
    portfolioChart,
    frames,
    tabMenuProps,
    accordionProps,
  };
};

export { useYieldData };
