import { GetTickerQuotesPayload, GetTickerQuotesResponse, GetTickerRequest, GetTickerResponse } from './common.model';

import { request } from '../../http';

/**
 * Get ticker
 */
export const getTicker = (data: GetTickerRequest) =>
  request<{ readonly data: GetTickerResponse }>({
    method: 'get',
    url: `common/ticker/${data.ticker}/${data.isin}`,
  });

export const getTickerQuotes = (params: GetTickerQuotesPayload) =>
  request<{ readonly data: GetTickerQuotesResponse }>({
    url: 'common/ticker/quotes',
    method: 'get',
    params,
  });
