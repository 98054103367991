import React, { FC } from 'react';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { CheckboxProps } from './checkbox.types';
import classNames from 'classnames';
import styles from './checkbox.module.scss';
import { v4 } from 'uuid';
import { Typography } from '../typography';

/**
 * Checkbox component.
 * @description Checkbox component for entire app usage.
 *
 * @author Rostyslav Nahornyi
 * @category Components.
 * @param { CheckboxProps } props - CheckboxProps defined in the './checkbox.types.ts'
 */
const Checkbox: FC<CheckboxProps> = ({ isChecked, label, disabled, onChange }: CheckboxProps) => {
  const id = v4();

  return (
    <label className={styles.wrapper}>
      <input
        hidden
        type="checkbox"
        id={id}
        onChange={() => !disabled && onChange()}
        checked={isChecked}
        disabled={disabled}
      />
      <div className={classNames(styles.checkWrapper, isChecked && styles.checked)}>
        <span className={classNames(styles.checkIcon, !isChecked && styles.invisibleCheck)}>
          <CheckIcon />
        </span>
      </div>
      <Typography.Text variant="bodyText3">{label}</Typography.Text>
    </label>
  );
};

export { Checkbox };
