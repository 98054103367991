import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './invoice-created-modal.module.scss';
import { useInvoiceCreatedModal } from './invoice-created-modal.hook';

/**
 * InvoiceCreatedModal component
 * @description Content for the AccountCreated window
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const InvoiceCreatedModal: FC = () => {
  const { t, handleContinueClick } = useInvoiceCreatedModal();

  return (
    <Modal modalId={ModalIds.invoiceCreated} title={t('modals.accountCreated.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>{t('modals.accountCreated.text')}</Typography.Text>
        <Button className={styles.button} variant={'main'} size={'large'} onClick={handleContinueClick}>
          <Typography.Text variant={'bodyText1'}>{t('modals.accountCreated.button')}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { InvoiceCreatedModal };
