import { Action, AssetState } from './asset.types';

/**
 * @description InitialState for the AssetContext
 *
 * @author Oleksii Medvediev
 */
const initialState: AssetState = {};

/**
 * @description Reducer function for the useReducer hook in the AssetContextProvider component.
 *
 * @author Oleksii Medvediev
 * @param {AssetState} state - State defined in the './asset.types.ts'
 * @param {Action} action - Action defined in the './asset.types.ts'
 * @returns updated AssetContextProvider state.
 */
const assetReducer = (state: AssetState, { type, payload }: Action): AssetState => {
  switch (type) {
    case 'SET_ASSET': {
      return {
        ...state,
        asset: payload,
      };
    }

    case 'SET_ALLOCATION': {
      return {
        ...state,
        allocation: payload,
      };
    }

    // case 'SET_TICKER_INFO': {
    //   return {
    //     ...state,
    //     tickerInfo: payload,
    //   };
    // }

    case 'SET_TICKER_QUOTES': {
      return {
        ...state,
        tickerQuotes: {
          min: payload?.min ?? 0,
          max: payload?.max ?? 0,
          list: (payload?.list ?? []).map(({ close, date, week }) => ({
            close: close ?? week,
            date,
            week: week ?? 0,
          })),
        },
      };
    }

    case 'SET_QUOTES_PERIOD': {
      return {
        ...state,
        quotesPeriod: payload,
      };
    }

    default:
      return state;
  }
};

export { initialState, assetReducer };
