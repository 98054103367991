import React, { FC } from 'react';

import { BrandingType } from '../../../../services/api/admin/admin.model';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';

const Info: FC<BrandingType> = ({ address1, address2, email, phone, site }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  return (
    <div>
      <Text variant={'small'} lineHeight={15}>
        <span>{address1}</span>
        <br />
        <span>{address2}</span>
        <br />
        <span>
          {t('reports.common.footer.address.addressLine3')}
          <a href={`tel:${phone}`}>{phone}</a>
        </span>
        <br />
        <span>
          <a href={site}>{site}</a>
        </span>
        <br />
        <span>
          {t('reports.common.footer.address.addressLine5.email')}
          <a href={`mailto:${email}`}>{email}</a>
        </span>
      </Text>
    </div>
  );
};

export { Info };
