import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { ConfirmationProps } from './confirmation.types';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import { capitalize } from 'lodash';
import styles from './confirmation.module.scss';

/**
 * Confirmation component
 * @description Content for the PortfoliosSettings flow Confirmation stage
 *
 * @author Oleksii Medvediev
 * @param { ConfirmationProps } props - ConfirmationProps defined in the './confirmation.types.ts'
 * @category Components
 */
const Confirmation: FC<ConfirmationProps> = ({ onClose, onSubmitStage, t }: ConfirmationProps) => (
  <Modal
    modalId={ModalIds.portfoliosSettingsDelete}
    title={capitalize(t('modals.portfoliosSettings.delete.confirmation.title'))}
  >
    <div className={styles.wrapper}>
      <Typography.Text variant={'bodyText3'} className={styles.subtitle}>
        {t('modals.portfoliosSettings.delete.confirmation.subtitle')}
      </Typography.Text>
      <div className={styles.controls}>
        <Button variant={'outline'} size={'large'} onClick={onClose}>
          {capitalize(t('modals.portfoliosSettings.delete.confirmation.cancel'))}
        </Button>
        <Button variant={'main'} size={'large'} type={'button'} onClick={onSubmitStage}>
          {capitalize(t('modals.portfoliosSettings.delete.confirmation.delete'))}
        </Button>
      </div>
    </div>
  </Modal>
);

export { Confirmation };
