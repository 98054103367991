import React, { FC } from 'react';

import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
// import { VncScreen } from 'react-vnc';
import classNames from 'classnames';
import styles from './vnc-modal.module.scss';
// import { useAppContext } from '../../../../contexts';
import { useVNCModalHook } from './vnc-modal.hook';

/**
 * VNCModal component
 * @description Content for the VNCModal window
 *
 * @author Sergii Goncharuk, Oleksii Medvediev
 * @category Components
 */
const VNCModal: FC = () => {
  // const ref = useRef<React.ElementRef<typeof VncScreen>>(null);
  const { isValidUrl, vncUrl, vncPassword } = useVNCModalHook();
  // const { dispatch: appDispatch } = useAppContext();
  // const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal modalId={ModalIds.vnc} title="VNC Viewer" className={styles.base}>
      <div
        className={classNames(
          styles.wrapper,
          // isLoading ? styles.hidden : ''
        )}
      >
        {vncUrl && vncPassword && isValidUrl ? (
          // <VncScreen
          //   url={vncUrl}
          //   scaleViewport
          //   background="#0f0f11"
          //   onCredentialsRequired={(rfb) => {
          //     rfb?.sendCredentials({ password: vncPassword });
          //   }}
          //   onConnect={() => {
          //     setIsLoading(false);
          //     appDispatch({ type: 'TOGGLE_IS_LOADING' });
          //   }}
          //   className={styles.vnc}
          //   ref={ref}
          // />
          <iframe
            src="https://io.inve.studio:6080"
            style={{ width: '100%', height: '100%', maxHeight: '100%' }}
            title={'vnc'}
          />
        ) : (
          <Typography.Text variant={'bodyText3'}>Invalid VNC params..</Typography.Text>
        )}
      </div>
    </Modal>
  );
};

export { VNCModal };
