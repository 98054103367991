import * as yup from 'yup';

import { Error, Request, Success, Summary } from './components';
import {
  ManagerGetAccountPortfoliosSnapshotResponse,
  ManagerSetAccountStrategyPayload,
  StrategyRequirements,
  getAccountPortfoliosSnapshot,
  setAccountPortfolios,
  setAccountStrategy,
} from '../../../../services/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  SummaryFormValues,
  SummaryProps,
  SummaryStrategyRequirementsValidation,
} from './components/summary/summary.types';
import { useAppContext, useModalsContext } from '../../../../contexts';

import { ChangeStrategyFlowProps } from './change-strategy-flow.types';
import { ErrorProps } from './components/error/error.types';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { NewParticipantSetupStages } from '../new-participant-setup-flow/new-participant-setup-flow.types';
import { RequestProps } from './components/request/request.types';
import { SuccessProps } from './components/success/success.types';
import { UserRole } from '../../../../contexts/app/app.types';
import { mapAccountPortfoliosSnapshotToData } from '../../../../utils';
import { useTranslation } from 'react-i18next';

/**
 * useChangeStrategyFlowData hook
 * @description The hook which processes ChangeStrategy flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useChangeStrategyFlowData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { changeStrategy },
    dispatch,
  } = useModalsContext();

  const flowData = useMemo(() => changeStrategy, [changeStrategy]);
  const calcAccountMinCapital = useCallback(
    () =>
      (flowData &&
        flowData.strategies.find(({ StrategyID }) => flowData.accountStrategy.CurrentStrategyID === StrategyID)
          ?.Requirements.MinCapital) ??
      0,
    [flowData],
  );

  const [strategyRequirementsValidation, setStrategyRequirementsValidation] =
    useState<SummaryStrategyRequirementsValidation>({
      isBrokerValid: true,
      areTradingPermissionsValid: true,
      isAccountTypeValid: true,
      isMinCapitalValid: !!((flowData?.accountStrategy.CurrentCapital ?? 0) >= calcAccountMinCapital()),
    });
  const [selectedStrategyRequirements, setSelectedStrategyRequirements] = useState<StrategyRequirements>();
  const [flowValues, setFlowValues] = useState<typeof initialValues>();
  const [accountPortfoliosSnapshot, setAccountPortfoliosSnapshot] =
    useState<ManagerGetAccountPortfoliosSnapshotResponse>();

  const fetchAccountSnapshot = useCallback(async () => {
    if (!accountPortfoliosSnapshot && flowData && user) {
      const { data } = await getAccountPortfoliosSnapshot({
        acctId: flowData.account.AccountID,
        role: UserRole.manager,
        roleLoginEmail: user.email,
      });

      data && setAccountPortfoliosSnapshot(data);
    }
  }, [accountPortfoliosSnapshot, flowData, user]);

  useEffect(() => {
    !flowData?.onSubmitNewParticipantSetupFlowStage && fetchAccountSnapshot();
  }, [fetchAccountSnapshot, flowData?.onSubmitNewParticipantSetupFlowStage]);

  const initialValues: SummaryFormValues = useMemo(
    () => ({
      currentCapital: flowData?.accountStrategy.CurrentCapital ?? 0,
      strategy: flowData?.accountStrategy.CurrentStrategy ?? '',
      expectedReturn:
        flowData?.strategies.find(({ StrategyID }) => StrategyID === flowData.accountStrategy.CurrentStrategyID)
          ?.CAGR ?? 0,
      maxRisk:
        flowData?.strategies.find(({ StrategyID }) => StrategyID === flowData.accountStrategy.CurrentStrategyID)
          ?.MaxDrawDown ?? 0,
    }),
    [flowData],
  );

  const validationSchema: yup.Schema<typeof initialValues> = yup.object({
    currentCapital: yup
      .number()
      .required(t('modals.changeStrategy.summary.form.inputs.currentCapital.errors.required')),
    strategy: yup.string().required(t('modals.changeStrategy.summary.form.inputs.strategy.errors.required')),
    expectedReturn: yup
      .number()
      .min(0, t('modals.changeStrategy.summary.form.inputs.expectedReturn.errors.limit'))
      .max(100, t('modals.changeStrategy.summary.form.inputs.expectedReturn.errors.limit'))
      .required(t('modals.changeStrategy.summary.form.inputs.expectedReturn.errors.required')),
    maxRisk: yup
      .number()
      .min(0, t('modals.changeStrategy.summary.form.inputs.maxRisk.errors.limit'))
      .max(100, t('modals.changeStrategy.summary.form.inputs.maxRisk.errors.limit'))
      .required(t('modals.changeStrategy.summary.form.inputs.maxRisk.errors.required')),
  });

  const setStage = useCallback(
    (stage: ChangeStrategyFlowProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.changeStrategy,
          data: {
            [ModalIds.changeStrategy]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.changeStrategy }),
    [dispatch],
  );

  const snapShotData = useMemo(
    () => accountPortfoliosSnapshot && mapAccountPortfoliosSnapshotToData(accountPortfoliosSnapshot),
    [accountPortfoliosSnapshot],
  );

  const submitHandlers: Record<
    ChangeStrategyFlowProps['currentStage'],
    | RequestProps['onSubmitStage']
    | SummaryProps['onSubmitStage']
    | ErrorProps['onSubmitStage']
    | SuccessProps['onSubmitStage']
  > = useMemo(
    () => ({
      summary: (values) => {
        if (Object.values(strategyRequirementsValidation).every((value) => value)) {
          setFlowValues(values);
          if (!!flowData?.onSubmitNewParticipantSetupFlowStage) {
            flowData.onSubmitNewParticipantSetupFlowStage(values, NewParticipantSetupStages.payment);
            setTimeout(() => {
              handleCloseModal();
            }, 100);
          } else {
            setStage('request');
          }
        } else {
          setStage('error');
        }
      },
      error: () => {
        const accStrategyRequirements =
          flowData &&
          flowData.strategies.find(({ StrategyID }) => flowData.accountStrategy.CurrentStrategyID === StrategyID)
            ?.Requirements;

        setStage('summary');
        setSelectedStrategyRequirements(accStrategyRequirements);
      },
      request: async () => {
        if (user && flowData && flowValues) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const strategyId = flowData.strategies.find(
              ({ StrategyName }) => flowValues.strategy === StrategyName,
            )?.StrategyID;

            const strategyDoc: ManagerSetAccountStrategyPayload['strategyDoc'] | undefined = strategyId
              ? {
                  AccountID: flowData.account.AccountID,
                  CAGR: flowValues.expectedReturn,
                  MaxDrawDown: flowValues.maxRisk,
                  StrategyName: flowValues.strategy,
                  StrategyID: strategyId,
                  ClientEmailOwner: flowData.account.ClientEmailOwner,
                }
              : undefined;

            const portfoliosDoc: ManagerSetAccountStrategyPayload['portfoliosDoc'] = {
              AccountID: flowData.account.AccountID,
              ClientEmailOwner: flowData.account.ClientEmailOwner,
              ReOpenPortfolios: flowData.account.Portfolios.map(({ PortfolioID, PortfolioMember, PortfolioOwner }) => ({
                PortfolioID,
                PortfolioMember,
                PortfolioOwner,
              })),
              OpenPortfolios: [],
              ClosePortfolios: [],
              ChangePortfolios: [],
            };

            if (strategyDoc) {
              const { message: message1 } = await setAccountStrategy({
                role: UserRole.manager,
                roleLoginEmail: user.email,
                strategyDoc,
              });

              const { message: message2 } = await setAccountPortfolios({
                role: user.isLoggedInRole,
                roleLoginEmail: user.email,
                portfoliosDoc,
              });

              if (message1 === 'ok' && message2 === 'ok') {
                flowData.onRefetchParticipants();
                setStage('success');
              } else {
                setStage('error');
              }
            }
          } catch (error) {
            console.error(error);
            setStage('error');
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        handleCloseModal();
      },
    }),
    [strategyRequirementsValidation, setStage, user, flowData, flowValues, appDispatch, handleCloseModal],
  );

  const stages: Record<ChangeStrategyFlowProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            summary: (
              <Summary
                setStrategyRequirementsValidation={setStrategyRequirementsValidation}
                setSelectedStrategyRequirements={setSelectedStrategyRequirements}
                accountStrategy={{
                  ...flowData.accountStrategy,
                  MinCapital:
                    flowData.strategies.find(
                      ({ StrategyID }) => flowData.accountStrategy.CurrentStrategyID === StrategyID,
                    )?.Requirements.MinCapital ?? 0,
                }}
                initialValues={initialValues}
                onSubmitStage={submitHandlers.summary}
                strategies={flowData.strategies}
                selectedStrategyRequirements={selectedStrategyRequirements}
                strategyRequirementsValidation={strategyRequirementsValidation}
                t={t}
                validationSchema={validationSchema}
                stepperProps={flowData.stepperProps}
              />
            ),
            error: <Error onSubmitStage={submitHandlers.error as ErrorProps['onSubmitStage']} t={t} />,
            request: (
              <Request
                accountId={flowData.account.AccountID}
                onSubmitStage={submitHandlers.request as RequestProps['onSubmitStage']}
                snapshotData={snapShotData}
                t={t}
              />
            ),
            success: <Success onSubmitStage={submitHandlers.success as SuccessProps['onSubmitStage']} t={t} />,
          }
        : undefined,
    [
      flowData,
      initialValues,
      snapShotData,
      strategyRequirementsValidation,
      selectedStrategyRequirements,
      submitHandlers.error,
      submitHandlers.request,
      submitHandlers.success,
      submitHandlers.summary,
      t,
      validationSchema,
    ],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useChangeStrategyFlowData };
