import { AvailableLocales } from '../../../../../localization';
import { MoUser } from '../../../../../auth/app/services/api/management-office';
import { UserRole } from '../../../contexts/app/app.types';

export enum Frame {
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
  all = 'all',
}

export enum PaymentFormat {
  Payment = 'Payment',
  Subscription = 'Subscription',
}

export type AdminP1Payload = {
  readonly roleLoginEmail: string;
  readonly role: string;
};

export type AdminP2Payload = {
  readonly roleLoginEmail: string;
  readonly role: string;
  readonly inDate: string;
  readonly frame: Frame;
};

export type AdminP3Payload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
  // readonly lang: 'en' | 'ua';
  readonly lang: AvailableLocales;
};

export type AdminP4Payload = {
  readonly role: UserRole;
};

export type AdminP6Payload = {
  readonly role: UserRole;
  readonly language: string;
  readonly topicLimit: number;
};

export type AdminP7Payload = {
  readonly roleLoginEmail: string;
  readonly role: string;
  readonly lang: AvailableLocales;
  readonly showRoleEnum?: UserRole;
  readonly fromDate?: string;
  readonly toDate?: string;
};

export type AdminP1Response = {
  readonly Accounts: number;
  readonly Employees: number;
  readonly Portfolios: number;
};

export type AdminP2Response = {
  readonly TotalCapital: number;
  readonly CalculatedDate: string;
  readonly InvestedCapital: number;
  readonly IncomeCapital: number;
  readonly DailyChangeCapital: number;
  readonly DailyChangeCapitalPercent: number;
  readonly CapitalChart: ReadonlyArray<{
    readonly Date: string;
    readonly Capital: number;
  }>;
  readonly ProfitabilityChart: ReadonlyArray<{ readonly Date: string; readonly Profitability: number }>;
};

export type AdminP3Response = ReadonlyArray<{
  readonly UUID: string;
  readonly Topic: string;
  readonly Message: string;
  readonly DateTime: string;
  readonly ReadMark: boolean;
  readonly RowNumber: number;
}>;

export type AdminP4Response = {
  readonly PaymentFormat: PaymentFormat;
  readonly Payment: {
    readonly Conditions: {
      readonly ManagementFee: number;
      readonly PerformanceFee: number;
      readonly Period: string;
    };
    readonly Calculation: {
      readonly NextDate: string;
      readonly TotalManagementFee: number;
      readonly TotalPerformanceFee: number;
      readonly PaymentChart: ReadonlyArray<{
        readonly Date: string;
        readonly Feesvalue: number;
      }>;
    };
    readonly PaymentStatus: {
      readonly ExpectedDate: string;
      readonly StatusPayment: boolean;
      readonly PeriodManagementFee: number;
      readonly PeriodPerformanceFee: number;
    };
    readonly PaymentHistory: ReadonlyArray<{
      readonly ID: string;
      readonly Status: number;
      readonly StartPeriod: string;
      readonly FinishPeriod: string;
      readonly PeriodManagementFee: string;
      readonly PeriodPerformanceFee: string;
    }>;
  };
  readonly Subscription: {
    readonly MaxCapital: number;
    readonly ValidUntil: string;
    readonly DaysToUntil: number;
    readonly TotalCapital: number;
    readonly CalculatedDate: string;
    readonly SubscriptionID: string;
    readonly AvailableCapital: number;
    readonly SubscriptionName: string;
    readonly UsedCapitalPercent: number;
    readonly History: ReadonlyArray<{
      readonly ID: string;
      readonly Status: number;
      readonly AnnualCost: string;
      readonly TotalCapital: string;
      readonly SubscriptionID: string;
      readonly DateSubscription: string;
      readonly SubscriptionName: string;
    }>;
  };
};

export type AdminP6Response = ReadonlyArray<{
  readonly Priority: string;
  readonly Topic: string;
  readonly Content: string;
  readonly hyperTextIndex: number;
  readonly link: string;
}>;

export type AdminP7Response = ReadonlyArray<{
  readonly Role: UserRole;
  readonly LogId: string;
  readonly LogText: string;
  readonly LogTopic: string;
  readonly RoleName: string;
  readonly RoleLogin: string;
  readonly LogDateTime: string;
}>;

export type ManagerUser = {
  readonly email: string;
  readonly name: string;
  readonly role: ReadonlyArray<UserRole>;
};

export type AdminSettingsDto = {
  readonly section: SettingsSection;
  readonly data:
    | PostServerType
    | IBGatewayConnectionType
    | TradingModuleConnectionType
    | TradeVisionConnectionType
    | BrandingType
    | FlexAccountsDataType;
};

export enum AdminInvoicesAction {
  download = 'download',
  cancel = 'cancel',
}

export type AdminInvoicesDto = {
  readonly id: string;
  readonly currentSubscription?: string;
};

export enum SettingsSection {
  postServer = 'postServer',
  ibGateway = 'ibGateway',
  tradingModule = 'ibkrConnection',
  tradeVision = 'investudioConnection',
  branding = 'branding',
  flexAccountsData = 'flexAccountsData',
  automatedOrdersConfirmationExpDate = 'automatedOrdersConfirmationExpDate',
}

export type AutomatedOrdersConfirmationExpDateType = ReadonlyArray<{
  readonly strategyId: string;
  readonly period?: {
    readonly startDate: string;
    readonly endDate: string;
  };
  readonly updatedBy: string;
}>;

export type PostServerType = {
  readonly serverName: string;
  readonly port: string;
  readonly connectionSecurity: string;
  readonly authenticationMethod: string;
  readonly userName: string;
  readonly password: string;
};

export type IBGatewayConnectionType = {
  readonly login: string;
  readonly password: string;
};

export type TradingModuleConnectionType = {
  readonly ipAddress: string;
  readonly port: string;
};

export type TradeVisionConnectionType = {
  readonly investudioApi: string;
};

export type BrandingType = {
  readonly companyName: string;
  readonly logo: string;
  readonly address1: string;
  readonly address2: string;
  readonly phone: string;
  readonly site: string;
  readonly email: string;
};

export type FlexAccountsDataType = {
  readonly GlobalFlexId: string;
  readonly DateExpFlexId: string;
  readonly AccountInfo: string;
  readonly CashFlow: string;
  readonly Trades: string;
  readonly OpenPositions: string;
  readonly Cash: string;
  readonly MarkToMark: string;
  readonly Transfers: string;
  readonly ChangeDividend: string;
};

export type AdminSubscriptionsResponse = ReadonlyArray<{
  readonly Currency: string;
  readonly DailyCost: number;
  readonly AnnualCost: number;
  readonly Recomendation: string;
  readonly MinimunCapital: number;
  readonly SubscriptionID: string;
  readonly SubscriptionName: string;
}>;

export type AdminInvoicePdf = BlobPart;

export type GetAdminSettingsResponse = {
  readonly section: SettingsSection;
  readonly data:
    | PostServerType
    | IBGatewayConnectionType
    | TradingModuleConnectionType
    | TradeVisionConnectionType
    | BrandingType
    | FlexAccountsDataType
    | AutomatedOrdersConfirmationExpDateType;
};

export type GetUsersListResponse = ReadonlyArray<MoUser>;

export type AdminInitResponse = {
  readonly summaries: AdminP2Response;
  readonly payment: AdminP4Response;
  readonly users: GetUsersListResponse;
  readonly messages: AdminP3Response;
};

export type ProjectConfig = {
  readonly IBTWS: {
    readonly ENV: {
      readonly TWS_USERID?: string;
      readonly TRADING_MODE: string;
      readonly READ_ONLY_API: string;
      readonly TWS_PASSWORD_FILE?: string;
      readonly VNC_SERVER_PASSWORD: string;
    };
    readonly Image: string;
    readonly PortVNC: number;
    readonly PortLive: number;
    readonly PortPaper: number;
    readonly PrefixName: string;
  };
  readonly Network: {
    readonly PrefixNetwork: string;
  };
  readonly ProjectName: string;
  readonly TradingModule: {
    readonly ENV: {
      readonly HOST: string;
      readonly PORT: number;
      readonly DB_HOST: string;
      readonly DB_NAME: string;
      readonly DB_PORT: number;
      readonly CLIENT_ID: number;
      readonly CON_TABLE: string;
      readonly RECS_TABLE: string;
      readonly DB_PASSWORD: string;
      readonly DB_USERNAME: string;
      readonly PRICE_TABLE: string;
      readonly ORDERS_TABLE: string;
      readonly TRADES_TABLE: string;
      readonly SUMMARY_TABLE: string;
      readonly POSITIONS_TABLE: string;
      readonly SETTINGS_DB_HOST: string;
      readonly SETTINGS_DB_NAME: string;
      readonly SETTINGS_DB_PORT: number;
      readonly FLEX_TRADES_TABLE: string;
      readonly SETTINGS_DB_PASSWORD: string;
      readonly SETTINGS_DB_USERNAME: string;
    };
    readonly Image: string;
    readonly PrefixName: string;
  };
};

export type ProjectConfigStatuses = {
  readonly ibGatewayStatus?: {
    readonly ipAddress: string;
    readonly name: string;
    readonly status: boolean;
  };
  readonly tradingModuleStatus?: {
    readonly ipAddress: string;
    readonly name: string;
    readonly status: boolean;
  };
};

// export type IBGatewayConfig = {
//   readonly login?: string;
//   readonly password?: string;
// };

// export type TradingModuleConfig = {
//   readonly ipAddress?: string;
//   readonly port?: string;
// };
