import * as yup from 'yup';

import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useMemo } from 'react';

import { FormProps } from './form.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { UserRole } from '../../../../../../contexts/app/app.types';
import { setManagerParticipantsPortfolioDeposit } from '../../../../../../services/api';
import { useTranslation } from 'react-i18next';

const initialValues = {
  replenishAmount: '',
};

/**
 * useFormData hook
 * @description The hook which processes ReplenishPortfolio flow Form stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useFormData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { replenishPortfolioForm },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();

  const validationSchema: yup.Schema<typeof initialValues> = yup.object({
    replenishAmount: yup.string().required(t('modals.replenishPortfolio.form.required')),
  });

  const flowData: FormProps | undefined = useMemo(() => replenishPortfolioForm, [replenishPortfolioForm]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.replenishPortfolioForm }),
    [dispatch],
  );

  const handleSubmitForm = useCallback(
    async ({ replenishAmount }: typeof initialValues) => {
      if (user && flowData) {
        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        try {
          const { message } = await setManagerParticipantsPortfolioDeposit({
            sumDeposit: +replenishAmount.replaceAll(' ', ''),
            roleLoginEmail: user.email,
            role: UserRole.manager,
            acctId: flowData.accountId,
            portfolioId: flowData.portfolioId,
          });

          if (message === 'ok') {
            flowData.onRefreshParticipants();

            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.replenishPortfolioSuccess,
                data: {
                  replenishPortfolioSuccess: {
                    amount: +replenishAmount.replaceAll(' ', ''),
                    portfolioName: flowData.portfolioName,
                    onSubmitSuccess: async () => {
                      // appDispatch({ type: 'TOGGLE_IS_LOADING' });

                      // try {
                      //   const { data } = await getManagerParticipantsPortfolioSnapshot({
                      //     acctId: flowData.accountId,
                      //     portfolioId: flowData.portfolioId,
                      //   });
                      //   const fileName = `Report ${flowData.accountId}`;
                      //   const json = JSON.stringify(data, null, 2);
                      //   const blob = new Blob([json], { type: 'application/json' });
                      //   const href = URL.createObjectURL(blob);

                      //   const link = document.createElement('a');
                      //   link.href = href;
                      //   link.download = fileName + '.json';
                      //   document.body.appendChild(link);
                      //   link.click();

                      //   document.body.removeChild(link);
                      //   URL.revokeObjectURL(href);
                      // } catch (error) {
                      //   console.error(error);
                      // }

                      dispatch({ type: 'HIDE_MODAL', payload: ModalIds.replenishPortfolioSuccess });
                      // appDispatch({ type: 'TOGGLE_IS_LOADING' });
                    },
                  },
                },
              },
            });
          }
        } catch (error) {
          console.error(error);
        }

        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        handleCloseModal();
      }
    },
    [appDispatch, dispatch, flowData, handleCloseModal, user],
  );

  return {
    t,
    flowData,
    initialValues,
    validationSchema,
    handleSubmitForm,
  };
};

export { useFormData };
