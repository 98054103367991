import { AppContextProps, UserRole } from './app.types';
import React, { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import {
  SettingsSection,
  getAdminSettings,
  getTradeVisionConnectionStatus,
  getEmailTransporterStatus,
  getProjectConfigStatuses,
} from '../../services/api';
import { appReducer, initialState } from './app.state';

import { AvailableLocales } from '../../../../localization';
import { BrandingType } from '../../services/api/admin/admin.model';
import { Cache } from 'aws-amplify';
import { CacheQuery } from '../../typescript';
import i18next from 'i18next';
import logo from '../../../assets/images/default-logo.png';
import { useLocation } from 'react-router';

/**
 * AppContext
 * @description Context for global app data
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const AppContext = createContext<AppContextProps>({
  dispatch: () => null,
  logo: '',
  isLoading: false,
});

/**
 * AppContextProvider component
 * @description Provider for the AppContext
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 * @category Context Providers
 */
const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const { pathname } = useLocation();
  const browserLanguage = window?.navigator?.language?.split('-')[0];

  const changeLanguage = useCallback(
    (language?: string) => {
      i18next.changeLanguage(
        Object.values(AvailableLocales).some((locale) => locale === language) ? language : browserLanguage,
      );
    },
    [browserLanguage],
  );

  useEffect(() => {
    const cachedUser = Cache.getItem(CacheQuery.USER);

    if (!state.user && cachedUser) {
      dispatch({ type: 'SET_USER', payload: cachedUser });
      dispatch({ type: 'SET_CURRENT_ROLE', payload: cachedUser.role[0] as UserRole });
    }

    // changeLanguage(state.user?.preferences?.language ?? browserLanguage);
  }, [browserLanguage, state.currentRole, state.user]);

  useEffect(() => {
    state.user?.preferences.language && changeLanguage(state.user.preferences.language);
  }, [changeLanguage, state.user?.preferences.language]);

  const checkIBGatewayConnectionStatus = useCallback(async () => {
    try {
      if (!state.user) return;

      const { data } = await getProjectConfigStatuses('ibGateway');

      if (data.ibGatewayStatus) {
        dispatch({ type: 'SET_IBGATEWAY_CONNECTION_STATUS', payload: !!data.ibGatewayStatus.status });
        dispatch({ type: 'SET_IBGATEWAY_NAME', payload: data.ibGatewayStatus.name });
        dispatch({ type: 'SET_IBGATEWAY_IP_ADDRESS', payload: data.ibGatewayStatus.ipAddress });
      } else {
        dispatch({ type: 'SET_IBGATEWAY_CONNECTION_STATUS', payload: false });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'SET_IBGATEWAY_CONNECTION_STATUS', payload: false });
    }
  }, [state.user]);

  const checkTradingModuleConnectionStatus = useCallback(async () => {
    try {
      const { data } = await getProjectConfigStatuses('tradingModule');

      if (data.tradingModuleStatus) {
        dispatch({ type: 'SET_TRADING_MODULE_CONNECTION_STATUS', payload: !!data.tradingModuleStatus.status });
        dispatch({ type: 'SET_TRADING_MODULE_NAME', payload: data.tradingModuleStatus.name });
        dispatch({ type: 'SET_TRADING_MODULE_IP_ADDRESS', payload: data.tradingModuleStatus.ipAddress });
      } else {
        dispatch({ type: 'SET_TRADING_MODULE_CONNECTION_STATUS', payload: false });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'SET_TRADING_MODULE_CONNECTION_STATUS', payload: false });
    }
  }, []);

  const checkTradeVisionConnectionStatus = useCallback(async () => {
    try {
      if (!state.user) return;

      const { data } = await getTradeVisionConnectionStatus(!pathname.includes('auth') ? state.user?.email : undefined);

      if (data) {
        dispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: data.connection });
        dispatch({ type: 'SET_LOGIN_CANDIDATE', payload: data.loginCandidate });
        dispatch({ type: 'SET_RESERVED_ROLES', payload: data.reservedRoles });
      } else {
        dispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: false });
        dispatch({ type: 'SET_LOGIN_CANDIDATE', payload: undefined });
        dispatch({ type: 'SET_RESERVED_ROLES', payload: undefined });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: false });
      dispatch({ type: 'SET_LOGIN_CANDIDATE', payload: undefined });
      dispatch({ type: 'SET_RESERVED_ROLES', payload: undefined });
    }
  }, [pathname, state.user]);

  const checkEmailTransporterStatus = useCallback(async () => {
    try {
      const { data } = await getEmailTransporterStatus();
      if (data) {
        dispatch({ type: 'SET_EMAIL_TRANSPORTER_STATUS', payload: data.connection });
      } else {
        dispatch({ type: 'SET_EMAIL_TRANSPORTER_STATUS', payload: false });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'SET_EMAIL_TRANSPORTER_STATUS', payload: false });
    }
  }, []);

  const fetchBranding = useCallback(async () => {
    if (
      (typeof state.companyName === 'undefined' || (typeof state.logo === 'undefined' && !state.isLoading)) &&
      !pathname.includes('management/reports')
    ) {
      try {
        const { data } = await getAdminSettings();
        const branding = data?.find(({ section }) => section === SettingsSection.branding);

        if ((branding?.data as BrandingType)?.companyName) {
          dispatch({ type: 'SET_COMPANY_NAME', payload: (branding?.data as BrandingType)?.companyName });
          dispatch({ type: 'SET_LOGO', payload: (branding?.data as BrandingType)?.logo ?? logo });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [pathname, state.companyName, state.isLoading, state.logo]);

  const checkStatuses = useCallback(() => {
    checkIBGatewayConnectionStatus();
    checkTradingModuleConnectionStatus();
    checkTradeVisionConnectionStatus();
    checkEmailTransporterStatus();
  }, [
    checkIBGatewayConnectionStatus,
    checkTradingModuleConnectionStatus,
    checkTradeVisionConnectionStatus,
    checkEmailTransporterStatus,
  ]);

  useEffect(() => {
    if (!window?.location.href.includes('reports')) {
      checkStatuses();

      const interval = setInterval(() => {
        checkStatuses();
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [checkStatuses]);

  useEffect(() => {
    fetchBranding();
  }, [fetchBranding]);

  return <AppContext.Provider value={{ ...state, dispatch }}>{children}</AppContext.Provider>;
};

/**
 * useAppContext hook.
 * @description The hook for getting AppContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useAppContext = () => useContext(AppContext);

export { AppContextProvider, useAppContext };
