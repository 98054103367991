import {
  CancelLoginRequestPayload,
  ChangePasswordPayload,
  LoginPayload,
  NewPasswordSetupPayload,
  RegisterRootUserPayload,
  RegisterUserPayload,
  RequestLoginPayload,
  SendTwoFaPayload,
  VerifyEmailPayload,
  VerifyEmailResendPayload,
  VerifyTwoFaPayload,
} from './auth.model';

import { GetAdminSettingsResponse } from '../admin';
import { MoUser } from '../../../../../auth/app/services/api/management-office';
import { UserRole } from '../../../contexts/app/app.types';
import { request } from '../../http';

/**
 * Root user pre-register validation
 */
export const preRegisterValidate = (data: VerifyEmailResendPayload) =>
  request<{ readonly data?: { readonly apiKey: string; readonly companyName: string } }>({
    method: 'post',
    url: 'auth-management-office/pre-register-validation',
    data,
  });

/**
 * Root user registration
 */
export const registerRootUser = (data: RegisterRootUserPayload) =>
  request({ method: 'post', url: 'auth-management-office/root-sign-up', data });

/**
 * Common user registration
 */
export const registerUser = (data: RegisterUserPayload) =>
  request<{ readonly data?: { link: string } }>({ method: 'post', url: 'auth-management-office/sign-up', data });

/**
 * User token verification
 */
export const verify = (data: VerifyEmailPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/verify' });

/**
 * Verify invitation from admin
 */
export const verifyInvitation = (data: VerifyEmailPayload) =>
  request<{ readonly data: { readonly email: string; readonly name: string } }>({
    method: 'post',
    data,
    url: 'auth-management-office/verify-invitation',
  });

/**
 * Login
 */
export const login = (data: LoginPayload) =>
  request<{
    readonly data:
      | (MoUser & { readonly settings: ReadonlyArray<GetAdminSettingsResponse> })
      | 'secondFactor'
      | 'loginRequest';
    readonly token?: string;
  }>({
    method: 'post',
    data,
    url: 'auth-management-office/login',
  });

/**
 * Send two-factor code
 */
export const sendTwoFa = (data: SendTwoFaPayload) =>
  request<{ readonly data: string }>({ method: 'post', data, url: 'auth-management-office/send-two-fa' });

/**
 * Resend two-factor code
 */
export const resendTwoFa = (data: { readonly userEmail: string }) =>
  request({ method: 'post', data, url: 'auth-management-office/resend-two-fa' });

/**
 * Verify two-factor code
 */
export const verifyTwoFa = (data: VerifyTwoFaPayload) =>
  request<{
    readonly data: (MoUser & { readonly settings: ReadonlyArray<GetAdminSettingsResponse> }) | 'loginRequest';
    readonly token?: string;
  }>({
    method: 'post',
    data,
    url: 'auth-management-office/verify-two-fa',
  });

/**
 * Password recovery request
 */
export const passwordRecoveryRequest = (data: { readonly email: string }) =>
  request({ method: 'post', data, url: 'auth-management-office/password-recovery' });

/**
 * New password setup
 */
export const setupNewPassword = (data: NewPasswordSetupPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/new-password-setup' });

/**
 * Get user
 */
export const authenticate = () => request<{ readonly data: MoUser }>({ method: 'get', url: 'auth-management-office' });

/**
 * Change password request
 */
export const changePassword = (data: ChangePasswordPayload) =>
  request<{ readonly data: MoUser }>({ method: 'post', data, url: 'office/change-password' });

/**
 * Logout user
 */
export const logout = (data: { readonly email: string }) =>
  request<{ readonly data: MoUser }>({ method: 'post', data, url: 'auth-management-office/logout' });

/**
 * Change user role
 */
export const changeUserRole = (data: { readonly role: UserRole; readonly email: string }) =>
  request<{ readonly data: UserRole | 'loginRequest' }>({
    method: 'post',
    data,
    url: 'auth-management-office/change-role',
  });

/**
 * Request login
 */
export const requestLogin = (data: RequestLoginPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/request-login' });

/**
 * Cancel login request
 */
export const cancelLoginRequest = (data: CancelLoginRequestPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/cancel-login-request' });
