import React, { FC } from 'react';

import { NoticeItem } from './components';
import { NoticesProps } from './notices.types';
import { Typography } from '../typography';
import { capitalize } from 'lodash';
import styles from './notices.module.scss';
import { useNoticesData } from './notices.hook';

/**
 * Notices component.
 * @description Displays notices grouped by date
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { NoticesProps } props - NoticesProps defined in the './notices.types.ts'
 */
const Notices: FC<NoticesProps> = ({ notices, title, cta }) => {
  const { groups } = useNoticesData(notices);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <Typography.Text variant={'h3'}>{capitalize(title)}</Typography.Text>
        {!!cta && <div className={styles.ctaButton}>{cta}</div>}
      </div>
      {groups.map(({ items, label }, index) => (
        <div className={styles.group} key={index.toString().concat(label)}>
          <Typography.Text variant={'label1'} className={styles.label}>
            {label}
          </Typography.Text>
          {items.map((item, index) => (
            <NoticeItem {...item} key={index.toString()} />
          ))}
        </div>
      ))}
    </div>
  );
};

export { Notices };
