import { Navigate, Route, Routes } from 'react-router';
import React, { FC, Suspense, lazy, useEffect } from 'react';
import { SettingsContextProvider, useSettingsContext } from './context';

import { FallbackLoader } from '../../components';

const Settings = lazy(() => import('./components/settings/settings.component'));
const EmailChange = lazy(() => import('./components/email-change/email-change.component'));
const PasswordChange = lazy(() => import('./components/password-change/password-change.component'));
const TwoFaChange = lazy(() => import('./components/two-fa-change/two-fa-change.component'));
const TwoFaConfirmation = lazy(() => import('./components/two-fa-confirmation/two-fa-confirmation.component'));

const SettingsRouter: FC = () => {
  useSettingsContext();

  // Setting Settings title
  useEffect(() => {
    if (document) {
      document.title = 'Settings | Clients Office';
    }
  }, []);

  return (
    <SettingsContextProvider>
      <Routes>
        <Route
          path={''}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path={'email-change'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <EmailChange />
            </Suspense>
          }
        />
        <Route
          path={'password-change'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <PasswordChange />
            </Suspense>
          }
        />
        <Route
          path={'two-fa-change'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <TwoFaChange />
            </Suspense>
          }
        />
        <Route
          path={'two-fa-confirmation'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <TwoFaConfirmation />
            </Suspense>
          }
        />
        <Route path={'*'} element={<Navigate to={''} />} />
      </Routes>
    </SettingsContextProvider>
  );
};

export { SettingsRouter };
