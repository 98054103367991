import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { RequestProps } from './request.types';
import { Typography } from '../../../../../../../typography';
import styles from './request.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Request component
 * @description Content for the UsersManagement Delete flow Request stage
 *
 * @author Oleksii Medvediev
 * @param { RequestProps } props - RequestProps defined in the './request.types.ts'
 * @category Components
 */
const Request: FC<RequestProps> = ({ onSubmit, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  return (
    <Modal modalId={ModalIds.usersManagementDelete} title={t('modals.usersManagement.delete.request.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>{t('modals.usersManagement.delete.request.text')}</Typography.Text>
        <div className={styles.controls}>
          <Button size={'large'} variant={'outline'} type={'button'} onClick={onClose}>
            <Typography.Text variant={'bodyText1'}>{t('modals.usersManagement.delete.request.cancel')}</Typography.Text>
          </Button>
          <Button size={'large'} variant={'main'} type={'button'} onClick={onSubmit}>
            <Typography.Text variant={'bodyText1'}>{t('modals.usersManagement.delete.request.submit')}</Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { Request };
