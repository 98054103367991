import React, { FC, useState } from 'react';

import { Button } from '../../../../../../../button';
import { ReactComponent as CalendarIcon } from '../../../../../../../../../assets/icons/calendar-16.svg';
import { CustomizationProps } from './customization.types';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import dayjs from 'dayjs';
import { formatPeriodLabel } from '../../edit.hook';
import styles from './customization.module.scss';
import { useModalsContext } from '../../../../../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * Customization component
 * @description Content for the AutomatedOrdersConfirmation Edit flow Customization stage
 *
 * @author Oleksii Medvediev
 * @param { CustomizationProps } props - CustomizationProps defined in the './customization.types.ts'
 * @category Components
 */
const Customization: FC<CustomizationProps> = ({ onSubmit, period, setPeriod }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const [currentScreen, setCurrentScreen] = useState<'default' | 'calendar'>('default');
  const { dispatch } = useModalsContext();

  return currentScreen === 'default' ? (
    <Modal
      modalId={ModalIds.automatedOrdersConfirmationEdit}
      title={t('modals.automatedOrdersConfirmation.edit.customization.title')}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Typography.Text variant={'bodyText3'} className={styles.textWarning}>
            {t('modals.automatedOrdersConfirmation.edit.customization.text')}
          </Typography.Text>
          <Button
            type={'button'}
            variant={'main'}
            size={'small'}
            leadingIcon={<CalendarIcon />}
            onClick={() => {
              dispatch({
                type: 'SHOW_MODAL',
                payload: {
                  id: ModalIds.dateRangePicker,
                  data: {
                    dateRangePicker: {
                      onSubmit: (period) => {
                        setPeriod(
                          period
                            ? {
                                startDate: period.startDate,
                                endDate: period.endDate,
                              }
                            : undefined,
                        );
                        period && setCurrentScreen('default');
                      },
                      onClose: () => {
                        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationEdit });
                      },
                      onBackButtonClick: () => {
                        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.dateRangePicker });
                        setCurrentScreen('default');
                      },
                      availableRange: {
                        max: dayjs().add(1, 'year').toDate(),
                        min: new Date(),
                      },
                      startDate: period && dayjs(period.startDate).toDate(),
                      endDate: period && dayjs(period.endDate).toDate(),
                      disablePastDatesSelect: true,
                      startNow: true,
                      title: t('modals.automatedOrdersConfirmation.edit.customization.calendarTitle'),
                      description: t('modals.automatedOrdersConfirmation.edit.customization.calendarDescription'),
                    },
                  },
                },
              });
              setCurrentScreen('calendar');
            }}
          >
            <Typography.Text variant={'bodyText1'}>
              {period
                ? formatPeriodLabel(period.startDate, period.endDate, t)
                : t('modals.automatedOrdersConfirmation.edit.customization.period')}
            </Typography.Text>
          </Button>
        </div>
        <Button variant={'main'} size={'large'} onClick={onSubmit} disabled={!period}>
          <Typography.Text variant={'bodyText1'}>
            {t('modals.automatedOrdersConfirmation.edit.customization.continue')}
          </Typography.Text>
        </Button>
      </div>
    </Modal>
  ) : null;
};

export { Customization };
