import { FC, ReactElement } from 'react';
import { Priority, TableIndicatorProps } from './table-priority.types';

import { ReactComponent as HighPriorityIcon } from '../../../../../assets/icons/high-table-priority.svg';
import { ReactComponent as MiddlePriorityIcon } from '../../../../../assets/icons/middle-table-priority.svg';
import { ReactComponent as LowPriorityIcon } from '../../../../../assets/icons/low-table-priority.svg';

const indicators: Record<Priority, ReactElement> = {
  [Priority.high]: <HighPriorityIcon />,
  [Priority.middle]: <MiddlePriorityIcon />,
  [Priority.low]: <LowPriorityIcon />,
};

/**
 * TablePriority Component
 *
 * @author Rostyslav Nahornyi
 * @category UI-KIT/component
 * @param { TableIndicatorProps } props - text defined in the './TableIndicatorProps.types.ts'
 */
const TablePriority: FC<TableIndicatorProps> = ({ priority }) => indicators[priority];

export { TablePriority };
