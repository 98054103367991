import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { appReducer, initialState } from './app.state';

import { AppContextProps } from './app.types';
import { AvailableLocales } from '../../../../localization';
import { Cache } from 'aws-amplify';
import { CacheQuery } from '../../shared';
import DefaultLogo from '../../../assets/images/default-logo.png';
import { getBranding } from '../../services/api/management-office';
import i18next from 'i18next';

/**
 * AppContext
 * @description Context for global app data
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const AppContext = createContext<AppContextProps>({
  dispatch: () => null,
});

/**
 * AppContextProvider component
 * @description Provider for the AppContext
 *
 * @author Oleksii Medvediev
 * @category Context Providers
 */
const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const browserLanguage = window?.navigator?.language?.split('-')[0];
  const [isBrandingFetched, setIsBrandingFetched] = useState(false);

  const changeLanguage = useCallback(
    (language?: string) => {
      i18next.changeLanguage(
        Object.values(AvailableLocales).some((locale) => locale === language) ? language : browserLanguage,
      );
    },
    [browserLanguage],
  );

  /**
   * Fetch branding
   */
  const fetchBranding = useCallback(async () => {
    if (!state.isLoading && !isBrandingFetched && !state.branding) {
      dispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        setIsBrandingFetched(true);
        const { data } = await getBranding();

        data && dispatch({ type: 'SET_BRANDING', payload: data });
      } catch (error) {
        console.error(error);
      }

      dispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [isBrandingFetched, state.branding, state.isLoading]);

  useEffect(() => {
    fetchBranding();
  }, [fetchBranding]);

  useEffect(() => {
    const cachedUser = Cache.getItem(CacheQuery.USER);

    if (!state.user && cachedUser) {
      dispatch({ type: 'SET_USER', payload: cachedUser });
    }

    changeLanguage(state.user?.preferences?.language ?? browserLanguage);
  }, [browserLanguage, changeLanguage, state.user]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const link = document?.querySelector('link[rel="icon"]');

      if (link && state.branding?.logo) {
        link.setAttribute('href', state.branding?.logo);
      } else if (link) {
        link.setAttribute('href', DefaultLogo);
      }
    }
  }, [state.branding?.logo]);

  return <AppContext.Provider value={{ ...state, dispatch }}>{children}</AppContext.Provider>;
};

/**
 * useAppContext hook.
 * @description The hook for getting AppContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useAppContext = () => useContext(AppContext);

export { AppContextProvider, useAppContext };
