import { FC, useLayoutEffect, useRef, useState } from 'react';

import { DropdownMenu } from './components/dropdown-menu';
import { DropdownProps } from './dropdown.types';
import { Portal } from '../portal';
import classNames from 'classnames';
import styles from './dropdown.module.scss';
import { useClickOutside } from '../../hooks';

/**
 * Dropdown component
 * @description Dropdown complete element
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { DropdownProps } props - DropdownProps defined in the './dropdown.types.ts'
 */
const Dropdown: FC<DropdownProps> = ({ button, isHeightLimited = true, ...menuProps }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [coordinates, setCoordinates] = useState<Record<'x' | 'y', number | undefined>>();
  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownMenuRef, () => {
    setCoordinates(undefined);
    setIsOpened(false);
  });

  useLayoutEffect(() => {
    if (dropdownMenuRef.current && menuRef.current && coordinates && coordinates.x && coordinates.y) {
      const menuHeight = dropdownMenuRef.current.getBoundingClientRect().height;
      const menuWidth = dropdownMenuRef.current.getBoundingClientRect().width;

      const top = coordinates.y + menuRef.current?.getBoundingClientRect().height + 8.5;
      const left = coordinates.x;

      const shouldAvoidBottomRightCorner =
        top + menuHeight > window?.innerHeight && left + menuWidth > window?.innerWidth;
      const shouldAvoidBottom = top + menuHeight > window?.innerHeight;
      const shouldAvoidRight = left + menuWidth > window?.innerWidth;

      if (shouldAvoidBottomRightCorner) {
        dropdownMenuRef.current.style.top = `${
          top - menuHeight - menuRef.current.getBoundingClientRect().height - 8.5
        }px`;
        dropdownMenuRef.current.style.left = `${left - menuWidth}px`;
      } else if (shouldAvoidBottom) {
        dropdownMenuRef.current.style.top = `${
          top - menuHeight - menuRef.current.getBoundingClientRect().height - 8.5
        }px`;
        dropdownMenuRef.current.style.left = `${left}px`;
      } else if (shouldAvoidRight) {
        dropdownMenuRef.current.style.top = `${top}px`;
        dropdownMenuRef.current.style.left = `${left - menuWidth}px`;
      } else {
        dropdownMenuRef.current.style.top = `${top}px`;
        dropdownMenuRef.current.style.left = `${left}px`;
      }
    }
  }, [coordinates]);

  return (
    <>
      <div className={styles.wrapper} ref={menuRef}>
        {{
          ...button,
          props: {
            ...button.props,
            style: { cursor: 'pointer' },
            onClick: () => {
              setCoordinates({
                x: menuRef.current?.getBoundingClientRect().left,
                y: menuRef.current?.getBoundingClientRect().top,
              });
              setIsOpened(!isOpened);
            },
          },
        }}
      </div>
      <Portal domNode={'dropdown-root'}>
        <div
          ref={dropdownMenuRef}
          className={classNames(
            styles.dropdownMenu,
            !menuRef.current || !dropdownMenuRef.current ? styles.noVisible : styles.isVisible,
            isHeightLimited && styles.heightLimited,
          )}
        >
          {dropdownMenuRef.current && menuRef.current && (
            <DropdownMenu
              {...menuProps}
              isOpened={isOpened}
              onChange={(value) => {
                menuProps.onChange(value);
                !menuProps.multiple && setIsOpened(false);
              }}
            />
          )}
        </div>
      </Portal>
    </>
  );
};

export { Dropdown };
