import { FC } from 'react';
import { HeaderLargeProps } from './investor-header-large.types';
import { IconedButton } from '../../../iconed-button';
import { Typography } from '../../../typography';
import classNames from 'classnames';
import styles from './investor-header-large.module.scss';

const HeaderLarge: FC<HeaderLargeProps> = ({ label, topControl: { icon, onClick }, bottomControl }) => (
  <div className={classNames(styles.wrapper, !label && styles.withoutBottomRow)}>
    <div className={classNames(styles.row, styles.topRow)}>
      <IconedButton
        icon={icon}
        className={styles.icon}
        size={'small'}
        variant={'borderless'}
        onClick={onClick}
        ignoreSVGColor={false}
      />
    </div>
    <div className={styles.row}>
      {label && <Typography.Text variant={'h4'}>{label}</Typography.Text>}
      {bottomControl && (
        <IconedButton
          icon={bottomControl.icon}
          size={'small'}
          variant={'borderless'}
          onClick={bottomControl.onClick}
          ignoreSVGColor={false}
        />
      )}
    </div>
  </div>
);
export { HeaderLarge };
