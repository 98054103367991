import { Action, ModalsState } from './modals.types';

/**
 * @description InitialState for the ModalsContext
 *
 * @author Oleksii Medvediev
 */
const initialState: ModalsState = {
  data: {},
};

/**
 * @description Reducer function for the useReducer hook in the ModalsContextProvider component.
 *
 * @author Oleksii Medvediev
 * @param {ModalsState} state - State defined in the './Modals.types.ts'
 * @param {Action} action - Action defined in the './Modals.types.ts'
 * @returns updated ModalsContextProvider state.
 */
const modalsReducer = (state: ModalsState, { type, payload }: Action): ModalsState => {
  switch (type) {
    case 'SHOW_MODAL':
      return {
        activeModals: [...(state.activeModals ?? []), payload.id],
        data: {
          ...state.data,
          ...payload.data,
        },
      };

    case 'HIDE_MODAL':
      return {
        activeModals: (state.activeModals ?? []).filter((id) => id !== payload),
        data: {
          ...state.data,
          [payload]: undefined,
        },
      };

    case 'UPDATE_MODAL_DATA': {
      return state.activeModals?.some((item) => item === payload.id)
        ? {
            ...state,
            data: {
              ...state.data,
              ...payload.data,
            },
          }
        : state;
    }
    default:
      return state;
  }
};

export { initialState, modalsReducer };
