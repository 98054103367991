import { Bar, ComposedChart, Line, ReferenceDot, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import React, { FC } from 'react';

import { LegendTemplate } from '../legend-template';
import { Preloader } from '../preloader';
import { SummariesChartProps } from './summaries-chart.types';
import styles from './summaries-chart.module.scss';
import { useSummariesChartHook } from './summaries-chart.hook';

/**
 * Tabs component
 * @description Summaries chart
 *
 * @author Vitalii Bodnarchuk
 * @category Components
 * @param { SummariesChartProps } SummariesChartProps - SummariesChartProps defined in the './summaries-chart.types.ts'
 */
const SummariesChart: FC<SummariesChartProps> = ({ rawData, frame }) => {
  const { t, chartData, color, profitabilityEnd } = useSummariesChartHook(rawData, frame);

  if (!chartData) return <Preloader />;
  return (
    <div className={styles.summariesChart}>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <ComposedChart data={chartData} syncId={'summaries-sync'}>
          <YAxis
            yAxisId={'leftY'}
            tickCount={6}
            dataKey={'capital'}
            strokeDasharray={2}
            strokeWidth={'1px'}
            interval={'preserveStartEnd'}
            width={75}
            // domain={[(min: number) => min * 0.9, (max: number) => max * 1.1]}
            domain={['auto', 'auto']}
            tickFormatter={(tick) => {
              return '$' + parseInt(tick.toFixed()).toLocaleString('uk-UK');
            }}
          />
          <YAxis
            tickCount={6}
            // domain={[(min: number) => min * (min < 0 ? 1.2 : 0.8), (max: number) => max * (max < 0 ? 0.8 : 1.2)]}
            domain={['auto', 'auto']}
            yAxisId={'rightY'}
            dataKey={'profitability'}
            orientation={'right'}
            interval={'preserveStartEnd'}
            strokeDasharray={2}
            strokeWidth={'1px'}
            tickFormatter={(tick) => {
              return `${(tick * 100).toFixed(1)}%`;
            }}
          />
          <XAxis interval={1} tick={{ fill: '#939396' }} dataKey="name" stroke={'#28282e'} />

          {/* Capital bars */}
          <Bar
            stackId={'capitalBars'}
            yAxisId={'leftY'}
            dataKey={'capital'}
            barSize={12}
            fill={'#e1ffef'}
            radius={[1, 1, 0, 0]}
          />

          {/* Projection profitability line */}
          <Line
            connectNulls
            yAxisId={'rightY'}
            dataKey={'projProfitability'}
            stroke={'#fff'}
            strokeWidth={1}
            strokeDasharray={2}
          />

          {/* Profitability line */}
          <Line connectNulls yAxisId={'rightY'} dataKey={'profitability'} stroke={color && color} strokeWidth={2} />

          {/* Projection capital bars */}
          <Bar
            stackId={'capitalBars'}
            yAxisId={'leftY'}
            dataKey={'projCapital'}
            barSize={12}
            fill={'#28282e'}
            stroke={'#939396'}
            strokeDasharray={2}
            strokeWidth={'1px'}
          />

          {/* Profitability end of the line */}
          <ReferenceDot
            yAxisId={'rightY'}
            x={profitabilityEnd?.x}
            y={profitabilityEnd?.y}
            stroke={color}
            strokeWidth={'4px'}
            r={5}
          />

          {/* Dividing lines */}
          {chartData?.map((item, i) => {
            return (
              <ReferenceLine
                key={`${i}-refline`}
                yAxisId="rightY"
                x={`${i}-refline`}
                strokeWidth={'1px'}
                stroke={'#fff'}
                opacity={0.6}
                strokeDasharray={'2'}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
      <LegendTemplate
        firstValueColor={color}
        valueFirst={t('charts.profitability') as string}
        valueSecond={t('dashboard.content.summaries.chart.profit') as string}
        valueThird={t('charts.projection') as string}
      />
    </div>
  );
};

export { SummariesChart };
