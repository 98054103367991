import { Form as FormComponent, Formik } from 'formik';
import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { FormProps } from './form.types';
import { Input } from '../../../../../../../input';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import { capitalize } from 'lodash';
import styles from './form.module.scss';

/**
 * Form component
 * @description Content for the PortfoliosSettings flow Edit stage form
 *
 * @author Oleksii Medvediev
 * @param { FormProps } props - FormProps defined in the './form.types.ts'
 * @category Components
 */
const Form: FC<FormProps> = ({
  initialValues,
  onBackButtonClick,
  onClose,
  onSubmitStage,
  t,
  currentCapital,
  validationSchema,
}: FormProps) => (
  <Modal
    modalId={ModalIds.portfoliosSettingsEdit}
    title={capitalize(t('modals.portfoliosSettings.edit.form.title'))}
    onBackButtonClick={onBackButtonClick}
  >
    <div className={styles.wrapper}>
      <div className={styles.subtitleRow}>
        <Typography.Text variant={'label2'}>{t('modals.portfoliosSettings.edit.form.subtitle')}</Typography.Text>
        <Typography.Number currency value={currentCapital} variant={'h3'} />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        onSubmit={onSubmitStage}
      >
        {({ setFieldValue, setFieldTouched, errors, touched, values }) => (
          <FormComponent>
            <div className={styles.inputFields}>
              <Input
                id={'owner'}
                label={capitalize(t('modals.portfoliosSettings.edit.form.owner'))}
                type={'text'}
                onBlur={() => setFieldTouched('owner', true)}
                onChange={(value) => setFieldValue('owner', value)}
                error={errors.owner && touched.owner ? errors.owner : undefined}
                defaultValue={initialValues.owner}
              />
              <Input
                id={'email'}
                label={capitalize(t('modals.portfoliosSettings.edit.form.ownerEmail'))}
                type={'text'}
                onChange={(value) => setFieldValue('ownerEmail', value)}
                error={errors.ownerEmail && touched.ownerEmail ? errors.ownerEmail : undefined}
                defaultValue={initialValues.ownerEmail}
              />
            </div>
            <div className={styles.controls}>
              <Button variant={'outline'} size={'large'} onClick={onClose}>
                <Typography.Text variant={'bodyText1'}>
                  {capitalize(t('modals.portfoliosSettings.edit.form.cancel'))}
                </Typography.Text>
              </Button>
              <Button
                variant={'main'}
                size={'large'}
                type={'submit'}
                disabled={!!Object.values(errors).length || !Object.values(values).every((value) => !!value)}
              >
                {capitalize(t('modals.portfoliosSettings.edit.form.save'))}
              </Button>
            </div>
          </FormComponent>
        )}
      </Formik>
    </div>
  </Modal>
);

export { Form };
