import * as yup from 'yup';

import { FormProps, PaymentSettingsValues } from './form.types';
import { ManagerP2_3_4_setPayload, getAccountFees, setAccountFees } from '../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { NewParticipantSetupStages } from '../../../new-participant-setup-flow/new-participant-setup-flow.types';
import { UserRole } from '../../../../../../contexts/app/app.types';
import { useTranslation } from 'react-i18next';

/**
 * useFormData hook
 * @description The hook which processes PaymentSettings flow Form stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useFormData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { paymentSettingsForm },
    dispatch,
  } = useModalsContext();
  const { currentRole, user, dispatch: appDispatch } = useAppContext();
  const [paymentSettingsInitialValues, setPaymentSettingsInitialValues] = useState<PaymentSettingsValues>();

  const flowData: FormProps | undefined = useMemo(() => paymentSettingsForm, [paymentSettingsForm]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.paymentSettingsForm });
  }, [dispatch]);

  const fetchPaymentSettingsInitialValues = useCallback(async () => {
    appDispatch({ type: 'TOGGLE_IS_LOADING' });

    if (flowData && user && currentRole) {
      try {
        const { data } = await getAccountFees({
          acctId: flowData?.accountId,
          role: currentRole,
          roleLoginEmail: user?.email,
        });

        if (data) {
          setPaymentSettingsInitialValues({
            HighWaterMark: data.HighWaterMark,
            ManagementFee: data.ManagementFee * 100,
            ManagementFrequency: data.ManagementFrequency,
            PerformanceFee: data.PerformanceFee * 100,
            PerformanceFrequency: data.PerformanceFrequency,
            Retrospective: '',
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    appDispatch({ type: 'TOGGLE_IS_LOADING' });
  }, [appDispatch, flowData, user, currentRole]);

  useEffect(() => {
    flowData?.shouldNotFetchDefaultValues
      ? setPaymentSettingsInitialValues({
          HighWaterMark: 0,
          ManagementFee: 0,
          ManagementFrequency: t('modals.paymentSettings.form.inputs.periodManagementFee.quarterly.id'),
          PerformanceFee: 0,
          PerformanceFrequency: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.quarterly.id'),
          Retrospective: '',
        })
      : fetchPaymentSettingsInitialValues();
  }, [fetchPaymentSettingsInitialValues, flowData?.shouldNotFetchDefaultValues, t]);

  const handleSubmitForm = useCallback(
    async (values: PaymentSettingsValues) => {
      if (flowData?.onSubmitNewParticipantSetupFlowStage) {
        flowData.onSubmitNewParticipantSetupFlowStage(values, NewParticipantSetupStages.portfolios);
        handleCloseModal();
      } else if (user && flowData) {
        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        try {
          const feesDoc: ManagerP2_3_4_setPayload['feesDoc'] = {
            AccountID: flowData.accountId,
            ClientEmailOwner: flowData.clientEmailOwner,
            ClientNameOwner: flowData.clientNameOwner,
            HighWaterMark: values.HighWaterMark,
            ManagementFee: values.ManagementFee,
            ManagementFrequency: values.ManagementFrequency,
            PerformanceFee: values.PerformanceFee,
            PerformanceFrequency: values.PerformanceFrequency,
          };

          const { message } = await setAccountFees({
            roleLoginEmail: user.email,
            role: UserRole.manager,
            feesDoc,
          });

          if (message === 'ok') {
            flowData.onRefreshParticipants();

            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.paymentSettingsSuccess,
                data: {
                  paymentSettingsSuccess: {
                    onSubmitSuccess: () => {
                      dispatch({ type: 'HIDE_MODAL', payload: ModalIds.paymentSettingsSuccess });
                    },
                  },
                },
              },
            });
          }
        } catch (error) {
          console.error(error);
        }

        appDispatch({ type: 'TOGGLE_IS_LOADING' });
        handleCloseModal();
      }
    },
    [appDispatch, dispatch, flowData, handleCloseModal, user],
  );

  const validationSchema: yup.Schema<PaymentSettingsValues> = yup.object({
    ManagementFee: yup
      .number()
      .min(0, t('modals.paymentSettings.form.inputs.managementFee.errors.limit'))
      .max(100, t('modals.paymentSettings.form.inputs.managementFee.errors.limit'))
      .required(t('modals.paymentSettings.form.inputs.managementFee.errors.required')),
    ManagementFrequency: yup
      .string()
      .required(t('modals.paymentSettings.form.inputs.periodManagementFee.errors.required')),
    PerformanceFee: yup
      .number()
      .min(0, t('modals.paymentSettings.form.inputs.performanceFee.errors.limit'))
      .max(100, t('modals.paymentSettings.form.inputs.performanceFee.errors.limit'))
      .required(t('modals.paymentSettings.form.inputs.performanceFee.errors.required')),
    PerformanceFrequency: yup
      .string()
      .required(t('modals.paymentSettings.form.inputs.periodPerfomanceFee.errors.required')),
    HighWaterMark: yup.number().required(t('modals.paymentSettings.form.inputs.highWaterMark.errors.required')),
    Retrospective: yup.string(),
  });

  return {
    t,
    validationSchema,
    flowData,
    paymentSettingsInitialValues,
    handleSubmitForm,
  };
};

export { useFormData };
