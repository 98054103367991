import {
  Branding,
  GetPortfolioChartPayload,
  GetPortfolioChartResponse,
  GetPortfolioFramesResponse,
  GetPortfolioIncomeF1Payload,
  GetPortfolioIncomeF1Response,
  GetPortfolioIncomeF2Payload,
  GetPortfolioIncomeF2Response,
  GetTickerShortInfoPayload,
  GetTickerShortInfoResponse,
  SendReportPayload,
} from './management-office.model';

import { request } from '../../http';

export const getTickerShortInfo = (params: GetTickerShortInfoPayload) =>
  request<{ readonly data?: GetTickerShortInfoResponse }>({
    method: 'get',
    url: 'office/trader/ticker-short-info',
    params,
  });

export const getPortfolioIncomeF1 = ({ acctId, portfolioId, limitCount }: GetPortfolioIncomeF1Payload) =>
  request<{ readonly data: GetPortfolioIncomeF1Response }>({
    method: 'get',
    url: `office/manager/p2_2_1_3_f1?acctId=${acctId}&portfolioId=${portfolioId}&limitCount=${limitCount}`,
  });

export const getPortfolioIncomeF2 = ({ acctId, portfolioId }: GetPortfolioIncomeF2Payload) =>
  request<{ readonly data: GetPortfolioIncomeF2Response }>({
    method: 'get',
    url: `office/manager/p2_2_1_3_f2?acctId=${acctId}&portfolioId=${portfolioId}`,
  });

export const getPortfolioChart = ({ accountId, portfolioId, period }: GetPortfolioChartPayload) =>
  request<{ readonly data: GetPortfolioChartResponse }>({
    method: 'get',
    url: `office/manager/get-portfolio-chart?accountId=${accountId}&portfolioId=${portfolioId}&period=${period}`,
  });

export const getFrames = () =>
  request<{ readonly data: GetPortfolioFramesResponse }>({
    method: 'get',
    url: 'office/manager/get-frames',
  });

export const sendReport = (payload: SendReportPayload) =>
  request({ method: 'post', data: payload, url: 'office/manager/report/send' });

/**
 * Get Branding settings
 */
export const getBranding = () =>
  request<{ readonly data?: Branding }>({
    method: 'get',
    url: 'office/admin/branding',
  });
