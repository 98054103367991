import './styles/global.scss';

import { FC, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';

import { AuthRouter } from './pages';
import { ReactComponent as BgImg } from '../assets/images/bg-img.svg';
import { Cache } from 'aws-amplify';
import { CacheQuery } from './shared';
import { Preloader } from './components';
import styles from './app.module.scss';
import { useAppContext } from './context';

const App: FC = () => {
  const { isLoading, headerContent } = useAppContext();
  const { pathname } = useLocation();
  const [vh, setVh] = useState<number>(window?.innerHeight ?? 0);

  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!pathname.includes('auth/')) {
      Cache.removeItem(CacheQuery.PASSWORD_RECOVERY_RESEND_COUNT);
      Cache.removeItem(CacheQuery.PASSWORD_RECOVERY_TIMER);
      Cache.removeItem(CacheQuery.TWO_FA_RESEND_COUNT);
      Cache.removeItem(CacheQuery.TWO_FA_TIMER);
    }

    window.addEventListener('resize', () => {
      window?.innerHeight && setVh(window.innerHeight);
    });
  }, [pathname]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.background} />
      <BgImg className={styles.bgImg} />
      <Preloader type={'overlay'} isActive={isLoading} />
      {headerContent && (
        <div ref={headerRef} className={styles.appHeader}>
          {headerContent}
        </div>
      )}
      <div
        ref={contentRef}
        style={{
          maxHeight: `calc(${vh}px - ${headerRef.current?.getBoundingClientRect().height ?? 0}px)`,
          minHeight: `calc(${vh}px - ${headerRef.current?.getBoundingClientRect().height ?? 0}px)`,
        }}
        className={styles.content}
      >
        <Routes>
          <Route path={'*'} element={<AuthRouter />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
