import * as yup from 'yup';

import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useMemo } from 'react';

import { FormProps } from './form.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { UserRole } from '../../../../../../contexts/app/app.types';
import { setManagerParticipantsPortfolioWithdraw } from '../../../../../../services/api';
import { useTranslation } from 'react-i18next';

const initialValues = {
  withdrawAmount: '',
};

/**
 * useFormData hook
 * @description The hook which processes WithdrawPortfolio flow Form stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useFormData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { withdrawPortfolioForm },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();

  const validationSchema: yup.Schema<typeof initialValues> = yup.object({
    withdrawAmount: yup.string().required(t('modals.withdrawPortfolio.form.required')),
  });

  const flowData: FormProps | undefined = useMemo(() => withdrawPortfolioForm, [withdrawPortfolioForm]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.withdrawPortfolioForm }),
    [dispatch],
  );

  const handleSubmitForm = useCallback(
    async ({ withdrawAmount }: typeof initialValues) => {
      if (user && flowData) {
        if (
          (+withdrawAmount.replaceAll(' ', '')).toFixed(0) !== flowData.portfolio.PortfolioCurrentCapital.toFixed(0)
        ) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });

          try {
            const { message } = await setManagerParticipantsPortfolioWithdraw({
              sumWithdraw: +withdrawAmount.replaceAll(' ', ''),
              roleLoginEmail: user.email,
              role: UserRole.manager,
              acctId: flowData.portfolio.PortfolioOnAccountID,
              portfolioId: flowData.portfolio.PortfolioID,
            });

            if (message === 'ok') {
              flowData.onRefreshParticipants();

              dispatch({
                type: 'SHOW_MODAL',
                payload: {
                  id: ModalIds.withdrawPortfolioSuccess,
                  data: {
                    withdrawPortfolioSuccess: {
                      onSubmitSuccess: () => {
                        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.withdrawPortfolioSuccess });
                      },
                    },
                  },
                },
              });
            }
          } catch (error) {
            console.error(error);
          }

          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        } else {
          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              id: ModalIds.withdrawPortfolioTotalWithdraw,
              data: {
                withdrawPortfolioTotalWithdraw: flowData,
              },
            },
          });
        }
        handleCloseModal();
      }
    },
    [appDispatch, dispatch, flowData, handleCloseModal, user],
  );

  return {
    t,
    flowData,
    initialValues,
    validationSchema,
    handleSubmitForm,
  };
};

export { useFormData };
