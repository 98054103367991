import { Recommendation } from '../../../../../../services/api/trader/trader.model';

/**
 * CancelProps
 * @description Props for the Cancel component
 *
 * @author Oleksii Medvediev
 */
type CancelProps = {
  readonly currentStage: CancelStages;
  readonly recommendations: ReadonlyArray<Recommendation>;
  readonly onSubmitCancel: () => void;
};

enum CancelStages {
  summary = 'summary',
  confirmation = 'confirmation',
}

export type { CancelProps };
export { CancelStages };
