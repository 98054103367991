import {
  ConnectionIndicator,
  MessagesIndicator,
  PriorityIndicator,
  RecommendationPriorityIndicator,
} from './components';

const Indicator = {
  ConnectionIndicator,
  MessagesIndicator,
  PriorityIndicator,
  RecommendationPriorityIndicator,
};

export { Indicator };
