import { FC } from 'react';
import { NotificationItem } from './components';
import { NotificationsProps } from './notifications.types';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg';
import { Typography } from '../../..';
import styles from './notifications.module.scss';
import { useNotificationsData } from './notifications.hook';

/**
 * Notifications component
 * @description Notifications
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { NotificationsProps } props - NotificationsProps defined in the './notifications.types.ts'
 */
const Notifications: FC<NotificationsProps> = ({
  notifications,
  portfolioName,
  locallyReadNotificationsIds,
  onRefetchNotifications,
}) => {
  const { t, groupedNotifications } = useNotificationsData(notifications);

  return (
    <div className={styles.wrapper}>
      {groupedNotifications && !!Object.keys(groupedNotifications).length ? (
        Object.keys(groupedNotifications)
          .reverse()
          .map((group) =>
            Object.values(groupedNotifications[group]).length ? (
              <div key={group} className={styles.message}>
                <Typography.Text variant={'label2'} className={styles.labelDate}>
                  {group.includes('hours') ? t('timeGroups.today') : group}
                </Typography.Text>
                {groupedNotifications[group].map((notification, index) => (
                  <NotificationItem
                    {...notification}
                    isLocallyRead={locallyReadNotificationsIds.some((id) => id === notification.UUID)}
                    onRefetchNotifications={onRefetchNotifications}
                    portfolioName={portfolioName}
                    index={index}
                    key={notification.UUID}
                  />
                ))}
              </div>
            ) : undefined,
          )
      ) : (
        <div className={styles.emptyPage}>
          <div className={styles.header}>
            <SearchIcon />
            <Typography.Text variant={'h5'} className={styles.title}>
              {t('emptyPage.title')}
            </Typography.Text>
          </div>
          <Typography.Text variant={'bodyText3'} className={styles.desc}>
            {t('emptyPage.description')}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export { Notifications };
