import { Form as FormComponent, Formik } from 'formik';
import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { ReactComponent as ChevronDown } from '../../../../../../../assets/icons/chevron-down.svg';
import { Dropdown } from '../../../../../dropdown';
import { Input } from '../../../../../input';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import { capitalize } from 'lodash';
import styles from './form.module.scss';
import { useFormData } from './form.hook';

/**
 * Form component
 * @description Content for the transferBetweenPortfolios flow Form stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-160580&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Form: FC = () => {
  const { t, flowData, initialValues, validationSchema, handleSubmitForm } = useFormData();

  return (
    <Modal modalId={ModalIds.transferBetweenPortfoliosForm} title={t('modals.transferBetweenPortfolios.form.title')}>
      {flowData && (
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <Typography.Text variant={'label2'} className={styles.label}>
              {t('modals.transferBetweenPortfolios.form.fromLabel')}
            </Typography.Text>
            <Typography.Text variant={'h3'}>{flowData.outputPortfolio.PortfolioName}</Typography.Text>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
            validateOnChange
          >
            {({ setFieldValue, errors, touched, setFieldTouched, isValid, values: { sumTransfer, toPortfolioId } }) => (
              <FormComponent className={styles.formWrapper} autoComplete={'off'}>
                <div className={styles.fields}>
                  <Input
                    id={'selectedPortfolioId'}
                    label={t('modals.transferBetweenPortfolios.form.targetPortfolio') as string}
                    type={'text'}
                    onChange={() => null}
                    value={flowData.inputPortfolios.find(({ id }) => id === toPortfolioId)?.name}
                    readOnly
                    tailingIcon={
                      <Dropdown
                        button={<ChevronDown className={styles.chevron} />}
                        options={flowData?.inputPortfolios.map(({ id, name }) => ({ id, value: name }))}
                        onChange={(value) => {
                          setFieldValue('toPortfolioId', Array.isArray(value) ? value[0] : value);
                        }}
                      />
                    }
                    onBlur={() => setFieldTouched('toPortfolioId', true)}
                  />
                  <Input
                    id={'sumTransfer'}
                    label={t('modals.transferBetweenPortfolios.form.sumTransfer')}
                    type={'text'}
                    isCurrency={true}
                    value={sumTransfer}
                    onChange={(value) => {
                      setFieldValue('sumTransfer', !!+value.replaceAll(' ', '') ? value : null);
                    }}
                    onBlur={() => setFieldTouched('sumTransfer', true)}
                    error={
                      sumTransfer &&
                      parseFloat(sumTransfer.toString().replace(/[^0-9.-]+/g, '')) >
                        Math.ceil(flowData.outputPortfolio.PortfolioCurrentCapital)
                        ? t('modals.transferBetweenPortfolios.form.insufficient')
                        : errors.sumTransfer && touched.sumTransfer
                        ? errors.sumTransfer
                        : undefined
                    }
                  />
                </div>
                <Button
                  size={'large'}
                  variant={'main'}
                  type={'submit'}
                  disabled={
                    !flowData ||
                    !isValid ||
                    !sumTransfer ||
                    !!errors.sumTransfer ||
                    parseFloat(sumTransfer.toString().replace(/[^0-9.-]+/g, '')) >
                      Math.ceil(flowData.outputPortfolio.PortfolioCurrentCapital)
                  }
                  className={styles.saveButton}
                >
                  <Typography.Text variant={'bodyText1'}>
                    {capitalize(t('modals.transferBetweenPortfolios.form.continue'))}
                  </Typography.Text>
                </Button>
              </FormComponent>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export { Form };
