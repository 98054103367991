import { useEffect, useState } from 'react';

import { Notification } from '../../../../services/api/client-office';
import { NotificationsProps } from './notifications.types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * useNotificationsData hook
 * @description The hook which processes Notifications page data
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Hooks
 */
const useNotificationsData = (notifications: NotificationsProps['notifications']) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'client.pages.dashboard.notifications-drawer.notifications' });
  const [groupedNotifications, setGroupedNotifications] = useState<Record<string, Array<Notification>>>();

  useEffect(() => {
    const groups: Record<string, Array<Notification>> = {};

    notifications?.forEach((notification) => {
      // If there is a requirement for grouping dates with words like "Today, Yesterday, 2 days ago, 3 days ago...",
      // check one of the previous commits. There is a solution.

      const date = new Date(notification.Date);
      const group = dayjs(date).format('DD/MM/YYYY');

      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].unshift(notification);
    });

    setGroupedNotifications(groups);
  }, [notifications, t]);

  return {
    t,
    groupedNotifications,
  };
};

export { useNotificationsData };
