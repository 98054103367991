import React, { FC } from 'react';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { CheckboxProps } from './checkbox.types';
import classNames from 'classnames';
import styles from './checkbox.module.scss';
import { v4 } from 'uuid';

/**
 * Checkbox component.
 * @description Checkbox component for entire app usage.
 *
 * @author Oleksii Medvediev
 * @category Components.
 * @param { CheckboxProps } props - CheckboxProps defined in the './checkbox.types.ts'
 */
const Checkbox: FC<CheckboxProps> = ({ isChecked, disabled, onChange }: CheckboxProps) => {
  const id = v4();

  return (
    <div
      className={styles.wrapper}
      role={'button'}
      tabIndex={0}
      onKeyDown={(event) => event.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <input hidden type={'checkbox'} id={id} onChange={() => onChange()} checked={isChecked} disabled={disabled} />
      <label htmlFor={id} className={classNames(styles.inputLabel, isChecked && styles.checked)}>
        <span className={classNames(styles.checkIcon, !isChecked && styles.invisibleCheck)}>
          <CheckIcon />
        </span>
      </label>
    </div>
  );
};

export { Checkbox };
