import { AccountOrdersDeclinationProps } from '../../components/modals/components/account-orders-declination/account-orders-declination.types';
import { AutomatedOrdersConfirmationFlowProps } from '../../components/modals/flows/automated-orders-confirmation-flow/automated-orders-confirmation-flow.types';
import { ChangeStrategyFlowProps } from '../../components/modals/flows/change-strategy-flow/change-strategy-flow.types';
import { CloseAllPositionsFlowProps } from '../../components/modals/flows/close-all-positions-flow/close-all-positions-flow.types';
import { ClosePortfolioFlowProps } from '../../components/modals/flows/close-portfolio-flow/close-portfolio-flow.types';
import { DatePickerModalProps } from '../../components/modals/components/date-picker-modal/date-picker-modal.types';
import { DateRangePickerModalProps } from '../../components/modals/components/date-range-picker-modal/date-range-picker-modal.types';
import { Dispatch } from 'react';
import { InvoiceCanceledModalProps } from '../../components/modals/components/invoice-canceled-modal/invoice-canceled-modal.types';
import { InvoiceCreatedModalProps } from '../../components/modals/components/invoice-created-modal/invoice-created-modal.types';
import { InvoiceDownloadedModalProps } from '../../components/modals/components/invoice-downloaded-modal/invoice-downloaded-modal.types';
import { LoginRequestProps } from '../../components/modals/components/login-request/login-request.types';
import { MonthlyCalendarModalProps } from '../../components/modals/components/monthly-calendar-modal/monthly-calendar-modal.types';
import { NewParticipantSetupFlowProps } from '../../components/modals/flows/new-participant-setup-flow/new-participant-setup-flow.types';
import { OrderAcceptationProps } from '../../components/modals/components/order-acceptation/order-acceptation.types';
import { OrderDeclinationProps } from '../../components/modals/components/order-declination/order-declination.types';
import { OrderFlowProps } from '../../components/modals/flows/order-flow/order-flow.types';
import { PaymentSettingsFlowProps } from '../../components/modals/flows/payment-settings-flow/payment-settings-flow.types';
import { PersonConfirmationModalProps } from '../../components/modals/components/person-confirmation/person-confirmation.types';
import { PortfoliosSettingsFlowProps } from '../../components/modals/flows/portfolios-settings-flow/portfolios-settings-flow.types';
import { RenamePortfolioFlowProps } from '../../components/modals/flows/rename-portfolio-flow/rename-portfolio-flow.types';
import { ReplenishPortfolioFlowProps } from '../../components/modals/flows/replenish-portfolio-flow/replenish-portfolio-flow.types';
import { ReportsFlowProps } from '../../components/modals/flows/reports-flow/reports-flow.types';
import { SecuritySettingsChangeErrorProps } from '../../components/modals/components/security-settings-change-error/security-settings-change-error.types';
import { SettingsConnectionModalProps } from '../../components/modals/components/settings-connection-modal/settings-connection-modal.types';
import { TransferBetweenPortfoliosFlowProps } from '../../components/modals/flows/transfer-between-portfolios-flow/transfer-between-portfolios-flow.types';
import { TwoFaChangedProps } from '../../components/modals/components/two-fa-changed/two-fa-changed.types';
import { UsersManagementFlowProps } from '../../components/modals/flows/users-management-flow/users-manegement-flow.types';
import { WithdrawAccountFlowProps } from '../../components/modals/flows/withdraw-account-flow/withdraw-portfolio-flow.types';
import { WithdrawPortfolioFlowProps } from '../../components/modals/flows/withdraw-portfolio-flow/withdraw-portfolio-flow.types';
import { RequestServiceActionModalProps } from '../../components/modals/components/request-service-action-modal/request-service-action-modal.types';
import { VNCModalProps } from '../../components/modals/components/vnc-modal/vnc-modal.types';

enum ModalIds {
  monthlyCalendar = 'monthlyCalendar',
  automatedOrdersConfirmationSet = 'automatedOrdersConfirmationSet',
  automatedOrdersConfirmationEdit = 'automatedOrdersConfirmationEdit',
  automatedOrdersConfirmationCancel = 'automatedOrdersConfirmationCancel',
  dateRangePicker = 'dateRangePicker',
  orderPlace = 'orderPlace',
  orderModify = 'orderModify',
  orderCancel = 'orderCancel',
  orderAcceptation = 'orderAcceptation',
  orderDeclination = 'orderDeclination',
  replenishPortfolioForm = 'replenishPortfolioForm',
  replenishPortfolioSuccess = 'replenishPortfolioSuccess',
  withdrawPortfolioForm = 'withdrawPortfolioForm',
  withdrawPortfolioSuccess = 'withdrawPortfolioSuccess',
  transferBetweenPortfoliosForm = 'transferBetweenPortfoliosForm',
  transferBetweenPortfoliosSuccess = 'transferBetweenPortfoliosSuccess',
  closePortfolioConfirmation = 'closePortfolioConfirmation',
  closePortfolioSuccess = 'closePortfolioSuccess',
  renamePortfolioForm = 'renamePortfolioForm',
  renamePortfolioSuccess = 'renamePortfolioSuccess',
  withdrawAccountForm = 'withdrawAccountForm',
  withdrawAccountSuccess = 'withdrawAccountSuccess',
  datePicker = 'datePicker',
  closeAllPositionsConfirmation = 'closeAllPositionsConfirmation',
  closeAllPositionsSuccess = 'closeAllPositionsSuccess',
  changeStrategy = 'changeStrategy',
  paymentSettingsForm = 'paymentSettingsForm',
  paymentSettingsSuccess = 'paymentSettingsSuccess',
  portfoliosSettingsSummary = 'portfoliosSettingsSummary',
  portfoliosSettingsAdd = 'portfoliosSettingsAdd',
  portfoliosSettingsEdit = 'portfoliosSettingsEdit',
  portfoliosSettingsDelete = 'portfoliosSettingsDelete',
  reportsIndividualAccount = 'reportsIndividualAccount',
  reportsIndividualPortfolio = 'reportsIndividualPortfolio',
  reportsCollectiveAccountsPortfolios = 'reportsCollectiveAccountsPortfolios',
  reportsCollectiveAccounts = 'reportsCollectiveAccounts',
  reportsCollectivePortfolios = 'reportsCollectivePortfolios',
  reportsGenerateReport = 'reportsGenerateReport',
  newParticipantSetup = 'newParticipantSetup',
  personConfirmation = 'personConfirmation',
  twoFaChanged = 'twoFaChanged',
  passwordChanged = 'passwordChanged',
  securitySettingsChangeError = 'securitySettingsChangeError',
  mailServerError = 'mailServerError',
  mailServerSuccess = 'mailServerSuccess',
  settingsConnection = 'settingsConnection',
  requestServiceAction = 'requestServiceAction',
  dataAccountError = 'dataAccountError',
  dataAccountSuccess = 'dataAccountSuccess',
  brandingError = 'brandingError',
  brandingSuccess = 'brandingSuccess',
  loginRequestGiveAccess = 'loginRequestGiveAccess',
  loginRequestChangeRole = 'loginRequestChangeRole',
  invoiceDownloaded = 'invoiceDownloaded',
  invoiceCanceled = 'invoiceCanceled',
  invoiceCreated = 'invoiceCreated',
  usersManagementInvite = 'usersManagementInvite',
  usersManagementEdit = 'usersManagementEdit',
  usersManagementDelete = 'usersManagementDelete',
  withdrawPortfolioTotalWithdraw = 'withdrawPortfolioTotalWithdraw',
  transferBetweenPortfoliosTotalTransfer = 'transferBetweenPortfoliosTotalTransfer',
  accountOrdersDeclination = 'accountOrdersDeclination',
  vnc = 'vnc',
}

/**
 * ModalsContextProps
 * @description Props for the ModalsContext
 *
 * @author Oleksii Medvediev
 */
interface ModalsContextProps extends ModalsState {
  readonly dispatch: Dispatch<Action>;
}

type ModalsState = {
  readonly activeModals?: ReadonlyArray<ModalIds>;
  readonly data: {
    readonly [ModalIds.monthlyCalendar]?: MonthlyCalendarModalProps;
    readonly [ModalIds.automatedOrdersConfirmationSet]?: AutomatedOrdersConfirmationFlowProps['setProps'];
    readonly [ModalIds.automatedOrdersConfirmationEdit]?: AutomatedOrdersConfirmationFlowProps['editProps'];
    readonly [ModalIds.automatedOrdersConfirmationCancel]?: AutomatedOrdersConfirmationFlowProps['cancelProps'];
    readonly [ModalIds.dateRangePicker]?: DateRangePickerModalProps;
    readonly [ModalIds.orderPlace]?: OrderFlowProps['placeProps'];
    readonly [ModalIds.orderModify]?: OrderFlowProps['modifyProps'];
    readonly [ModalIds.orderCancel]?: OrderFlowProps['cancelProps'];
    readonly [ModalIds.orderAcceptation]?: OrderAcceptationProps;
    readonly [ModalIds.orderDeclination]?: OrderDeclinationProps;
    readonly [ModalIds.replenishPortfolioForm]?: ReplenishPortfolioFlowProps['fromProps'];
    readonly [ModalIds.replenishPortfolioSuccess]?: ReplenishPortfolioFlowProps['successProps'];
    readonly [ModalIds.withdrawPortfolioForm]?: WithdrawPortfolioFlowProps['fromProps'];
    readonly [ModalIds.withdrawPortfolioSuccess]?: WithdrawPortfolioFlowProps['successProps'];
    readonly [ModalIds.transferBetweenPortfoliosForm]?: TransferBetweenPortfoliosFlowProps['fromProps'];
    readonly [ModalIds.transferBetweenPortfoliosSuccess]?: TransferBetweenPortfoliosFlowProps['successProps'];
    readonly [ModalIds.closePortfolioConfirmation]?: ClosePortfolioFlowProps['confirmationProps'];
    readonly [ModalIds.closePortfolioSuccess]?: ClosePortfolioFlowProps['successProps'];
    readonly [ModalIds.renamePortfolioForm]?: RenamePortfolioFlowProps['fromProps'];
    readonly [ModalIds.renamePortfolioSuccess]?: RenamePortfolioFlowProps['successProps'];
    readonly [ModalIds.withdrawAccountForm]?: WithdrawAccountFlowProps['fromProps'];
    readonly [ModalIds.withdrawAccountSuccess]?: WithdrawAccountFlowProps['successProps'];
    readonly [ModalIds.datePicker]?: DatePickerModalProps;
    readonly [ModalIds.closeAllPositionsConfirmation]?: CloseAllPositionsFlowProps['confirmationProps'];
    readonly [ModalIds.closeAllPositionsSuccess]?: CloseAllPositionsFlowProps['successProps'];
    readonly [ModalIds.changeStrategy]?: ChangeStrategyFlowProps;
    readonly [ModalIds.paymentSettingsForm]?: PaymentSettingsFlowProps['formProps'];
    readonly [ModalIds.paymentSettingsSuccess]?: PaymentSettingsFlowProps['successProps'];
    readonly [ModalIds.portfoliosSettingsSummary]?: PortfoliosSettingsFlowProps['summaryProps'];
    readonly [ModalIds.portfoliosSettingsAdd]?: PortfoliosSettingsFlowProps['addProps'];
    readonly [ModalIds.portfoliosSettingsEdit]?: PortfoliosSettingsFlowProps['editProps'];
    readonly [ModalIds.portfoliosSettingsDelete]?: PortfoliosSettingsFlowProps['deleteProps'];
    readonly [ModalIds.reportsIndividualAccount]?: ReportsFlowProps['individualAccountProps'];
    readonly [ModalIds.reportsIndividualPortfolio]?: ReportsFlowProps['individualPortfolioProps'];
    readonly [ModalIds.reportsCollectiveAccountsPortfolios]?: ReportsFlowProps['collectiveAccountsPortfoliosProps'];
    readonly [ModalIds.reportsCollectiveAccounts]?: ReportsFlowProps['collectiveAccountsProps'];
    readonly [ModalIds.reportsCollectivePortfolios]?: ReportsFlowProps['collectivePortfoliosProps'];
    readonly [ModalIds.newParticipantSetup]?: NewParticipantSetupFlowProps;
    readonly [ModalIds.personConfirmation]?: PersonConfirmationModalProps;
    readonly [ModalIds.twoFaChanged]?: TwoFaChangedProps;
    readonly [ModalIds.securitySettingsChangeError]?: SecuritySettingsChangeErrorProps;
    readonly [ModalIds.settingsConnection]?: SettingsConnectionModalProps;
    readonly [ModalIds.requestServiceAction]?: RequestServiceActionModalProps;
    readonly [ModalIds.loginRequestChangeRole]?: LoginRequestProps['changeRole'];
    readonly [ModalIds.invoiceCreated]?: InvoiceCreatedModalProps;
    readonly [ModalIds.usersManagementInvite]?: UsersManagementFlowProps['inviteProps'];
    readonly [ModalIds.usersManagementEdit]?: UsersManagementFlowProps['editProps'];
    readonly [ModalIds.usersManagementDelete]?: UsersManagementFlowProps['deleteProps'];
    readonly [ModalIds.withdrawPortfolioTotalWithdraw]?: WithdrawPortfolioFlowProps['totalWithdrawProps'];
    readonly [ModalIds.transferBetweenPortfoliosTotalTransfer]?: TransferBetweenPortfoliosFlowProps['totalTransferProps'];
    readonly [ModalIds.invoiceCanceled]?: InvoiceCanceledModalProps;
    readonly [ModalIds.invoiceDownloaded]?: InvoiceDownloadedModalProps;
    readonly [ModalIds.reportsGenerateReport]?: ReportsFlowProps['generateReportProps'];
    readonly [ModalIds.accountOrdersDeclination]?: AccountOrdersDeclinationProps;
    readonly [ModalIds.vnc]?: VNCModalProps;
  };
};

type Action =
  | {
      readonly type: 'SHOW_MODAL';
      readonly payload: {
        readonly id: ModalIds;
        readonly data?: Partial<ModalsState['data']>;
      };
    }
  | {
      readonly type: 'HIDE_MODAL';
      readonly payload: ModalIds;
    }
  | {
      readonly type: 'UPDATE_MODAL_DATA';
      readonly payload: {
        readonly id: ModalIds;
        readonly data?: Partial<ModalsState['data']>;
      };
    };

export type { ModalsContextProps, ModalsState, Action };

export { ModalIds };
