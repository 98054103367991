import React, { FC } from 'react';

import { UsersIndicatorProps } from './users-indicator.types';
import styles from './users-indicator.module.scss';
import { Typography } from '../../../typography';

/**
 * UsersIndicator component
 * @description Users count indicator
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { UsersIndicatorProps } props - UsersIndicatorProps defined in the './messages-indicator.types.ts'
 */
const UsersIndicator: FC<UsersIndicatorProps> = ({ value }) => (
  <Typography.Text variant="label4" className={styles.wrapper}>
    {value}
  </Typography.Text>
);

export { UsersIndicator };
