import {
  GetCashFlowResponse,
  GetFinancialResultResponse,
  GetNotificationsResponse,
  GetPortfolioAllocationResponse,
  GetPortfolioAssetsResponse,
  GetPortfolioPerformanceResponse,
  GetTradeEventsResponse,
} from '../../../services/api/client-office';
import {
  GetPortfolioChartResponse,
  GetPortfolioFramesResponse,
  GetPortfolioIncomeF1Response,
  GetPortfolioIncomeF2Response,
  GetTickerShortInfoResponse,
} from '../../../services/api/management-office';
import { GetPortfolioPerformanceOldResponse, GetPortfoliosResponse } from '../../../services/api/portfolio';

import { Dispatch } from 'react';

/**
 * PortfolioContextProps
 * @description Props for the PortfolioContext
 *
 * @author Oleksii Medvediev
 */
interface PortfolioContextProps extends PortfolioState {
  readonly dispatch: Dispatch<Action>;
}

type PortfolioState = {
  readonly portfolio?: GetPortfoliosResponse[number];
  readonly performanceOld?: GetPortfolioPerformanceOldResponse;
  readonly assets?: GetPortfolioAssetsResponse;
  readonly allocation?: GetPortfolioAllocationResponse;
  readonly performance?: GetPortfolioPerformanceResponse;
  readonly incomeF1?: GetPortfolioIncomeF1Response;
  readonly incomeF2?: GetPortfolioIncomeF2Response;
  readonly yieldPeriod: Frame;
  readonly portfolioChart?: GetPortfolioChartResponse;
  readonly frames?: GetPortfolioFramesResponse;
  readonly selectedYieldOption?: {
    readonly name: Frame;
    readonly navs: number;
    readonly profit: number;
  };
  readonly tickersShortInfo?: Record<GetTickerShortInfoResponse['ticker'], GetTickerShortInfoResponse>;
  readonly isNotificationDrawerOpened?: boolean;
  readonly notifications?: GetNotificationsResponse;
  readonly operations?: GetTradeEventsResponse;
  readonly cashflow?: GetCashFlowResponse;
  readonly financialResult?: GetFinancialResultResponse;
  readonly financialResultError?: string;
};

enum Frame {
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
  all = 'all',
}

type Action =
  | {
      readonly type: 'SET_PORTFOLIO';
      readonly payload: PortfolioState['portfolio'];
    }
  | {
      readonly type: 'SET_PORTFOLIO_PERFORMANCE_OLD';
      readonly payload: PortfolioState['performanceOld'];
    }
  | {
      readonly type: 'SET_PORTFOLIO_ASSETS';
      readonly payload: PortfolioState['assets'];
    }
  | {
      readonly type: 'SET_PORTFOLIO_ALLOCATION';
      readonly payload: PortfolioState['allocation'];
    }
  | {
      readonly type: 'SET_PORTFOLIO_PERFORMANCE';
      readonly payload: PortfolioState['performance'];
    }
  | {
      readonly type: 'SET_PORTFOLIO_INCOME_F1';
      readonly payload: PortfolioState['incomeF1'];
    }
  | {
      readonly type: 'SET_PORTFOLIO_INCOME_F2';
      readonly payload: PortfolioState['incomeF2'];
    }
  | {
      readonly type: 'SET_YIELD_PERIOD';
      readonly payload: PortfolioState['yieldPeriod'];
    }
  | {
      readonly type: 'SET_PORTFOLIO_CHART';
      readonly payload: PortfolioState['portfolioChart'];
    }
  | {
      readonly type: 'SET_FRAMES';
      readonly payload: PortfolioState['frames'];
    }
  | {
      readonly type: 'SET_SELECTED_YIELD_OPTION';
      readonly payload: PortfolioState['selectedYieldOption'];
    }
  | {
      readonly type: 'SET_TICKERS_SHORT_INFO';
      readonly payload: PortfolioState['tickersShortInfo'];
    }
  | { readonly type: 'OPEN_NOTIFICATION_DRAWER' }
  | { readonly type: 'CLOSE_NOTIFICATION_DRAWER' }
  | {
      readonly type: 'SET_NOTIFICATIONS';
      readonly payload: PortfolioState['notifications'];
    }
  | {
      readonly type: 'SET_TRADE_EVENTS';
      readonly payload: PortfolioState['operations'];
    }
  | {
      readonly type: 'SET_CASHFLOW';
      readonly payload: PortfolioState['cashflow'];
    }
  | {
      readonly type: 'SET_FINANCIAL_RESULT';
      readonly payload: PortfolioState['financialResult'];
    }
  | {
      readonly type: 'SET_FINANCIAL_RESULT_ERROR';
      readonly payload: PortfolioState['financialResultError'];
    };

export { Frame };
export type { Action, PortfolioContextProps, PortfolioState };
