import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { Cache } from 'aws-amplify';
import { CacheQuery } from '../../shared';
import { environment } from '../../../environment';

async function request<T>(config: AxiosRequestConfig): Promise<
  AxiosResponse<
    T & {
      readonly message: string;
      readonly status: string;
      readonly code: number;
      readonly errors?: ReadonlyArray<string>;
    }
  >['data']
> {
  try {
    const { headers, ...restConfig } = config;

    const response = await axios({
      baseURL: environment.api.base,
      method: config.method ?? 'get',
      headers: {
        ...headers,
        'local-auth': localStorage.getItem('local-auth-co'),
      },
      ...restConfig,
    });

    return response.data;
  } catch (error) {
    console.error((error as AxiosError)?.message);

    if (
      ((error as AxiosError).response?.data as { message: string })?.message === 'token expired' ||
      ((error as AxiosError).response?.data as { message: string })?.message === 'forbidden'
    ) {
      Cache.removeItem(CacheQuery.USER);
      localStorage.removeItem('local-auth-co');
      localStorage.removeItem('local-auth-mo');
      typeof window !== 'undefined' && window?.location.reload();
    }

    throw error;
  }
}

export { request };
