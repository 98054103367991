import {
  AccountOrdersDeclination,
  BrandingError,
  BrandingSuccess,
  DataAccountError,
  DataAccountSuccess,
  DatePickerModal,
  DateRangePickerModal,
  InvoiceCanceledModal,
  InvoiceCreatedModal,
  InvoiceDownloadedModal,
  LoginRequest,
  MailServerErrorModal,
  MailServerSuccessModal,
  MonthlyCalendarModal,
  OrderAcceptation,
  OrderDeclination,
  PasswordChanged,
  PersonConfirmation,
  RequestServiceActionModal,
  SecuritySettingsChangeError,
  SettingsConnectionModal,
  TwoFaChanged,
  VNCModal,
} from './components';
import {
  AutomatedOrdersConfirmationFlow,
  ChangeStrategyFlow,
  CloseAllPositionsFlow,
  ClosePortfolioFlow,
  NewParticipantSetupFlow,
  OrderFlow,
  PaymentSettingsFlow,
  PortfoliosSettingsFlow,
  RenamePortfolioFlow,
  ReplenishPortfolioFlow,
  ReportsFlow,
  TransferBetweenPortfoliosFlow,
  UsersManagementFlow,
  WithdrawAccountFlow,
  WithdrawPortfolioFlow,
} from './flows';
import React, { FC, useCallback } from 'react';

import { ModalIds } from '../../contexts/modals/modals.types';
import { useModalsContext } from '../../contexts';

/**
 * Modals component
 * @description Global modals component used to render appropriate modal bu its id
 *
 * @category Components
 */
const Modals: FC = () => {
  const { activeModals } = useModalsContext();

  const renderModal = useCallback((item: ModalIds, index: number) => {
    switch (item) {
      case ModalIds.monthlyCalendar: {
        return <MonthlyCalendarModal key={ModalIds.monthlyCalendar + index} />;
      }

      case ModalIds.automatedOrdersConfirmationSet: {
        return <AutomatedOrdersConfirmationFlow.Set key={ModalIds.automatedOrdersConfirmationSet + index} />;
      }

      case ModalIds.dateRangePicker: {
        return <DateRangePickerModal key={ModalIds.dateRangePicker + index} />;
      }

      case ModalIds.automatedOrdersConfirmationEdit: {
        return <AutomatedOrdersConfirmationFlow.Edit key={ModalIds.automatedOrdersConfirmationEdit + index} />;
      }

      case ModalIds.automatedOrdersConfirmationCancel: {
        return <AutomatedOrdersConfirmationFlow.Cancel key={ModalIds.automatedOrdersConfirmationCancel + index} />;
      }

      case ModalIds.orderPlace: {
        return <OrderFlow.Place key={ModalIds.orderPlace + index} />;
      }

      case ModalIds.orderCancel: {
        return <OrderFlow.Cancel key={ModalIds.orderCancel + index} />;
      }

      case ModalIds.orderModify: {
        return <OrderFlow.Modify key={ModalIds.orderModify + index} />;
      }

      case ModalIds.orderAcceptation: {
        return <OrderAcceptation key={ModalIds.orderAcceptation + index} />;
      }

      case ModalIds.orderDeclination: {
        return <OrderDeclination key={ModalIds.orderDeclination + index} />;
      }

      case ModalIds.replenishPortfolioForm: {
        return <ReplenishPortfolioFlow.Form key={ModalIds.replenishPortfolioForm + index} />;
      }

      case ModalIds.replenishPortfolioSuccess: {
        return <ReplenishPortfolioFlow.Success key={ModalIds.replenishPortfolioSuccess + index} />;
      }

      case ModalIds.withdrawPortfolioForm: {
        return <WithdrawPortfolioFlow.Form key={ModalIds.withdrawPortfolioForm + index} />;
      }

      case ModalIds.withdrawPortfolioSuccess: {
        return <WithdrawPortfolioFlow.Success key={ModalIds.withdrawPortfolioSuccess + index} />;
      }

      case ModalIds.transferBetweenPortfoliosForm: {
        return <TransferBetweenPortfoliosFlow.Form key={ModalIds.transferBetweenPortfoliosForm + index} />;
      }

      case ModalIds.transferBetweenPortfoliosSuccess: {
        return <TransferBetweenPortfoliosFlow.Success key={ModalIds.transferBetweenPortfoliosSuccess + index} />;
      }

      case ModalIds.closePortfolioConfirmation: {
        return <ClosePortfolioFlow.Confirmation key={ModalIds.closePortfolioConfirmation + index} />;
      }

      case ModalIds.closePortfolioSuccess: {
        return <ClosePortfolioFlow.Success key={ModalIds.closePortfolioSuccess + index} />;
      }

      case ModalIds.renamePortfolioForm: {
        return <RenamePortfolioFlow.Form key={ModalIds.renamePortfolioForm + index} />;
      }

      case ModalIds.renamePortfolioSuccess: {
        return <RenamePortfolioFlow.Success key={ModalIds.renamePortfolioSuccess + index} />;
      }

      case ModalIds.datePicker: {
        return <DatePickerModal key={ModalIds.datePicker + index} />;
      }

      case ModalIds.withdrawAccountForm: {
        return <WithdrawAccountFlow.Form key={ModalIds.withdrawAccountForm + index} />;
      }

      case ModalIds.withdrawAccountSuccess: {
        return <WithdrawAccountFlow.Success key={ModalIds.withdrawAccountSuccess + index} />;
      }

      case ModalIds.closeAllPositionsConfirmation: {
        return <CloseAllPositionsFlow.Confirmation key={ModalIds.closeAllPositionsConfirmation + index} />;
      }

      case ModalIds.closeAllPositionsSuccess: {
        return <CloseAllPositionsFlow.Success key={ModalIds.closeAllPositionsSuccess + index} />;
      }

      case ModalIds.changeStrategy: {
        return <ChangeStrategyFlow key={ModalIds.changeStrategy + index} />;
      }

      case ModalIds.paymentSettingsForm: {
        return <PaymentSettingsFlow.Form key={ModalIds.paymentSettingsForm + index} />;
      }

      case ModalIds.paymentSettingsSuccess: {
        return <PaymentSettingsFlow.Success key={ModalIds.paymentSettingsSuccess + index} />;
      }

      case ModalIds.portfoliosSettingsSummary: {
        return <PortfoliosSettingsFlow.Summary key={ModalIds.portfoliosSettingsSummary + index} />;
      }

      case ModalIds.portfoliosSettingsAdd: {
        return <PortfoliosSettingsFlow.Add key={ModalIds.portfoliosSettingsAdd + index} />;
      }

      case ModalIds.portfoliosSettingsEdit: {
        return <PortfoliosSettingsFlow.Edit key={ModalIds.portfoliosSettingsEdit + index} />;
      }

      case ModalIds.portfoliosSettingsDelete: {
        return <PortfoliosSettingsFlow.Delete key={ModalIds.portfoliosSettingsDelete + index} />;
      }

      case ModalIds.reportsIndividualAccount: {
        return <ReportsFlow.IndividualAccount key={ModalIds.reportsIndividualAccount + index} />;
      }

      case ModalIds.reportsIndividualPortfolio: {
        return <ReportsFlow.IndividualPortfolio key={ModalIds.reportsIndividualPortfolio + index} />;
      }

      case ModalIds.reportsCollectiveAccountsPortfolios: {
        return <ReportsFlow.CollectiveAccountsPortfolios key={ModalIds.reportsCollectiveAccountsPortfolios + index} />;
      }

      case ModalIds.reportsCollectiveAccounts: {
        return <ReportsFlow.CollectiveAccounts key={ModalIds.reportsCollectiveAccounts + index} />;
      }

      case ModalIds.reportsCollectivePortfolios: {
        return <ReportsFlow.CollectivePortfolios key={ModalIds.reportsCollectivePortfolios + index} />;
      }

      case ModalIds.reportsGenerateReport: {
        return <ReportsFlow.GenerateReport key={ModalIds.reportsGenerateReport + index} />;
      }

      case ModalIds.newParticipantSetup: {
        return <NewParticipantSetupFlow key={ModalIds.newParticipantSetup + index} />;
      }

      case ModalIds.personConfirmation: {
        return <PersonConfirmation key={ModalIds.personConfirmation + index} />;
      }

      case ModalIds.twoFaChanged: {
        return <TwoFaChanged key={ModalIds.twoFaChanged + index} />;
      }

      case ModalIds.passwordChanged: {
        return <PasswordChanged key={ModalIds.passwordChanged + index} />;
      }

      case ModalIds.securitySettingsChangeError: {
        return <SecuritySettingsChangeError key={ModalIds.securitySettingsChangeError + index} />;
      }

      case ModalIds.mailServerError: {
        return <MailServerErrorModal key={ModalIds.mailServerError + index} />;
      }

      case ModalIds.mailServerSuccess: {
        return <MailServerSuccessModal key={ModalIds.mailServerSuccess + index} />;
      }

      case ModalIds.settingsConnection: {
        return <SettingsConnectionModal key={ModalIds.settingsConnection + index} />;
      }

      case ModalIds.requestServiceAction: {
        return <RequestServiceActionModal key={ModalIds.requestServiceAction + index} />;
      }

      case ModalIds.dataAccountError: {
        return <DataAccountError key={ModalIds.dataAccountError + index} />;
      }

      case ModalIds.dataAccountSuccess: {
        return <DataAccountSuccess key={ModalIds.dataAccountSuccess + index} />;
      }

      case ModalIds.brandingError: {
        return <BrandingError key={ModalIds.brandingError + index} />;
      }

      case ModalIds.brandingSuccess: {
        return <BrandingSuccess key={ModalIds.brandingSuccess + index} />;
      }

      case ModalIds.loginRequestChangeRole: {
        return <LoginRequest.ChangeRole key={ModalIds.loginRequestChangeRole} />;
      }

      case ModalIds.loginRequestGiveAccess: {
        return <LoginRequest.GiveAccess key={ModalIds.loginRequestGiveAccess} />;
      }

      case ModalIds.invoiceDownloaded: {
        return <InvoiceDownloadedModal key={ModalIds.invoiceDownloaded + index} />;
      }

      case ModalIds.invoiceCanceled: {
        return <InvoiceCanceledModal key={ModalIds.invoiceCanceled + index} />;
      }

      case ModalIds.invoiceCreated: {
        return <InvoiceCreatedModal key={ModalIds.invoiceCreated + index} />;
      }

      case ModalIds.usersManagementDelete: {
        return <UsersManagementFlow.Delete key={ModalIds.usersManagementDelete + index} />;
      }

      case ModalIds.usersManagementEdit: {
        return <UsersManagementFlow.Edit key={ModalIds.usersManagementEdit + index} />;
      }

      case ModalIds.usersManagementInvite: {
        return <UsersManagementFlow.Invite key={ModalIds.usersManagementInvite + index} />;
      }

      case ModalIds.withdrawPortfolioTotalWithdraw: {
        return <WithdrawPortfolioFlow.TotalWithdraw key={ModalIds.withdrawPortfolioTotalWithdraw + index} />;
      }

      case ModalIds.transferBetweenPortfoliosTotalTransfer: {
        return (
          <TransferBetweenPortfoliosFlow.TotalTransfer key={ModalIds.transferBetweenPortfoliosTotalTransfer + index} />
        );
      }

      case ModalIds.accountOrdersDeclination: {
        return <AccountOrdersDeclination key={ModalIds.accountOrdersDeclination + index} />;
      }

      case ModalIds.vnc: {
        return <VNCModal key={ModalIds.vnc + index} />;
      }

      default:
        return null;
    }
  }, []);

  return <>{activeModals?.map((item, index) => renderModal(item, index))}</>;
};

export { Modals };
