import { Action, State } from './information.types';
import { Frame, getAccountStrategy, getManagerParticipants, getStrategies } from '../../../services/api';
import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { initialState, reducer } from './information.state';

import { useAppContext } from '../../../contexts';
import { useSearchParams } from 'react-router-dom';

const InformationContext = createContext<State & { readonly dispatch: Dispatch<Action> }>({
  dispatch: () => null,
  yieldPeriod: Frame.month,
});

const InformationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentRole, user, isLoading, dispatch: appDispatch } = useAppContext();
  const [params] = useSearchParams();

  const accountId = useMemo(() => params.get('accountId') ?? undefined, [params]);
  const portfolioId = useMemo(() => params.get('portfolioId') ?? undefined, [params]);

  const fetchData = useCallback(async () => {
    if (user && currentRole) {
      if (!state.participants && !isLoading) {
        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        try {
          const { data } = await getManagerParticipants({ roleLoginEmail: user.email, role: currentRole });
          data && dispatch({ type: 'SET_PARTICIPANTS', payload: data });
        } catch (error) {
          console.error(error);
        }

        appDispatch({ type: 'TOGGLE_IS_LOADING' });
      }
      if (accountId && !isLoading && state.participants && !state.accountStrategy) {
        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        try {
          const { data } = await getAccountStrategy({
            acctId: accountId,
            role: currentRole,
            roleLoginEmail: user.email,
          });
          data && dispatch({ type: 'SET_ACCOUNT_STRATEGY', payload: data });
        } catch (error) {
          console.error(error);
        }

        appDispatch({ type: 'TOGGLE_IS_LOADING' });
      }
      if (accountId && !isLoading && state.accountStrategy && !state.strategies) {
        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        try {
          const { data } = await getStrategies({
            lang: user.preferences.language,
            role: currentRole,
            roleLoginEmail: user.email,
          });
          data && dispatch({ type: 'SET_STRATEGIES', payload: data });
        } catch (error) {
          console.error(error);
        }

        appDispatch({ type: 'TOGGLE_IS_LOADING' });
      }
    }
  }, [
    accountId,
    appDispatch,
    currentRole,
    isLoading,
    state.accountStrategy,
    state.participants,
    state.strategies,
    user,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    accountId &&
      state.participants &&
      state.account?.AccountID !== accountId &&
      dispatch({ type: 'SET_ACCOUNT', payload: state.participants.find(({ AccountID }) => AccountID === accountId) });
    !accountId && dispatch({ type: 'SET_ACCOUNT', payload: undefined });

    portfolioId &&
      state.participants &&
      state.portfolio?.PortfolioID !== portfolioId &&
      dispatch({
        type: 'SET_PORTFOLIO',
        payload: state.participants
          ?.find(({ Portfolios }) => Portfolios.some(({ PortfolioID }) => PortfolioID === portfolioId))
          ?.Portfolios.find(({ PortfolioID }) => PortfolioID === portfolioId),
      });
    !portfolioId && dispatch({ type: 'SET_PORTFOLIO', payload: undefined });
  }, [accountId, portfolioId, state.account?.AccountID, state.participants, state.portfolio?.PortfolioID]);

  return <InformationContext.Provider value={{ ...state, dispatch }}>{children}</InformationContext.Provider>;
};

const useInformationContext = () => useContext(InformationContext);

export { InformationContextProvider, useInformationContext };
