import React, { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { initialState, settingsReducer } from './settings.state';

import { SettingsContextProps } from './settings.types';
import { getSupportInfo } from '../../../../../management-office/app/services/api';
import { useAppContext } from '../../../context';

/**
 * SettingsContext
 * @description Context for SettingsRouter pages
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const SettingsContext = createContext<SettingsContextProps>({
  dispatch: () => null,
});

/**
 * SettingsContextProvider component
 * @description Provider for the SettingsContext
 *
 * @author Oleksii Medvediev
 * @category Context Providers
 */
const SettingsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading } = useAppContext();
  const [state, dispatch] = useReducer(settingsReducer, initialState);

  const fetchSupportInfo = useCallback(async () => {
    if (!isLoading && !state.supportInfo) {
      try {
        const { data } = await getSupportInfo();

        data && dispatch({ type: 'SET_SUPPORT_INFO', payload: data });
      } catch (error) {
        console.error(error);
        dispatch({
          type: 'SET_SUPPORT_INFO',
          payload: {
            Mail: 'customer@investudio.com.ua',
            Phone: '+380980001138',
            Telegram: '+380980001138',
            WhatsApp: '+380980001138',
          },
        });
      }
    }
  }, [isLoading, state.supportInfo]);

  useEffect(() => {
    fetchSupportInfo();
  }, [fetchSupportInfo]);

  return <SettingsContext.Provider value={{ ...state, dispatch }}>{children}</SettingsContext.Provider>;
};

/**
 * useSettingsContext hook.
 * @description The hook for getting SettingsContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useSettingsContext = () => useContext(SettingsContext);

export { SettingsContextProvider, useSettingsContext };
