import React, { FC } from 'react';

import { RadiosTypes } from './radios.types';
import { Typography } from '../typography';
import classNames from 'classnames';
import styles from './radios.module.scss';

/**
 * Radios component
 * @description Radios
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { RadiosTypes } props - RadiosTypes defined in the './radios.types.ts'
 */
const Radios: FC<RadiosTypes> = ({ name, id, value, onChange, checked, text, disabled }) => {
  return (
    <label htmlFor={id} className={classNames(styles.wrapper, disabled && styles.disabledWrapper)}>
      <input
        className={styles.radioInput}
        type={'radio'}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
        hidden
        disabled={disabled}
      />
      <span className={styles.customRadio} />
      <Typography.Text variant={'bodyText2'}>{text}</Typography.Text>
    </label>
  );
};

export { Radios };
