import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './account-orders-declination.module.scss';
import { useAccountOrdersDeclinationData } from './account-orders-declination.hook';

/**
 * OrderDeclination component
 * @description Content for the OrderDeclination modal.
 * @link for one account modal https://www.figma.com/file/pCwb00CHfXi1s4Yd5q3q0U/Trader--%7C-File-for-development?type=design&node-id=1787-136262&mode=dev
 * @link for few accounts modal https://www.figma.com/file/pCwb00CHfXi1s4Yd5q3q0U/Trader--%7C-File-for-development?type=design&node-id=1787-136309&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const AccountOrdersDeclination: FC = () => {
  const { t, modalData, handleCloseModal } = useAccountOrdersDeclinationData();

  return modalData ? (
    <Modal modalId={ModalIds.accountOrdersDeclination} title={t(`modals.accountOrdersDeclination.title`)}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {t(`modals.accountOrdersDeclination.description-${modalData.accountsQuantity > 1 ? 'few' : 'one'}`)}
        </Typography.Text>
        <div className={styles.controlButtons}>
          <Button type={'button'} variant={'outline'} size={'large'} onClick={handleCloseModal}>
            <Typography.Text variant={'bodyText1'}>{t(`modals.accountOrdersDeclination.cancel`)}</Typography.Text>
          </Button>
          <Button type={'button'} variant={'main'} size={'large'} onClick={modalData.onSubmit}>
            <Typography.Text variant={'bodyText1'}>{t(`modals.accountOrdersDeclination.continue`)}</Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export { AccountOrdersDeclination };
