import {
  ChartIndicator,
  ConnectionIndicator,
  StatusIndicator,
  TablePriority,
  TendencyIndicator,
  UsersIndicator,
} from './components';

const Indicator = {
  Table: TablePriority,
  Status: StatusIndicator,
  Users: UsersIndicator,
  Connection: ConnectionIndicator,
  Chart: ChartIndicator,
  Tendency: TendencyIndicator,
  // Notification Indicator === Indicator.Status[info]
};

export { Indicator };
