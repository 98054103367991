import { Button, Typography } from '../../../../..';
import React, { FC } from 'react';

import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import styles from './give-access-modal.module.scss';
import { useGiveAccessData } from './give-access-modal.hook';

/**
 * GiveAccess component
 * @description Exclusively for the LoginRequest components as its component
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 */
const GiveAccess: FC = () => {
  const { t, fullName, cancelHandler, acceptHandler } = useGiveAccessData();

  // after 60 sec cancel+hide this modal

  return (
    <Modal modalId={ModalIds.loginRequestGiveAccess} title={t('modals.loginRequest.giveAccess.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {t('modals.loginRequest.giveAccess.p1', { fullName })}
          <br />
          <br />
          {t('modals.loginRequest.giveAccess.p2')}
        </Typography.Text>

        <div className={styles.controlButtons}>
          <Button variant={'outline'} size={'large'} className={styles.btn} onClick={cancelHandler}>
            {t('modals.loginRequest.giveAccess.controlButtons.cancel')}
          </Button>
          <Button variant={'main'} size={'large'} className={styles.btn} onClick={acceptHandler}>
            {t('modals.loginRequest.giveAccess.controlButtons.accept')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { GiveAccess };
