import React, { FC } from 'react';

import { ChartIndicatorProps } from './chart-indicator.types';
import { Typography } from '../../../typography';
import styles from './chart-indicator.module.scss';

/**
 * ChartIndicator component
 * @description Chart count indicator
 *
 * @author Rostyslav Nahornyi, Sergii Goncharuk, Oleksii Medvediev
 * @category Components
 * @param { ChartIndicatorProps } props - ChartIndicatorProps defined in the './messages-indicator.types.ts'
 */
const ChartIndicator: FC<ChartIndicatorProps> = ({ type, trend, text, color }) => (
  <div className={styles.wrapper}>
    <div className={styles[type]} style={color ? { backgroundColor: color } : {}} />
    <Typography.Text className={styles.text} variant={'bodyText3'}>
      {text}.&nbsp;(<Typography.Number value={+trend.toFixed(2)} variant={'bodyText3'} percent />)
    </Typography.Text>
  </div>
);

export { ChartIndicator };
