import {
  Branding,
  CancelLoginRequestPayload,
  GetAdminSettingsResponse,
  getTradeVisionConnectionStatusResponse,
  GetPortalsResponse,
  MoLoginPayload,
  MoUser,
  NewPasswordSetupPayload,
  PasswordRecoveryPayload,
  RegisterRootUserPayload,
  RegisterUserPayload,
  RequestLoginPayload,
  VerifyEmailResendPayload,
  VerifyTwoFaPayload,
} from './management-office.model';

import { request } from '../../http';

export const getAdminSettings = () => {
  return request<{ readonly data: ReadonlyArray<GetAdminSettingsResponse> }>({
    method: 'get',
    url: 'office/admin/settings',
  });
};

/**
 * Get Branding settings
 */
export const getBranding = () =>
  request<{ readonly data?: Branding }>({
    method: 'get',
    url: 'office/admin/branding',
  });

/**
 * Root user pre-register validation
 */
export const preRegisterValidate = (data: VerifyEmailResendPayload) =>
  request<{ readonly data?: { readonly apiKey: string; readonly companyName: string } }>({
    method: 'post',
    url: 'auth-management-office/pre-register-validation',
    data,
  });

/**
 * Root user registration
 */
export const registerRootUser = (data: RegisterRootUserPayload) =>
  request({ method: 'post', url: 'auth-management-office/root-sign-up', data });

/**
 * Common user registration
 */
export const registerUser = (data: RegisterUserPayload) =>
  request<{ readonly data?: { link: string } }>({ method: 'post', url: 'auth-management-office/sign-up', data });

export const getTradeVisionConnectionStatus = (email?: string) =>
  request<{ readonly data: getTradeVisionConnectionStatusResponse }>({
    method: 'get',
    url: 'office/connection-status',
    params: { email },
  });

/**
 * Request login
 */
export const requestLogin = (data: RequestLoginPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/request-login' });

/**
 * Cancel login request
 */
export const cancelLoginRequest = (data: CancelLoginRequestPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/cancel-login-request' });

/**
 * Login
 */
export const moLogin = (data: MoLoginPayload) =>
  request<{
    readonly data:
      | (MoUser & { readonly settings: ReadonlyArray<GetAdminSettingsResponse> })
      | 'secondFactor'
      | 'loginRequest';
    readonly token?: string;
  }>({
    method: 'post',
    data,
    url: 'auth-management-office/login',
  });

/**
 * Verify two-factor code
 */
export const verifyTwoFa = (data: VerifyTwoFaPayload) =>
  request<{
    readonly data: (MoUser & { readonly settings: ReadonlyArray<GetAdminSettingsResponse> }) | 'loginRequest';
    readonly token?: string;
  }>({
    method: 'post',
    data,
    url: 'auth-management-office/verify-two-fa',
  });

export const getPortals = (email: string) =>
  request<{ readonly data: GetPortalsResponse }>({
    method: 'get',
    url: 'auth-management-office/get-user-affiliation',
    params: { email },
  });

export const initPasswordRecovery = ({ email }: PasswordRecoveryPayload) =>
  request<{ readonly message: string }>({
    method: 'post',
    url: 'auth-management-office/password-recovery',
    data: { email },
  });

/**
 * New password setup
 */
export const setupNewPassword = (data: NewPasswordSetupPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/new-password-setup' });
