import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { ErrorProps } from './error.types';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import styles from './error.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Error component
 * @description Content for the Error stage modal of UsersManagement Invite flow
 *
 * @author Oleksii Medvediev
 * @param { ErrorProps } props - ErrorProps defined in the './error.component.tsx'
 * @category Components
 */
const Error: FC<ErrorProps> = ({ name, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  return (
    <Modal modalId={ModalIds.usersManagementInvite} title={t('modals.usersManagement.invite.error.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {t('modals.usersManagement.invite.error.text', { name })}
        </Typography.Text>
        <Button type={'button'} variant={'main'} size={'large'} onClick={onClose}>
          <Typography.Text variant={'bodyText1'}>{t('modals.usersManagement.invite.error.continue')}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { Error };
