import {
  AdminInitResponse,
  AdminInvoicePdf,
  AdminInvoicesAction,
  AdminInvoicesDto,
  AdminP1Payload,
  AdminP1Response,
  AdminP2Payload,
  AdminP2Response,
  AdminP3Payload,
  AdminP3Response,
  AdminP4Payload,
  AdminP4Response,
  AdminP6Payload,
  AdminP6Response,
  AdminP7Payload,
  AdminP7Response,
  AdminSettingsDto,
  AdminSubscriptionsResponse,
  GetAdminSettingsResponse,
  GetUsersListResponse,
  ManagerUser,
  ProjectConfig,
  ProjectConfigStatuses,
} from './admin.model';

import { MoUser } from '../../../../../auth/app/services/api/management-office';
import { request } from '../../http';

export const getAdminHeaderData = (data: AdminP1Payload) =>
  request<{ readonly data: AdminP1Response }>({
    method: 'get',
    url: `office/admin/p1/${data.roleLoginEmail}/${data.role}`,
  });

export const getP2 = (data: AdminP2Payload) =>
  request<{ readonly data: AdminP2Response }>({
    method: 'get',
    url: `office/admin/p2/${data.roleLoginEmail}/${data.role}`,
    params: { inDate: data.inDate, frame: data.frame },
  });

export const getAdminMessages = ({ lang, role, roleLoginEmail }: AdminP3Payload) =>
  request<{ readonly data: AdminP3Response }>({
    method: 'get',
    url: `office/admin/p3?roleLoginEmail=${roleLoginEmail}&lang=${lang}&role=${role}`,
  });

export const getP4 = (data: AdminP4Payload) =>
  request<{ readonly data: AdminP4Response }>({ method: 'get', url: `office/admin/p4/${data.role}` });

export const getP6 = ({ role, language, topicLimit }: AdminP6Payload) =>
  request<{ readonly data: AdminP6Response }>({
    method: 'get',
    url: `office/admin/p6?role=${role}&lang=${language}&topicLimit=${topicLimit}`,
  });

export const getP7 = (params: AdminP7Payload) =>
  request<{ readonly data: AdminP7Response }>({
    method: 'get',
    url: 'office/admin/p7',
    params,
  });

export const getUsersList = () =>
  request<{ readonly data: GetUsersListResponse }>({ method: 'get', url: 'auth-management-office/get-users' });

export const inviteUser = (data: ManagerUser) =>
  request<{ readonly data?: MoUser; readonly message?: string }>({
    method: 'post',
    data,
    url: 'auth-management-office/invite-user',
  });

export const editUser = (data: ManagerUser & { id: string }) =>
  request<{ readonly data: MoUser }>({ method: 'post', data, url: 'auth-management-office/edit-user' });

export const deleteUser = (data: { id: string }) =>
  request({ method: 'post', data, url: 'auth-management-office/delete-user' });

export const getAdminSettings = () => {
  return request<{ readonly data: ReadonlyArray<GetAdminSettingsResponse> }>({
    method: 'get',
    url: 'office/admin/settings',
  });
};

export const createAdminSetting = (data: AdminSettingsDto) => {
  return request<{ readonly data: GetAdminSettingsResponse }>({
    method: 'post',
    url: 'office/admin/create-setting',
    data,
  });
};

export const updateAdminSetting = (data: AdminSettingsDto) => {
  return request<{ readonly data: GetAdminSettingsResponse }>({
    method: 'post',
    url: 'office/admin/update-setting',
    data,
  });
};

export const getAdminSubscriptions = () =>
  request<{ readonly data: AdminSubscriptionsResponse }>({ method: 'get', url: 'is-connector/subscriptions' });

export const getAdminInvoices = (data: AdminInvoicesDto) =>
  request<AdminInvoicePdf>({
    method: 'post',
    url: 'is-connector/invoices',
    data: {
      ...data,
      action: AdminInvoicesAction.download,
    },
    headers: {
      Accept: 'application/pdf',
    },
    responseType: 'blob',
  });

export const cancelAdminInvoices = (data: AdminInvoicesDto) =>
  request<unknown>({
    method: 'post',
    url: 'is-connector/invoices',
    data: {
      ...data,
      action: AdminInvoicesAction.cancel,
    },
  });

export const getInitForAdmin = (params: AdminP2Payload & AdminP4Payload & AdminP3Payload) =>
  request<{ readonly data: AdminInitResponse }>({ method: 'get', url: 'office/admin/init', params });

export const reinitializeIBTWSModule = () =>
  request<{ readonly data: { readonly status: 'success' | 'failed' } }>({
    method: 'post',
    url: 'ibkr-tws/reinitialize',
  });

export const getProjectConfig = () => {
  return request<{ readonly data: ProjectConfig }>({
    method: 'get',
    url: 'office/admin/project-config',
  });
};

export const getProjectConfigStatuses = (connection: 'ibGateway' | 'tradingModule' | 'all') => {
  return request<{ readonly data: ProjectConfigStatuses }>({
    method: 'get',
    url: `office/admin/project-config/statuses?connection=${connection}`,
  });
};

export const setProjectConfigIBGatewaySettings = (data: { readonly login: string; readonly password: string }) => {
  return request<{ readonly data: Pick<ProjectConfig['IBTWS']['ENV'], 'TWS_USERID' | 'TWS_PASSWORD_FILE'> }>({
    method: 'post',
    url: 'office/admin/set-project-config/ibGateway',
    data,
  });
};

export const setProjectConfigTradingModuleSettings = (
  data: Pick<ProjectConfig['TradingModule']['ENV'], 'HOST' | 'PORT'>,
) => {
  return request<{ readonly data: Pick<ProjectConfig['TradingModule']['ENV'], 'HOST' | 'PORT'> }>({
    method: 'post',
    url: 'office/admin/set-project-config/tradingModule',
    data,
  });
};

export const startProjectConfigInstance = (instance: 'ibGateway' | 'tradingModule') => {
  return request<{ readonly data: { readonly status: string } }>({
    method: 'get',
    url: `office/admin/project-config/start?instance=${instance}`,
  });
};

export const stopProjectConfigInstance = (instance: 'ibGateway' | 'tradingModule') => {
  return request<{ readonly data: { readonly status: string } }>({
    method: 'get',
    url: `office/admin/project-config/stop?instance=${instance}`,
  });
};

export const restartProjectConfigInstance = (instance: 'ibGateway' | 'tradingModule') => {
  return request<{ readonly data: { readonly status: string } }>({
    method: 'get',
    url: `office/admin/project-config/restart?instance=${instance}`,
  });
};

export const getVncURL = () => {
  return request<{ readonly data: { readonly url: string; readonly password: string } }>({
    method: 'get',
    url: 'office/admin/project-config/vnc-url',
  });
};
