import { AccordionHeaderProps } from './accordion-header.types';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrow-down.svg';
import React from 'react';
import { Typography } from '../../../typography';
import classNames from 'classnames';
import styles from './accordion-header.module.scss';

/**
 * AccordionHeader component
 * @description Header of accordion
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const AccordionHeader: React.FC<AccordionHeaderProps> = ({ title, isExpanded, onClick, hideBottomLine, isMessage }) => (
  <div
    role={'button'}
    tabIndex={0}
    onClick={onClick}
    onKeyDown={onClick}
    className={classNames(
      styles.accordionHeader,
      hideBottomLine ? isExpanded && styles.expandedHeaderWithoutLine : isExpanded && styles.expandedHeader,
    )}
  >
    {typeof title === 'string' ? (
      <Typography.Text variant={isMessage ? 'bodyText1' : 'h3'}>{title}</Typography.Text>
    ) : (
      title
    )}
    <ArrowIcon className={isExpanded ? styles.on : styles.off} />
  </div>
);

export { AccordionHeader };
