import React, { FC } from 'react';

import { TabProps } from '../tab.types';
import { Typography } from '../../typography';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import styles from './settings.module.scss';

/**
 * Exclusively Settings component for Tab
 * @description Settings tab
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { TabProps } props - TabProps defined in the '../tab-props.types.ts'
 */
const Settings: FC<TabProps> = ({ label, isSelected, className, style, onClick, disabled }) => (
  <button
    tabIndex={0}
    aria-label={'button'}
    type={'button'}
    className={classNames(styles.settingsTab, isSelected && styles.selected, className)}
    style={style}
    onClick={onClick}
    disabled={disabled}
  >
    <Typography.Text variant={'bodyText1'} className={styles.title}>
      {capitalize(label)}
    </Typography.Text>
  </button>
);

export { Settings };
