import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './security-settings-change-error.module.scss';
import { useSecuritySettingsChangeErrorData } from './security-settings-change-error.hook';

/**
 * SecuritySettingsChangeError component
 * @description Security settings change error modal window component.
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const SecuritySettingsChangeError: FC = () => {
  const { handleCloseModal, t, type } = useSecuritySettingsChangeErrorData();

  return (
    <Modal modalId={ModalIds.securitySettingsChangeError} title={t(`modals.securitySettingsChangeError.title`)}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>{t(`modals.securitySettingsChangeError.${type}Text`)}</Typography.Text>
        <Button variant={'main'} size={'large'} type={'button'} onClick={handleCloseModal}>
          <Typography.Text variant={'bodyText1'}>{t(`modals.securitySettingsChangeError.continue`)}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { SecuritySettingsChangeError };
