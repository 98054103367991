import { Error, Request, Success } from './components';
import React, { useCallback, useMemo } from 'react';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { CancelProps } from './cancel.types';
import { ErrorProps } from './components/error/error.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { RequestProps } from './components/request/request.types';
import { SuccessProps } from './components/success/success.types';
import { setAutomatedOrdersConfirmationExpDate } from '../../../../../../services/api';

/**
 * useCancelData hook
 * @description The hook which processes AutomatedOrdersConformation Cancel flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useCancelData = () => {
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { automatedOrdersConfirmationCancel },
    dispatch,
  } = useModalsContext();

  const flowData: CancelProps | undefined = useMemo(
    () => automatedOrdersConfirmationCancel,
    [automatedOrdersConfirmationCancel],
  );

  const setStage = useCallback(
    (stage: CancelProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.automatedOrdersConfirmationCancel,
          data: {
            [ModalIds.automatedOrdersConfirmationCancel]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<CancelProps['currentStage'], RequestProps['onSubmit'] | SuccessProps['onClose']> =
    useMemo(
      () => ({
        request: async (value) => {
          if (value === 'yes') {
            if (user && flowData) {
              appDispatch({ type: 'TOGGLE_IS_LOADING' });

              try {
                const { data } = await setAutomatedOrdersConfirmationExpDate({
                  updatedBy: user.email,
                  strategyId: flowData.strategyId,
                  period: undefined,
                });
                if (data && data.automatedOrdersConfirmationExpDate) {
                  flowData.onRefetch();
                  setStage('success');
                } else {
                  setStage('error');
                }
              } catch (error) {
                console.error(error);
                setStage('error');
              }

              appDispatch({ type: 'TOGGLE_IS_LOADING' });
            }
          } else {
            dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationCancel });
          }
        },
        success: () => {
          dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationCancel });
        },
        error: () => {
          dispatch({ type: 'HIDE_MODAL', payload: ModalIds.automatedOrdersConfirmationCancel });
        },
      }),
      [appDispatch, flowData, dispatch, setStage, user],
    );

  const stages: Record<CancelProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            request: <Request onSubmit={submitHandlers.request} />,
            success: <Success onClose={submitHandlers.success as SuccessProps['onClose']} />,
            error: <Error onClose={submitHandlers.error as ErrorProps['onClose']} />,
          }
        : undefined,
    [flowData, submitHandlers.error, submitHandlers.request, submitHandlers.success],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useCancelData };
