import { Form as FormComponent, Formik } from 'formik';
import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { FormProps } from './form.types';
import { Input } from '../../../../../../../input';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../typography';
import { capitalize } from 'lodash';
import styles from './form.module.scss';

/**
 * Form component
 * @description Content for the PortfoliosSettings flow Add stage form
 *
 * @author Oleksii Medvediev
 * @param { FormProps } props - FormProps defined in the './form.types.ts'
 * @category Components
 */
const Form: FC<FormProps> = ({
  initialValues,
  onBackButtonClick,
  onClose,
  onSubmitStage,
  t,
  undividedCapital,
  validationSchema,
}: FormProps) => (
  <Modal
    modalId={ModalIds.portfoliosSettingsAdd}
    title={capitalize(t('modals.portfoliosSettings.add.form.title'))}
    onBackButtonClick={onBackButtonClick}
  >
    <div className={styles.wrapper}>
      <div className={styles.subtitleRow}>
        <Typography.Text variant={'label2'}>{t('modals.portfoliosSettings.add.form.subtitle')}</Typography.Text>
        <Typography.Number currency value={Math.round(undividedCapital)} variant={'h3'} />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        onSubmit={({ capital, owner, ownerEmail }) =>
          onSubmitStage({ owner, ownerEmail, capital: capital.replaceAll(' ', '') })
        }
      >
        {({ setFieldValue, setFieldTouched, errors, touched, values }) => (
          <FormComponent>
            <div className={styles.inputFields}>
              <Input
                id={'owner'}
                label={capitalize(t('modals.portfoliosSettings.add.form.owner'))}
                type={'text'}
                onBlur={() => setFieldTouched('owner', true)}
                onChange={(value) => setFieldValue('owner', value)}
                error={errors.owner && touched.owner ? errors.owner : undefined}
              />
              <Input
                id={'capital'}
                label={capitalize(t('modals.portfoliosSettings.add.form.capital'))}
                type={'text'}
                isCurrency={true}
                value={values.capital}
                onChange={(value) => {
                  setFieldValue('capital', !!+value.toString().replaceAll(' ', '') ? value : null);
                }}
                onBlur={() => setFieldTouched('capital', true)}
                error={
                  values.capital && parseFloat(values.capital.replace(/[^0-9.-]+/g, '')) > undividedCapital
                    ? t('modals.withdrawPortfolio.form.insufficient')
                    : errors.capital && touched.capital
                    ? errors.capital
                    : undefined
                }
              />
              <Input
                id={'email'}
                label={capitalize(t('modals.portfoliosSettings.add.form.ownerEmail'))}
                type={'text'}
                onChange={(value) => setFieldValue('ownerEmail', value)}
                error={errors.ownerEmail && touched.ownerEmail ? errors.ownerEmail : undefined}
              />
            </div>
            <div className={styles.controls}>
              <Button variant={'outline'} size={'large'} onClick={onClose}>
                <Typography.Text variant={'bodyText1'}>
                  {capitalize(t('modals.portfoliosSettings.add.form.cancel'))}
                </Typography.Text>
              </Button>
              <Button
                variant={'main'}
                size={'large'}
                type={'submit'}
                disabled={
                  !!Object.values(errors).length ||
                  (values.capital && parseFloat(values.capital.replace(/[^0-9.-]+/g, '')) > undividedCapital) ||
                  !Object.values(values).every((value) => !!value)
                }
              >
                {capitalize(t('modals.portfoliosSettings.add.form.addPortfolio'))}
              </Button>
            </div>
          </FormComponent>
        )}
      </Formik>
    </div>
  </Modal>
);

export { Form };
