import { useAppContext, useModalsContext } from '../../../../contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { getAdminInvoices } from '../../../../services/api';
import { useTranslation } from 'react-i18next';

/**
 * useInvoiceDownloadedModal
 * @description Gets useInvoiceDownloadedModal component data from store
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Hooks
 * @returns AccountDownloadedData
 */
const useInvoiceDownloadedModal = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { invoiceDownloaded },
    dispatch,
  } = useModalsContext();
  const { dispatch: appDispatch } = useAppContext();

  const [isInvoiceDownloaded, setIsInvoiceDownloaded] = useState(false);

  const modalData = useMemo(() => invoiceDownloaded, [invoiceDownloaded]);

  const handleDownloadInvoice = useCallback(async () => {
    if (modalData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const data = await getAdminInvoices({
          id: modalData.subscriptionId,
          currentSubscription: modalData.currentSubscription,
        });
        if (data) {
          setIsInvoiceDownloaded(true);
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        }
      } catch (error) {
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, modalData]);

  useEffect(() => {
    handleDownloadInvoice();
  }, [handleDownloadInvoice]);

  const handleContinueClick = useCallback(async () => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.invoiceDownloaded });
  }, [dispatch]);

  return {
    t,
    isInvoiceDownloaded,
    handleContinueClick,
  };
};

export { useInvoiceDownloadedModal };
