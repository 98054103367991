import React, { Ref, forwardRef } from 'react';

import { ButtonProps } from './button.types';
import { Typography } from '../typography';
import classNames from 'classnames';
import styles from './button.module.scss';

/**
 * Button component
 * @description Button
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { ButtonProps } props - ButtonProps defined in the './button.types.ts'
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      variant,
      leadingIcon,
      trailingIcon,
      selected,
      label,
      className,
      style,
      type = 'button',
      disabled,
      ignoreSVGColor,
      onClick,
      children,
    },
    ref: Ref<HTMLButtonElement>,
  ) => (
    <button
      className={classNames(
        styles.button,
        styles[size],
        styles[variant],
        selected && styles.active,
        disabled && styles.disabled,
        !ignoreSVGColor && styles.ignoreSVGColor,
        className,
      )}
      type={type}
      disabled={disabled}
      style={style}
      tabIndex={0}
      aria-label={'button'}
      ref={ref}
      onClick={onClick}
    >
      <>
        {leadingIcon && <div className={styles.icon}>{leadingIcon}</div>}
        {label && (
          <Typography.Text
            variant={
              variant === 'underline'
                ? 'linkButton'
                : size === 'small' && variant !== 'floating'
                ? 'bodyText2'
                : variant === 'main2'
                ? 'label1'
                : 'bodyText1'
            }
          >
            {label}
          </Typography.Text>
        )}
        {!label && children && children}
        {trailingIcon && <div className={styles.icon}>{trailingIcon}</div>}
      </>
    </button>
  ),
);

Button.displayName = 'Button';

export { Button };
