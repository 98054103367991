import React, { FC } from 'react';
import { FoldItem, MenuItem } from './components';

import { Typography } from '../typography';
import { FoldItemProps } from './components/fold-item/fold-item.types';
import styles from './sidebar.module.scss';
import { SidebarProps } from './sidebar.types';

/**
 * Sidebar
 * @description Sidebar component displayed on the left side of the app.
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { SidebarProps } props - SidebarProps defined in the './sidebar.types.ts'.
 */
const Sidebar: FC<SidebarProps> = ({ logoSrc, sections, companyName }) => {
  return (
    <aside className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={logoSrc} alt={'company-logo'} />
        <Typography.Text variant={'bodyText2'} className={styles.companyName}>
          {companyName}
        </Typography.Text>
      </div>
      <div className={styles.menu}>
        {!!sections?.length &&
          sections.map((section, index) => (
            <div className={styles.section} key={index.toString().concat(section?.length.toString())}>
              {!!section?.length &&
                section.map((item, index) =>
                  (item as FoldItemProps)?.items ? (
                    <FoldItem {...(item as FoldItemProps)} key={item.label.concat(index.toString())} />
                  ) : (
                    <MenuItem {...item} key={item.label.concat(index.toString())} />
                  ),
                )}
            </div>
          ))}
      </div>
    </aside>
  );
};

export { Sidebar };
