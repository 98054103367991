import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { NewParticipantSetupStages } from '../../../new-participant-setup-flow/new-participant-setup-flow.types';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/plus-16.svg';
import { Stepper } from '../../../../../stepper';
import { Table } from '../../../../../table';
import { Typography } from '../../../../../typography';
import { capitalize } from 'lodash';
import styles from './summary.module.scss';
import { useSummaryData } from './summary.hook';

/**
 * Summary component
 * @description Exclusively for the PortfolioSettings components as its component
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-152999&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Summary: FC = () => {
  const { t, flowData, tableData, handleCloseModal, handleOpenAddPortfolioFlow } = useSummaryData();

  return (
    <Modal
      modalId={ModalIds.portfoliosSettingsSummary}
      title={`${flowData?.clientNameOwner} ${flowData?.accountId.toUpperCase()}`}
    >
      <div className={styles.wrapper}>
        {flowData?.stepperProps && <Stepper {...flowData.stepperProps} />}
        <div className={styles.subtitleRow}>
          <Typography.Text variant={'label2'}>{t('modals.portfoliosSettings.summary.subtitle')}</Typography.Text>
          <Typography.Number
            currency
            value={Math.round(flowData?.accountUnlocatedCapitalPortfolio ?? 0)}
            variant={'h3'}
          />
        </div>
        <div className={styles.content}>
          <Typography.Text variant={'bodyText1'}>
            {capitalize(t('modals.portfoliosSettings.summary.settings'))}
          </Typography.Text>
          {!!!tableData.data[0].rows.length && flowData?.onSubmitNewParticipantSetupFlowStage ? (
            <Button
              variant={'borderless'}
              size={'small'}
              leadingIcon={<PlusIcon />}
              onClick={handleOpenAddPortfolioFlow}
            >
              {t('modals.portfoliosSettings.summary.addPortfolioLong')}
            </Button>
          ) : (
            <Table {...tableData} />
          )}
          {!!tableData.data[0].rows.length && flowData?.onSubmitNewParticipantSetupFlowStage && (
            <Button
              variant={'borderless'}
              size={'small'}
              leadingIcon={<PlusIcon />}
              onClick={handleOpenAddPortfolioFlow}
            >
              {t('modals.portfoliosSettings.summary.addPortfolioLong')}
            </Button>
          )}
        </div>
        {flowData?.onSubmitNewParticipantSetupFlowStage ? (
          <Button
            variant={'main'}
            size={'large'}
            onClick={() => {
              flowData?.onSubmitNewParticipantSetupFlowStage &&
                flowData.onSubmitNewParticipantSetupFlowStage(
                  flowData.portfolios ?? [],
                  NewParticipantSetupStages.success,
                );
              handleCloseModal();
            }}
          >
            <Typography.Text variant={'bodyText1'}>
              {capitalize(t('modals.portfoliosSettings.summary.continue'))}
            </Typography.Text>
          </Button>
        ) : (
          <div className={styles.controls}>
            <Button variant={'outline'} size={'large'} onClick={handleCloseModal}>
              <Typography.Text variant={'bodyText1'}>
                {capitalize(t('modals.portfoliosSettings.summary.cancel'))}
              </Typography.Text>
            </Button>
            <Button variant={'main'} size={'large'} onClick={handleOpenAddPortfolioFlow}>
              <Typography.Text variant={'bodyText1'}>
                {capitalize(t('modals.portfoliosSettings.summary.addPortfolio'))}
              </Typography.Text>
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export { Summary };
