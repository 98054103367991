import React, { FC } from 'react';

import { HeaderProps } from './header.types';
import { Indicator } from '../indicator';
import { ReactComponent as LogOutIcon } from '../../../assets/icons/exit.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/setting.svg';
import { Typography } from '../typography';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import styles from './header.module.scss';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

/**
 * Header component
 * @description App's header
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { HeaderProps } props - HeaderProps defined in the './header.component.tsx'
 */
const Header: FC<HeaderProps> = ({
  isBrokerConnectionEstablished,
  isInvestudioConnectionEstablished,
  onLogout,
  currentRole,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  return (
    <div className={styles.wrapper}>
      {currentRole && (
        <div className={styles.currentRole}>
          <UserIcon />
          <Typography.Text variant={'bodyText3'}>{t(`roles.${currentRole}`)}</Typography.Text>
        </div>
      )}
      <div className={styles.connections}>
        <Indicator.ConnectionIndicator
          isConnected={isInvestudioConnectionEstablished}
          connectionName={'TradeVision connection'}
        />
        <Indicator.ConnectionIndicator
          isConnected={isBrokerConnectionEstablished}
          connectionName={'Broker connection'}
        />
      </div>
      <div className={styles.controls}>
        <div className={styles.control}>
          <SettingsIcon onClick={() => navigate('/management/settings')} />
        </div>
        <div className={styles.control}>
          <LogOutIcon onClick={onLogout} />
        </div>
      </div>
    </div>
  );
};

export { Header };
