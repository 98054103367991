import { Form as FormComponent, Formik } from 'formik';
import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { Input } from '../../../../../input';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import { capitalize } from 'lodash';
import styles from './form.module.scss';
import { useFormData } from './form.hook';

/**
 * Form component
 * @description Content for the ReplenishPortfolio flow Form stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-157251&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Form: FC = () => {
  const { t, flowData, initialValues, validationSchema, handleSubmitForm } = useFormData();

  return (
    <Modal modalId={ModalIds.replenishPortfolioForm} title={t('modals.replenishPortfolio.form.title')}>
      {flowData && (
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <Typography.Text variant={'label2'} className={styles.label}>
              {t('modals.replenishPortfolio.form.capitalLabel')}
            </Typography.Text>
            <Typography.Number variant={'h3'} currency value={Math.round(flowData.accountUnlocatedCapitalPortfolio)} />
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmitForm}>
            {({ setFieldValue, errors, touched, setFieldTouched, isValid, values: { replenishAmount } }) => (
              <FormComponent className={styles.formWrapper} autoComplete={'off'}>
                <Input
                  id={'replenishAmount'}
                  label={t('modals.replenishPortfolio.form.replenishAmount')}
                  type={'text'}
                  isCurrency={true}
                  value={replenishAmount}
                  onChange={(value) => {
                    setFieldValue('replenishAmount', !!+value.toString().replaceAll(' ', '') ? value : null);
                  }}
                  onBlur={() => setFieldTouched('replenishAmount', true)}
                  error={
                    replenishAmount &&
                    parseFloat(replenishAmount.replace(/[^0-9.-]+/g, '')) > flowData.accountUnlocatedCapitalPortfolio
                      ? t('modals.replenishPortfolio.form.insufficient')
                      : errors.replenishAmount && touched.replenishAmount
                      ? errors.replenishAmount
                      : undefined
                  }
                />
                <Button
                  size={'large'}
                  variant={'main'}
                  type={'submit'}
                  disabled={
                    !flowData ||
                    !isValid ||
                    !replenishAmount ||
                    parseFloat(replenishAmount.replace(/[^0-9.-]+/g, '')) > flowData.accountUnlocatedCapitalPortfolio
                  }
                  className={styles.saveButton}
                >
                  <Typography.Text variant={'bodyText1'}>
                    {capitalize(t('modals.replenishPortfolio.form.continue'))}
                  </Typography.Text>
                </Button>
              </FormComponent>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export { Form };
