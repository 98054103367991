import React, { FC } from 'react';

import { DropdownItemProps } from './dropdown-item.types';
import { Typography } from '../../../../../typography';
import classNames from 'classnames';
import styles from './dropdown-item.module.scss';

/**
 * DropdownItem component
 * @description Exclusive dropdown item for DropdownMenu
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 * @param { DropdownItemProps } props - DropdownItemProps defined in the './dropdown-item-.types.ts'
 */
const DropdownItem: FC<DropdownItemProps> = ({
  id,
  value,
  leadingIcon,
  isSelected,
  className,
  style,
  disabled,
  onSelect,
}) => (
  <div
    role={'button'}
    tabIndex={0}
    onKeyDown={() => onSelect(id)}
    className={classNames(styles.dropdownItem, isSelected && styles.isSelected, disabled && styles.disabled, className)}
    style={style}
    onClick={() => onSelect(id)}
  >
    {leadingIcon && <div className={styles.leadingIcon}>{leadingIcon}</div>}
    <Typography.Text variant={'bodyText3'}>{value}</Typography.Text>
  </div>
);

export { DropdownItem };
