import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../typography';
import styles from './new-participant-setup-flow.module.scss';
import { useNewParticipantSetupFlowData } from './new-participant-setup-flow.hook';

/**
 * Set component
 * @description Content for the NewParticipantSetup flow.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2118-409171&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const NewParticipantSetupFlow: FC = () => {
  const { currentStage, t, accountOwner, handleCloseModal } = useNewParticipantSetupFlowData();

  return currentStage === 'success' ? (
    <Modal modalId={ModalIds.newParticipantSetup} title={t('modals.newParticipantSetup.success.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText3'}>
          {t('modals.newParticipantSetup.success.description', { accountOwner })}
        </Typography.Text>
        <Button variant={'main'} size={'large'} onClick={handleCloseModal}>
          <Typography.Text variant={'bodyText1'}>{t('modals.newParticipantSetup.success.continue')}</Typography.Text>
        </Button>
      </div>
    </Modal>
  ) : null;
};

export { NewParticipantSetupFlow };
