import { Error, Request, Success } from './components';
import { InviteNewUserValues, RequestProps } from './components/request/request.types';
import React, { useCallback, useMemo, useState } from 'react';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { ErrorProps } from './components/error/error.types';
import { InviteProps } from './invite.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { inviteUser } from '../../../../../../services/api';

/**
 * useInviteData hook
 * @description The hook which processes UsersManagement Invite flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useInviteData = () => {
  const { user, errorMessage, dispatch: appDispatch } = useAppContext();
  const {
    data: { usersManagementInvite },
    dispatch,
  } = useModalsContext();

  const [newUser, setNewUser] = useState<InviteNewUserValues>();

  const flowData: InviteProps | undefined = useMemo(() => usersManagementInvite, [usersManagementInvite]);

  const setStage = useCallback(
    (stage: InviteProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.usersManagementInvite,
          data: {
            [ModalIds.usersManagementInvite]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    InviteProps['currentStage'],
    RequestProps['onSubmit'] | SuccessProps['onClose'] | ErrorProps['onClose']
  > = useMemo(
    () => ({
      request: async (values: InviteNewUserValues) => {
        if (user && flowData) {
          errorMessage && appDispatch({ type: 'SET_ERROR_MESSAGE' });
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { data, message } = await inviteUser({
              email: values.email,
              name: values.name,
              role: values.role,
            });

            setNewUser(values);

            if (data) {
              await flowData.onRefetchUsers();
              setStage('success');
            } else {
              if (message) {
                appDispatch({ type: 'SET_ERROR_MESSAGE', payload: message });
              } else {
                setStage('error');
              }
            }
          } catch (error) {
            console.error(error);

            setStage('error');
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.usersManagementInvite });
      },
      error: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.usersManagementInvite });
      },
    }),
    [user, flowData, errorMessage, appDispatch, setStage, dispatch],
  );

  const stages: Record<InviteProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            request: <Request onSubmit={submitHandlers.request} errorMessage={errorMessage} />,
            success: newUser ? (
              <Success
                name={newUser.name}
                email={newUser.email}
                onClose={submitHandlers.success as SuccessProps['onClose']}
              />
            ) : (
              <></>
            ),
            error: newUser ? (
              <Error name={newUser.name} onClose={submitHandlers.error as ErrorProps['onClose']} />
            ) : (
              <></>
            ),
          }
        : undefined,
    [errorMessage, flowData, newUser, submitHandlers.error, submitHandlers.request, submitHandlers.success],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useInviteData };
