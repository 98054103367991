import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { CSVLink } from 'react-csv';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../typography';
import styles from './total-transfer.module.scss';
import { useTotalTransferData } from './total-transfer.hook';

/**
 * TotalTransfer component
 * @description Content for the TransferBetweenPortfolios flow TotalTransfer stage.
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const TotalTransfer: FC = () => {
  const { t, flowData, snapShotData, handleSubmitTotalTransfer } = useTotalTransferData();

  return (
    <Modal
      modalId={ModalIds.transferBetweenPortfoliosTotalTransfer}
      title={t('modals.transferBetweenPortfolios.totalTransfer.title')}
    >
      {flowData && (
        <div className={styles.wrapper}>
          <Typography.Text variant={'bodyText3'} className={styles.label}>
            {t('modals.transferBetweenPortfolios.totalTransfer.description')}
          </Typography.Text>
          <div className={styles.buttons}>
            {flowData && snapShotData && (
              <CSVLink
                headers={snapShotData.headers}
                data={snapShotData.data}
                filename={`${flowData.portfolio.PortfolioOnAccountID}.csv`}
              >
                <Button size={'large'} variant={'outline'}>
                  <Typography.Text variant={'bodyText1'}>
                    {t('modals.transferBetweenPortfolios.totalTransfer.controlButtons.report')}
                  </Typography.Text>
                </Button>
              </CSVLink>
            )}
            <Button size={'large'} variant={'main'} onClick={handleSubmitTotalTransfer}>
              <Typography.Text variant={'bodyText1'}>
                {t('modals.transferBetweenPortfolios.totalTransfer.controlButtons.continue')}
              </Typography.Text>
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export { TotalTransfer };
