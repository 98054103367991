import React, { FC } from 'react';

import { BrandingType } from '../../../../services/api/admin/admin.model';
import { Text } from '../../components';
import { Typography } from '../../../../components';
import styles from './footer.module.scss';
import { useFooterHook } from './footer.hook';

const Footer: FC<BrandingType> = ({ address1, address2, companyName, email, logo, phone, site }) => {
  const { t } = useFooterHook();

  return (
    <footer>
      <div className={styles.footer}>
        <div>
          <Text variant="small" lineHeight={18} color="invert">
            {t('reports.common.footer.info.paragraph1')}
          </Text>
          <br />
          <Text variant="small" lineHeight={18} color="invert">
            {t('reports.common.footer.info.paragraph2')}
          </Text>
          <br />
          <Text variant="small" lineHeight={18} color="invert">
            {t('reports.common.footer.info.paragraph3.part1')}
            <a href={`tel:${phone}`}>{phone}</a>
            {t('reports.common.footer.info.paragraph3.part2')}
            <a href={`mailto:${email}`}>{email}</a>.
          </Text>
        </div>
        <div className={styles.footerInfo}>
          <div className={styles.company}>
            <img src={logo} alt="logo" />
            {companyName && <Typography.Text variant={'bodyText1'}>{companyName}</Typography.Text>}
          </div>
          <div>
            <Text variant="small" bold={600} classes={styles.footerInfoPadding} color="invert">
              {t('reports.common.footer.address.alwaysOnline')}
            </Text>
            <Text variant="small" color="invert" lineHeight={18}>
              <span>{address1}</span>
              <br />
              <span>{address2}</span>
              <br />
              <span>
                {t('reports.common.footer.address.addressLine3')}
                <a href={`tel:${phone}`}>{phone}</a>
              </span>
              <br />
              <span>
                <a href={site}>{site}</a>
              </span>
              <br />
              <span>
                {t('reports.common.footer.address.addressLine5.email')}
                <a href={`mailto:${email}`}>{email}</a>
              </span>
            </Text>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
