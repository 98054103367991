import { useLayoutEffect, useState } from 'react';

type WindowSizeType = {
  readonly width: number;
  readonly height: number;
};

/**
 * useWindowSize hook.
 * Hook for tracking the window size.
 *
 * @author Rostyslav Nahornyi
 * @category hooks.
 *
 * @returns { WindowSizeType } An object with the window's width and height.
 */
function useWindowSize(): WindowSizeType {
  const [size, setSize] = useState<WindowSizeType>({ width: window.innerHeight, height: window.innerHeight });

  const handleResizeWindow = () => setSize({ width: window.innerWidth, height: window.innerHeight });

  useLayoutEffect(() => {
    handleResizeWindow();
    window.addEventListener('resize', handleResizeWindow);

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  return size;
}

export { useWindowSize };
