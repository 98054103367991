import { Header, Portal } from '../../components';

import { FC } from 'react';
import { ReactComponent as FiltersIcon } from '../../../assets/icons/sliders-v.svg';
import { ReactComponent as LeftIcon } from '../../../assets/icons/arrow-left2.svg';
import { Notifications } from './components';
import { NotificationsDrawerProps } from './notifications-drawer.types';
import classNames from 'classnames';
import styles from './notifications-drawer.module.scss';
import { useNotificationsDrawerData } from './notifications-drawer.hook';

/**
 * NotificationsDrawer component
 * @description NotificationsDrawer
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 * @param { NotificationsDrawerProps } props - TooltipProps defined in the './notifications-drawer.types.ts'
 */
const NotificationsDrawer: FC<NotificationsDrawerProps> = (props) => {
  const { t, notifications, unreadNotificationsCount, openFiltersDrawer } = useNotificationsDrawerData(props);

  if (!props.isOpened) return null;

  return (
    <Portal domNode={'notifications-drawer'}>
      <div className={classNames(styles.wrapper, props.isOpened && styles.isOpened)}>
        <Header
          large={{
            label: t('header.label', { messagesCount: unreadNotificationsCount }),
            topControl: { icon: <LeftIcon />, onClick: props.onClose },
            bottomControl: { icon: <FiltersIcon />, onClick: () => openFiltersDrawer() },
          }}
        />

        <Notifications
          locallyReadNotificationsIds={props.locallyReadNotificationsIds}
          notifications={notifications}
          portfolioName={props.portfolioName}
          onRefetchNotifications={props.onRefetchNotifications}
        />
      </div>
    </Portal>
  );
};

export { NotificationsDrawer };
