import { useAppContext, useModalsContext } from '../../../../contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useTranslation } from 'react-i18next';
import { cancelAdminInvoices } from '../../../../services/api';

/**
 * useInvoiceCanceledModal
 * @description Gets useInvoiceCanceledModal component data from store
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Hooks
 * @returns AccountCanceledData
 */
const useInvoiceCanceledModal = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    dispatch,
    data: { invoiceCanceled },
  } = useModalsContext();
  const { dispatch: appDispatch } = useAppContext();

  const [isInvoiceDownloaded, setIsInvoiceDownloaded] = useState(false);

  const modalData = useMemo(() => invoiceCanceled, [invoiceCanceled]);

  const handleCancelInvoice = useCallback(async () => {
    if (modalData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        await cancelAdminInvoices({
          id: modalData.subscriptionId,
          currentSubscription: modalData.currentSubscription,
        });

        setIsInvoiceDownloaded(true);
      } catch (error) {
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, modalData]);

  useEffect(() => {
    handleCancelInvoice();
  }, [handleCancelInvoice]);

  const handleContinueClick = useCallback(async () => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.invoiceCanceled });
  }, [dispatch]);

  return {
    t,
    isInvoiceDownloaded,
    handleContinueClick,
  };
};

export { useInvoiceCanceledModal };
