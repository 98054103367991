import { Action, State } from './information.types';

import { Frame } from '../../../services/api';

const initialState: State = {
  yieldPeriod: Frame.month,
};

const reducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case 'SET_ALLOCATION': {
      return {
        ...state,
        allocation: payload,
      };
    }

    case 'SET_ASSETS': {
      return {
        ...state,
        assets: payload,
      };
    }

    case 'SET_INCOME_F1': {
      return {
        ...state,
        incomeF1: payload,
      };
    }

    case 'SET_INCOME_F2': {
      return {
        ...state,
        incomeF2: payload,
      };
    }

    case 'SET_PARTICIPANTS': {
      return {
        ...state,
        participants: payload,
      };
    }

    case 'SET_PORTFOLIO_CHART': {
      return {
        ...state,
        portfolioChart: payload,
      };
    }

    case 'SET_ACCOUNT': {
      return {
        ...state,
        account: payload,
      };
    }

    case 'SET_PORTFOLIO': {
      return {
        ...state,
        portfolio: payload,
      };
    }

    case 'SET_FRAMES': {
      return {
        ...state,
        frames: payload,
      };
    }

    case 'SET_YIELD_PERIOD': {
      return {
        ...state,
        yieldPeriod: payload,
      };
    }

    case 'SET_TICKERS_SHORT_INFO': {
      return {
        ...state,
        tickersShortInfo: payload,
      };
    }

    case 'SET_OPERATIONS': {
      return {
        ...state,
        operations: payload,
      };
    }

    case 'SET_CASH_FLOW': {
      return {
        ...state,
        cashFlow: payload,
      };
    }

    case 'SET_ACCOUNT_STRATEGY': {
      return {
        ...state,
        accountStrategy: payload,
      };
    }

    case 'SET_STRATEGIES': {
      return {
        ...state,
        strategies: payload,
      };
    }

    case 'RESET_DATA': {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { initialState, reducer };
