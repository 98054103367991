import { dateIsBetween, getMonthsFromTo, monthByIndex } from '../../shared';

import { FC } from 'react';
import { MonthlyCalendarProps } from './monthly-calendar.types';
import { Tab } from '../tab';
import { Typography } from '../typography';
import { capitalize } from 'lodash';
import styles from './monthly-calendar.module.scss';
import { useMonthlyCalendarData } from './monthly-calendar.hook';
import { v4 } from 'uuid';

/**
 * MonthlyCalendarModal component
 * @description Content for the MonthlyCalendar window
 *
 * @author Rostyslav Nahornyi, Sergii Goncharuk
 * @category Components
 */
const MonthlyCalendar: FC<MonthlyCalendarProps> = ({
  period: { startDate, endDate = new Date() },
  singleItemSelect,
  selectedMonths,
  setSelectedMonths,
}) => {
  const { t, selectDate, lastYearRef, tabRef } = useMonthlyCalendarData({
    singleItemSelect,
    period: { startDate, endDate },
    selectedMonths,
    setSelectedMonths,
  });

  return (
    <div className={styles.monthlyCalendar}>
      <div className={styles.content}>
        {getMonthsFromTo(startDate, endDate).map(({ year, monthData }) => (
          <div className={styles.yearWrapper} ref={lastYearRef} key={year}>
            <Typography.Text variant={'h5'}>{year}</Typography.Text>

            <div className={styles.months}>
              {monthData.map(({ isMonthInRange: isActive, date }) => (
                <Tab.DatePicker
                  key={v4()}
                  ref={dateIsBetween(date, selectedMonths.a, selectedMonths.b) ? tabRef : undefined}
                  disabled={!isActive}
                  onClick={() => selectDate(date)}
                  isSelected={dateIsBetween(date, selectedMonths.a, selectedMonths.b)}
                  label={capitalize(t(monthByIndex[date.getMonth()]))}
                  className={styles.button}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { MonthlyCalendar };
