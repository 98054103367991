import { ManagerGetAccountPortfoliosSnapshotResponse } from '../services/api';

type PortfoliosSnapshotCSVData = Array<{
  readonly accountId: string;
  readonly portfolioOwner: string;
  readonly email: string;
  readonly initialDate: string;
  readonly initialNAVs: string;
  readonly initialShare: string;
  readonly currentDate: string;
  readonly currentNAVs: string;
  readonly currentShare: string;
}>;

const headers = [
  { label: 'Account', key: 'accountId' },
  { label: 'Portfolio owner', key: 'portfolioOwner' },
  { label: 'E-mail', key: 'email' },
  { label: 'Initial Date', key: 'initialDate' },
  { label: 'Initial NAVs', key: 'initialNAVs' },
  { label: 'Initial share', key: 'initialShare' },
  { label: 'Current Date', key: 'currentDate' },
  { label: 'Current NAVs', key: 'currentNAVs' },
  { label: 'Current share', key: 'currentShare' },
];

type SnapshotData = {
  readonly headers: Array<Record<'label' | 'key', string>>;
  readonly data: PortfoliosSnapshotCSVData;
};

/**
 * mapAccountPortfoliosSnapshotToData util
 * @description maps ManagerGetAccountPortfoliosSnapshotResponse response to PortfoliosSnapshotCSVData
 *
 * @author Oleksii Medvediev
 * @param payload - ManagerGetAccountPortfoliosSnapshotResponse
 * @returns PortfoliosSnapshotCSVData
 */
const mapAccountPortfoliosSnapshotToData = (payload: ManagerGetAccountPortfoliosSnapshotResponse): SnapshotData => ({
  headers,
  data: payload.map(
    ({
      AccountID,
      PortfolioCurrentCapital,
      PortfolioCurrentWeight,
      PortfolioDateClose,
      PortfolioDateOpen,
      PortfolioMember,
      PortfolioOwner,
      PortfolioStartCapital,
      PortfolioStartWeight,
    }) => ({
      accountId: AccountID,
      currentDate: PortfolioDateClose,
      currentNAVs: PortfolioCurrentCapital?.toString(),
      currentShare: PortfolioCurrentWeight?.toString(),
      email: PortfolioOwner,
      initialDate: PortfolioDateOpen,
      initialNAVs: PortfolioStartCapital?.toString(),
      initialShare: PortfolioStartWeight?.toString(),
      portfolioOwner: PortfolioMember,
    }),
  ),
});

export { mapAccountPortfoliosSnapshotToData };
export type { SnapshotData };
