import { Action, AppState } from './app.types';

import { Cache } from 'aws-amplify';
import { CacheQuery } from '../../shared';
import { ReactComponent as Logo } from '../../../assets/icons/Logo.svg';

/**
 * @description InitialState for the AppContext
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 */
const initialState: AppState = {
  user: Cache.getItem(CacheQuery.USER) ?? undefined,
  logo: <Logo />,
  scrollableContentRef: undefined,
};

/**
 * @description Reducer function for the useReducer hook in the AppContextProvider component.
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @param {AppState} state - State defined in the './app.types.ts'
 * @param {Action} action - Action defined in the './app.types.ts'
 * @returns updated AppContextProvider state.
 */
const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'TOGGLE_IS_LOADING': {
      return { ...state, isLoading: !state.isLoading };
    }

    case 'SET_USER': {
      Cache.setItem(CacheQuery.USER, action.payload);
      return { ...state, user: action.payload };
    }

    case 'OPEN_DRAWER': {
      return { ...state, drawer: { isOpened: true, data: action.payload?.data } };
    }

    case 'CLOSE_DRAWER': {
      return { ...state, drawer: { data: undefined, isOpened: false } };
    }

    case 'SET_HEADER_CONTENT': {
      return {
        ...state,
        headerContent: action.payload,
      };
    }

    case 'SET_SCROLLABLE_CONTENT': {
      return {
        ...state,
        scrollableContentRef: action.payload,
      };
    }

    case 'SET_BRANDING': {
      return {
        ...state,
        branding: action.payload,
      };
    }

    case 'SET_SELECTED_ACCOUNT_ID': {
      return {
        ...state,
        selectedAccountId: action.payload,
      };
    }

    default:
      return state;
  }
};

export { initialState, appReducer };
