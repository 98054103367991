import { Form as FormComponent, Formik } from 'formik';
import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { ReactComponent as ChevronDownIcon } from '../../../../../../../../../assets/icons/angle-down.svg';
import { Dropdown } from '../../../../../../../dropdown';
import { FormProps } from './form.types';
import { ReactComponent as InfoCircleIcon } from '../../../../../../../../../assets/icons/info-circle.svg';
import { Input } from '../../../../../../../input';
import { Tooltip } from '../../../../../../../tooltip';
import { Typography } from '../../../../../../../typography';
import styles from './form.module.scss';

/**
 * Form component
 * @description Exclusively for the Summary component of ChangeStrategy flow as its form
 *
 * @author Oleksii Medvediev
 * @param { FormProps } props - FormProps defined in the './form.types.ts'
 * @category Components
 */
const Form: FC<FormProps> = ({
  onSubmit,
  setStrategyRequirementsValidation,
  setSelectedStrategyRequirements,
  accountStrategy,
  initialValues,
  strategies,
  validationSchema,
  t,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    validateOnBlur={true}
    validateOnMount={true}
  >
    {({ errors, values, touched, setFieldTouched, setFieldValue }) => {
      const selectedStrategy = strategies?.find((item) => item.StrategyName === values.strategy);

      return (
        <FormComponent className={styles.wrapper}>
          <Typography.Text variant={'bodyText1'}>{t('modals.changeStrategy.summary.form.title')}</Typography.Text>

          <div className={styles.form}>
            <Input
              id={'currentCapital'}
              type={'number'}
              label={t('modals.changeStrategy.summary.form.inputs.currentCapital.label')}
              isCurrency={true}
              value={initialValues.currentCapital
                .toFixed(0)
                .replaceAll(' ', '')
                .replace(/(?!-)[^0-9.]/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              onChange={() => null}
              readOnly
            />
            <Input
              id={'strategy'}
              type={'text'}
              readOnly
              label={t('modals.changeStrategy.summary.form.inputs.strategy.label')}
              value={values.strategy ?? initialValues.strategy}
              onChange={() => setFieldTouched('strategy', true)}
              onBlur={() => setFieldTouched('strategy', true)}
              error={touched.strategy ? errors.strategy : undefined}
              ignoreSvgColor
              leadingIcon={
                values.strategy && (
                  <Tooltip text={selectedStrategy?.About || ''}>
                    <InfoCircleIcon />
                  </Tooltip>
                )
              }
              tailingIcon={
                strategies && (
                  <Dropdown
                    button={<ChevronDownIcon className={styles.chevron} />}
                    options={[...strategies].map(({ StrategyName }) => ({
                      id: StrategyName,
                      value: StrategyName,
                    }))}
                    onChange={(strategyName) => {
                      setFieldValue('strategy', strategyName);
                      setFieldValue('expectedReturn', selectedStrategy ? selectedStrategy.CAGR * 100 : 0);
                      setFieldValue('maxRisk', selectedStrategy ? selectedStrategy.MaxDrawDown * 100 : 0);
                      setFieldTouched('expectedReturn', false);
                      setFieldTouched('maxRisk', false);

                      const strategy = [...strategies]?.find(({ StrategyName }) => strategyName === StrategyName);

                      if (!strategyName || !strategy || !accountStrategy) {
                        setStrategyRequirementsValidation((prevState) => ({
                          ...prevState,
                          isBrokerValid: false,
                          areTradingPermissionsValid: false,
                        }));
                      } else {
                        //Strategy Permissions(account and selected from dropdown)
                        const accountStrategyPerms = accountStrategy.TradingPermissions.split(',');
                        const selectedStrategyPerms = strategy.Requirements.TradingPermissions.split(',');
                        //Strategy AccTypes(account and selected from dropdown)
                        const accountStrategyAccType = accountStrategy.AccountType.toLowerCase();
                        const selectedStrategyAccTypes = strategy.Requirements.AccountType.toLowerCase().split(',');

                        /**
                         * @example
                         * Account[Stock, Options , ETP] – Selected[Stock, Options , ETP, Bonds] => false
                         * Account[Stock, Options , ETP, Bonds, CFD, Futures Option, Futures etc.] – Selected[Stock, Options , ETP, Bonds] => true
                         */
                        const validatedTradingPermissions =
                          accountStrategyPerms.length >= selectedStrategyPerms.length &&
                          selectedStrategyPerms.every((permission) => selectedStrategyPerms.includes(permission));

                        /**
                         * @example
                         * Account[Margin] – Selected[Margin, Cash, Margin Portfolio] => true
                         * Account[Cash] – Selected[Margin] => false
                         */
                        const validatedAccountType = selectedStrategyAccTypes.includes(accountStrategyAccType);

                        setSelectedStrategyRequirements(strategy.Requirements);
                        setStrategyRequirementsValidation(() => ({
                          isBrokerValid: true,
                          areTradingPermissionsValid: validatedTradingPermissions,
                          isAccountTypeValid: validatedAccountType,
                          isMinCapitalValid: !!(
                            strategy && initialValues.currentCapital >= strategy?.Requirements.MinCapital
                          ),
                        }));
                      }
                    }}
                  />
                )
              }
            />
            <Input
              id={'expectedReturn'}
              type={'number'}
              label={t('modals.changeStrategy.summary.form.inputs.expectedReturn.label')}
              value={touched.expectedReturn ? values.expectedReturn : Math.round((selectedStrategy?.CAGR ?? 0) * 100)}
              onChange={(value) => setFieldValue('expectedReturn', value)}
              onBlur={() => setFieldTouched('expectedReturn', true)}
              error={errors.expectedReturn ? errors.expectedReturn : undefined}
            />
            <Input
              id={'maxRisk'}
              type={'number'}
              value={touched.maxRisk ? values.maxRisk : Math.round((selectedStrategy?.MaxDrawDown ?? 0) * 100)}
              label={t('modals.changeStrategy.summary.form.inputs.maxRisk.label')}
              onChange={(value) => setFieldValue('maxRisk', value)}
              onBlur={() => setFieldTouched('maxRisk', true)}
              error={errors.maxRisk ? errors.maxRisk : undefined}
            />
          </div>

          <div className={styles.controls}>
            <Button disabled={!!Object.values(errors).length} variant={'main'} size={'large'} type={'submit'}>
              <Typography.Text variant={'bodyText1'}>
                {t('modals.changeStrategy.summary.form.continue')}
              </Typography.Text>
            </Button>
          </div>
        </FormComponent>
      );
    }}
  </Formik>
);

export { Form };
