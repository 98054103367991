import { Navigate, Route, Routes } from 'react-router';
import { PortfolioContextProvider, usePortfolioContext } from './context';
import React, { FC, Suspense, lazy } from 'react';

import { Preloader } from '../../components';

const Portfolio = lazy(() => import('./components/portfolio/portfolio.component'));
const Information = lazy(() => import('./components/information/information.component'));
const Operations = lazy(() => import('./components/operations/operations.component'));
const CashFlow = lazy(() => import('./components/cash-flow/cash-flow.component'));
const FinancialResult = lazy(() => import('./components/financial-result/financial-result.component'));
const Replenish = lazy(() => import('./components/replenish/replenish.component'));
const WithdrawFunds = lazy(() => import('./components/withdraw-funds/withdraw-funds.component'));
const Transfer = lazy(() => import('./components/transfer/transfer.component'));
const ClosePortfolio = lazy(() => import('./components/close-portfolio/close-portfolio.component'));
const RenamePortfolio = lazy(() => import('./components/rename-portfolio/rename-portfolio.component'));
const GoalChange = lazy(() => import('./components/goal-change/goal-change.component'));
const GenerateReport = lazy(() => import('./components/generate-report/generate-report.component'));

const PortfolioRouter: FC = () => {
  usePortfolioContext();

  return (
    <PortfolioContextProvider>
      <Routes>
        <Route
          path={''}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <Portfolio />
            </Suspense>
          }
        />
        <Route
          path={'/information'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <Information />
            </Suspense>
          }
        />
        <Route
          path={'/operations'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <Operations />
            </Suspense>
          }
        />
        <Route
          path={'/cash-flow'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <CashFlow />
            </Suspense>
          }
        />
        <Route
          path={'/financial-result'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <FinancialResult />
            </Suspense>
          }
        />
        <Route
          path={'/replenish'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <Replenish />
            </Suspense>
          }
        />
        <Route
          path={'/withdraw-funds'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <WithdrawFunds />
            </Suspense>
          }
        />
        <Route
          path={'/transfer'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <Transfer />
            </Suspense>
          }
        />
        <Route path={'/create-portfolio'} element={<Navigate to={'/dashboard/create-portfolio'} replace />} />
        <Route
          path={'/close-portfolio'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <ClosePortfolio />
            </Suspense>
          }
        />
        <Route
          path={'/rename-portfolio'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <RenamePortfolio />
            </Suspense>
          }
        />
        <Route
          path={'/goal-change'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <GoalChange />
            </Suspense>
          }
        />
        <Route
          path={'/generate-report'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <GenerateReport />
            </Suspense>
          }
        />
        <Route path={'*'} element={<Navigate to={''} />} />
      </Routes>
    </PortfolioContextProvider>
  );
};

export { PortfolioRouter };
